import {
  AutoComplete,
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import { applySort, capitalizeFirstLetter } from '../../../utils/common.utils';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { DownlineService } from '../../../services/downlines.service';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import InviteDownlineTour from '../../../components/common/Tour/inviteDownlineTour';
import InviteDownlinesSvg from '../../../assets/icons/inviteDownlines.svg';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import RemoveDownlineModal from '../../../utils/modal/downlines/remove-downlines/remove-downline';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { adminStore } from '../../../stores/admin.store';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router-dom';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

export type DownlineData = {
  id: string;
  npn: string;
  agencyType: 'producer' | 'agency';
  createdAt: string;
  email: string;
  producer?: {
    id: string;
    name: string;
  };
  agency?: {
    id: string;
    name: string;
  };
};

function Downlines() {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(10);
  const [activeData, setActiveData] = useState<DownlineData[]>([]);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const { bearerToken } = useAuth();
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  // Adding Filters
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [selectedRows, setSelectedRows] = useState<DownlineData[]>([]);
  const [downlinesToBeRemoved, setDownlinesToBeRemoved] = useState<
    DownlineData[]
  >([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const navigate = useNavigate();

  const handleRemoveDownlines = (value: any) => {
    setDownlinesToBeRemoved(value);
  };

  const columns: ColumnsType<DownlineData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'downlineName',
      sorter: true,
      width: 200,
      render: (text: string, record: DownlineData) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              {record.agencyType === 'agency'
                ? record?.agency?.name
                : record?.producer?.name}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      width: 200,
      sorter: true,
      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Downline Type',
      dataIndex: 'agencyType',
      key: 'downlineType',
      width: 200,
      sorter: true,
      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
              marginBottom: 0,
            }}
          >
            {capitalizeFirstLetter(text)}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '12px',
              fontWeight: '400',
              marginBottom: 0,
            }}
          >
            {moment(text).format('MMMM DD, YYYY')}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      width: 200,
      render: (_id: string, record: DownlineData) => (
        <Button
          // shape="circle"
          className="button important-action-button"
          onClick={() => {
            setDownlinesToBeRemoved([record]);
            setModalVisibility(true);
          }}
          disabled={selectedRows.length > 0}
          style={selectedRows.length > 0 ? { opacity: '50%' } : {}}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchDownlines(queryText || '', newPageNumber, newPageSize);
  };

  const fetchDownlines = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken) {
        const defaultRequestBody = {
          agency: adminStore.agency?.id,
          // showOnlyAssignedStates: true,
        };
        const reqBody = {
          ...requestBody,
          ...defaultRequestBody,
          ...(sort || sortValue || {}),
        };
        setIsLoading(true);
        // const userEmail = user?.email ? user?.email : '';
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: any = await DownlineService.getDownlines(
          size || pageSize,
          page || pageNumber,
          bearerToken,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : '',
          cancelTokenSource,
          reqBody
        );
        if (response) {
          setCancelToken(undefined);
          setCount(response.totalCount);
          setIsLoading(false);
          setActiveData(response.data);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
    } finally {
    }
  };

  useEffect(() => {
    fetchDownlines();
  }, [queryText, searchType]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await DownlineService.getDownlineFilters(token);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.DOWNLINES
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchDownlines('', 1);
    setPageNumber(1);
  }, [requestBody]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  const pageName = PlausiblePageConstants.AGENCY_DOWNLINES;

  return (
    <div
      style={{ height: 'fit-content', width: '100%' }}
      className="table-layout-v2 relative"
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <Row
          justify="space-between"
          className="radio-buttons"
          style={{ alignItems: 'center' }}
        >
          <Typography.Text
            type="secondary"
            className="reset-icon-size"
            style={{ fontSize: '14px', marginBottom: 0 }}
          >
            <Space>
              <InfoCircleOutlined />
              View and manage your downlines
            </Space>
          </Typography.Text>
          <Space style={{ display: 'flex', gap: 16 }}>
            <Tooltip title="Invite Downline">
              <Button
                id={
                  IdConstants.AGENCY.DOWNLINES.INVITE_DOWNLINE_BUTTON +
                  '-agency-downlines'
                }
                className="button-secondary-color-important invite-downline-svg"
                style={{
                  background: 'var(--hint-primary-color)',
                  color: 'white',
                }}
                onClick={() => navigate(RouteConstants.inviteDownline.path)}
              >
                <img src={InviteDownlinesSvg} alt="invite-downline" />
              </Button>
            </Tooltip>
            <Button
              id={
                IdConstants.AGENCY.DOWNLINES.VIEW_INVITATION_LINK +
                '-agency-downlines'
              }
              onClick={() => navigate(RouteConstants.invite_downlines.path)}
            >
              View Invitations
            </Button>
            <Button
              id={IdConstants.AGENCY.DOWNLINES.REMOVE_DOWNLINE_BUTTON}
              className="button important-action-button"
              disabled={selectedRows.length > 0 && !isLoading ? false : true}
              onClick={() => {
                setDownlinesToBeRemoved(selectedRows);
                setModalVisibility(true);
              }}
              style={{
                opacity: selectedRows.length > 0 && !isLoading ? '100%' : '50%',
              }}
            >
              Remove Downlines
            </Button>
          </Space>
        </Row>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '10px',
            marginBottom: '10px',
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            disabled={isLoading}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-agency-downlines'}
              placeholder="Search Downlines by Name or NPN"
              style={{ width: '100%' }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters, pageName }}
        />

        <>
          <Table
            rowKey={(record) => record.id}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRows.map((data) => data.id),
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
              },
            }}
            columns={columns}
            dataSource={activeData}
            pagination={false}
            scroll={{
              y: 'calc(100vh - 280px)',
            }}
            loading={isLoading}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchDownlines);
              setSortValue(sort);
            }}
          />
        </>

        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              disabled={isLoading}
              total={count}
              current={pageNumber}
              defaultCurrent={pageNumber}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </Row>
        )}
        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          fetchFilters={fetchFiltersInfoForFindAll}
          clearFilters={() => {
            clearFilters();
          }}
          pageName={pageName}
          filterPage={PageConstants.DOWNLINES}
        />
      </ConfigProvider>
      {tourStore.inviteDownlineDemoTour &&
        tourStore.inviteDownlineDemoTourVisible && <InviteDownlineTour />}
      <RemoveDownlineModal
        handleVisibility={(isCancel?: boolean) => {
          if (!isCancel) {
            setSelectedRows([]);
            setDownlinesToBeRemoved([]);
          }
          setModalVisibility(false);
        }}
        visibility={modalVisibility}
        downlinesToBeRemoved={downlinesToBeRemoved}
        setRemoveDownlines={handleRemoveDownlines}
        npn=""
        email=""
        reloadPage={() => {
          fetchDownlines();
          return;
        }}
      />
    </div>
  );
}

export default withRoles(observer(Downlines), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
