import {
  InfoCircleOutlined,
  LoadingOutlined,
  ReloadOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { GeneralTabType } from '../../../../../enums/generalTabs.enum';
import { IdConstants } from '../../../../../constants/id.constants';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { Spin } from 'antd';
import { WarningBannerType } from '../../../../../enums/warningBannerType.enum';
import { useNavigate } from 'react-router';

interface WarningBannerProps {
  type: WarningBannerType;
  onRefreshAssignments?: () => void;
  isRefreshing?: boolean;
}

const WarningBanner = (props: WarningBannerProps) => {
  const customIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: '#9F7200' }} spin />
  );

  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: '6px 24px',
        border: '1px solid #FFC942',
        backgroundColor: '#FCEFCF',
        borderRadius: 12,
        marginBottom: 16,
      }}
    >
      {props.type === WarningBannerType.OWNERSHIP_DETAILS && (
        <>
          <p style={{ fontSize: 16, fontWeight: 600 }}>
            <InfoCircleOutlined style={{ marginRight: 12, color: '#E2A200' }} />
            Fill Ownership Details
          </p>

          <p style={{ marginLeft: 30, fontFamily: 'Figtree' }}>
            Please fill the ownership details to proceed forward with the agency
            licenses application
          </p>
          <p
            style={{
              color: '#9F7200',
              textDecoration: 'underline',
              marginLeft: 30,
            }}
            id={IdConstants.AGENCY.ALL_LICENSES.FILL_OWNERSHIP_DETAILS}
            onClick={() =>
              navigate(
                RouteConstants.agencyGeneralProfile.path +
                  `?tab=${GeneralTabType.OWNERSHIP_DETAILS}`
              )
            }
          >
            <span className="cursor-pointer">
              Click here to fill the details <RightOutlined />
            </span>
          </p>
        </>
      )}
      {props.type === WarningBannerType.ASSIGNMENT_PROCESSING && (
        <>
          <p style={{ fontSize: 16, fontWeight: 600 }}>
            <InfoCircleOutlined style={{ marginRight: 12, color: '#E2A200' }} />
            Warning: Territories and State Assignments in Progress for Bulk
            Onboarded Producers{' '}
          </p>

          <p style={{ marginLeft: 30, fontFamily: 'Figtree' }}>
            Please note that territories and state assignments are still being
            processed for certain producers who were onboarded via the Bulk
            Onboard process. As a result, there may be temporary delays or
            discrepancies in the assignment of regions. We appreciate your
            patience and will update the assignments as soon as they are
            completed.
          </p>
          <p
            style={{
              color: '#9F7200',
              textDecoration: 'underline',
              marginLeft: 30,
            }}
            onClick={props.onRefreshAssignments}
          >
            <span
              className="cursor-pointer"
              style={{ display: 'flex', gap: '8px', fontWeight: 700 }}
            >
              {props.isRefreshing === true ? (
                <Spin indicator={customIcon} size="small" />
              ) : (
                <ReloadOutlined
                  style={{ fontSize: 14, color: '#9F7200' }}
                  onClick={props.onRefreshAssignments}
                  className="cursor-pointer primary-color"
                />
              )}
              Refresh Assignments
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default WarningBanner;
