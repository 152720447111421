import {
  Button,
  Card,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  Invite,
  InviteType,
  InviteTypeLabels,
} from '../types/data/invite.type';
import { RoleType, RoleTypeLabels } from '../enums/roles.enum';
import { isEmpty, toUpper } from 'lodash';
import { useEffect, useState } from 'react';

import { AdminInvitesService } from '../services/adminInvites.service';
import { ColumnsType } from 'antd/es/table';
import { InviteResponseEnum } from '../enums/invite-response.enum';
import { SyncOutlined } from '@ant-design/icons';
import { applySort } from '../utils/common.utils';
import { auth0Store } from '../stores/auth0Store';
import { formatDateWithTime } from '../utils/date.utils';
import { getNameString } from '../utils/name.utils';
import { inviteStore } from '../stores/invites.store';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../auth/authProvider';
import { withRoles } from '../auth/useRoles';

function Invites() {
  const { getAccessTokenSilently } = useAuth();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingState, setLoadingState] = useState<{
    isLoading: boolean;
    index: number;
    action: InviteResponseEnum | null;
  }>({
    isLoading: false,
    index: -1,
    action: null,
  });
  const [sortValue, setSortValue] = useState({});

  useEffect(() => {
    handleRefresh();
  }, []);

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    handleRefresh(newPageNumber, newPageSize);
  };

  const handleRespond = async (
    inviteToken: string,
    inviteType: InviteType,
    response: InviteResponseEnum,
    index: number
  ) => {
    setLoadingState({
      isLoading: true,
      index,
      action: response,
    });
    const token = await getAccessTokenSilently();
    try {
      await AdminInvitesService.respondToInvite(token, inviteToken, response);

      await inviteStore.loadInvites(token);
    } catch (e) {
      console.error('Error :: ', e);
    } finally {
      setLoadingState({
        isLoading: false,
        index: -1,
        action: null,
      });
      response === InviteResponseEnum.ACCEPTED &&
        inviteType === InviteType.userInvite &&
        auth0Store.loadProfiles(token, false);
    }
  };

  const handleRefresh = async (
    page?: number,
    size?: number,
    query?: string,
    sort?: Object
  ) => {
    const token = await getAccessTokenSilently();

    await inviteStore.loadInvites(token, page, size, {
      ...(sort || sortValue || {}),
    });
  };

  let columns: ColumnsType<Invite> = [
    {
      title: 'Invite Type',
      dataIndex: 'inviteType',
      key: 'inviteType',
      sorter: true,
      width: 200,
      render: (text: string, record: Invite) => (
        <Typography
          style={{
            marginLeft: 12,
          }}
        >
          {InviteTypeLabels[text as InviteType]}
        </Typography>
      ),
    },
    {
      title: 'Invited On',
      dataIndex: 'invitedOn',
      key: 'createdAt',
      sorter: true,
      width: 200,
      render: (text: string, record: Invite) => {
        let content = '';
        if (record.expiryDate && moment.isDate(new Date(record.expiryDate))) {
          content = `Invite expires in ${moment(record.expiryDate)
            .startOf('day')
            .diff(moment().startOf('day'), 'days')} day(s)`;
        }

        return (
          <Row align="middle">
            <Tooltip title={content}>
              <Typography.Paragraph>
                {formatDateWithTime(record.invitedOn)}
              </Typography.Paragraph>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Invited By',
      dataIndex: 'inviterDetails',
      key: 'inviterDetails.account.name',
      width: 200,
      sorter: true,
      render: (inviterDetails: any) => (
        <Row>
          <Space>
            <Typography.Paragraph underline>
              {getNameString(inviterDetails?.account?.name)}
            </Typography.Paragraph>
            <Typography.Paragraph>from</Typography.Paragraph>
            <Typography.Paragraph strong>
              {inviterDetails?.agency?.name}
            </Typography.Paragraph>
          </Space>
        </Row>
      ),
    },
    {
      title: 'Additional Information',
      dataIndex: 'inviteeDetails',
      key: 'inviteeDetails',
      width: 200,
      render: (inviteeDetails: any, record: Invite) => (
        <Row>
          {record.inviteType === InviteType.userInvite && (
            <Space>
              <Typography.Paragraph>Invited as</Typography.Paragraph>
              <Typography.Paragraph strong>
                {RoleTypeLabels[inviteeDetails?.role as RoleType]}
              </Typography.Paragraph>
            </Space>
          )}
          {record.inviteType === InviteType.downlineInvite && (
            <Space>
              <Typography.Paragraph>
                Invited using {toUpper(record.invitedUsing)}:
              </Typography.Paragraph>
              <Typography.Paragraph strong>
                {inviteeDetails?.npn}
              </Typography.Paragraph>
            </Space>
          )}
        </Row>
      ),
    },
    {
      width: 200,
      render: (_: any, record: Invite, index: number) => (
        <Space>
          <Button
            type="primary"
            color="green"
            onClick={() => {
              handleRespond(
                record.inviteToken,
                record.inviteType,
                InviteResponseEnum.ACCEPTED,
                index
              );
            }}
            style={{
              width: '100px',
            }}
            loading={
              loadingState.index === index &&
              loadingState.isLoading &&
              loadingState.action === InviteResponseEnum.ACCEPTED
            }
            disabled={loadingState.index !== index && loadingState.isLoading}
          >
            Accept
          </Button>
          <Button
            onClick={() => {
              handleRespond(
                record.inviteToken,
                record.inviteType,
                InviteResponseEnum.REJECTED,
                index
              );
            }}
            style={{
              fontSize: '14px',
              cursor: 'pointer',
              background: 'var(--important-color)',
              color: 'white',
              width: '100px',
            }}
            loading={
              loadingState.index === index &&
              loadingState.isLoading &&
              loadingState.action === InviteResponseEnum.REJECTED
            }
            disabled={loadingState.index !== index && loadingState.isLoading}
          >
            Reject
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ height: '100%', width: '100%', flexGrow: '1' }}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          alignItems: 'flex-end',
          padding: '0 20px',
        }}
        className="table-layout-v2 relative"
      >
        <Button
          icon={<SyncOutlined spin={inviteStore.invitesLoading} />}
          onClick={() => handleRefresh()}
          loading={inviteStore.invitesLoading}
        >
          Refresh
        </Button>
        <Table
          style={{ width: '100%' }}
          columns={columns}
          rowClassName={(record) => record?.id}
          dataSource={inviteStore.invites}
          pagination={false}
          loading={inviteStore.invitesLoading}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, handleRefresh);
            setSortValue(sort);
          }}
          scroll={{
            y: 'calc(100vh - 280px)',
          }}
        />
        {!isEmpty(inviteStore.invites) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              current={pageNumber}
              total={inviteStore.invitesCount}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={inviteStore.invitesLoading}
            />
          </Row>
        )}
      </div>
    </div>
  );
}
export default withRoles(observer(Invites), [
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
  RoleType.ADMIN,
]);
