import { Avatar, Dropdown, Menu, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import AgencyLogo from '../agencyLogo/agencyLogo';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { adminStore } from '../../../stores/admin.store';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { auth0Store } from '../../../stores/auth0Store';
import { capitalize } from 'lodash';
import dropdownIcon from '../../../assets/icons/expand-up-down-line.svg';
import { getInitials } from '../../../utils/name.utils';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { withRoles } from '../../../auth/useRoles';

const AGENCY_SEPARATOR = '____AGENCY___NAME__';

function SelectProfile() {
  const { loadUser, getAccessTokenSilently } = useAuth();
  const navigate = useNavigate();

  const [selectedProfile, setSelectedProfile] = useState(() => {
    const initialProfile =
      auth0Store.getCurrentProfile() || auth0Store.getProfiles()[0];
    return {
      value: initialProfile?.id + AGENCY_SEPARATOR + initialProfile?.agencyId,
      label: initialProfile?.agency.name,
      role: initialProfile?.role,
    };
  });
  const [isSingleAgencyProfile, setIsSingleAgencyProfile] =
    useState<boolean>(false);

  const handleSelectProfile = async (value: string) => {
    const token = await getAccessTokenSilently();
    const regex = new RegExp(`(.*)${AGENCY_SEPARATOR}(.*)`);
    const matches = value.match(regex);

    const profileId = matches?.[1];
    const agencyId = matches?.[2];
    const profile = auth0Store.findProfileById(profileId!, agencyId!);
    if (profile) {
      signupStore.setIsAppLoading(true);
      auth0Store.setCurrentProfile(profile);
      if (profile.agencyId) {
        hierarchyViewStore.setCurrentAgencyId(profile.agencyId);
        await agencyProfileStore.loadAgencyDetails(token);
      }
      loadUser().then(() => {
        if (profile.role === RoleType.ADMIN) {
          navigate(RouteConstants.allAgents.path);
        } else if (profile.role === RoleType.AGENT) {
          navigate(RouteConstants.agentDashBoard.path);
        }
      });
    }
  };

  useEffect(() => {
    if (auth0Store.getProfiles()?.length === 1) setIsSingleAgencyProfile(true);
  }, []);

  const content = (
    <span
      style={{
        maxWidth: '170px',
        fontSize: '14px',
        fontFamily: 'Figtree',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        overflowY: 'visible',
        marginTop: '-4px',
      }}
    >
      {selectedProfile.label}
    </span>
  );

  const menu = (
    <Menu
      onClick={({ key }) => {
        const selected = auth0Store
          .getProfiles()
          .find(
            (profile) =>
              profile.id + AGENCY_SEPARATOR + profile.agencyId === key
          );
        if (selected) {
          setSelectedProfile({
            value: key,
            label: selected.agency.name,
            role: selected.role,
          });
          handleSelectProfile(key);
        }
      }}
      className="flex flex-col gap-2"
      items={auth0Store.getProfiles().map((itr) => {
        const isSelected =
          selectedProfile.value === itr.id + AGENCY_SEPARATOR + itr.agencyId;

        const isCurrentProfile =
          itr.agency.name === auth0Store.getCurrentProfile()?.agency.name &&
          itr.role === auth0Store.getCurrentProfile()?.role;

        const label = (
          <div style={{ fontFamily: 'Figtree' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <AgencyLogo
                agencyNameInitial={getInitials(itr.agency.name)}
                style={{
                  height: '25px',
                  width: '25px',
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',
                  fontSize: '14px',
                }}
                imageUrl={itr.agency.agencyLogo}
              />
              <div>
                <span
                  style={{
                    display: 'block',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '20px',
                  }}
                >
                  {itr.agency.name}
                </span>

                <span
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                    color: '#848484',
                    height: '12px',
                  }}
                >
                  {capitalize(itr.role)}
                </span>
              </div>
            </div>
          </div>
        );

        return {
          key: itr.id + AGENCY_SEPARATOR + itr.agencyId,
          label,
          className: isSelected
            ? 'menu-item-agency-selected'
            : 'menu-item-agency',
          disabled: isCurrentProfile,
          style: {
            height: '44px',
            padding: '6px 8px',
            fontFamily: 'Figtree',
            fontWeight: 400,
            fontSize: '14px',
            backgroundColor: isSelected ? '#eeeeee' : '#ffffff',
            color: 'rgba(0, 0, 0, 0.88)',
          },
        };
      })}
    />
  );
  return auth0Store?.getProfiles()?.length > 0 ? (
    <div
      style={{
        padding: '16px 16px 32px 16px',
        display: 'flex',
        backgroundColor: '#fafafa',
      }}
    >
      <AgencyLogo
        key={adminStore.agency?.agencyLogo}
        imageUrl={adminStore.agency?.agencyLogo}
        style={{
          height: '24px',
          width: '24px',
          border: '1px solid #EBEBEB',
          borderRadius: '4px',
          marginRight: '8px',
          fontSize: '14px',
        }}
        agencyNameInitial={getInitials(adminStore.agency?.name || '')}
      />

      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={isSingleAgencyProfile}
        destroyPopupOnHide
      >
        <button
          style={{
            border: 'none',
            background: 'none',
            display: 'flex',
            gap: '4px',
            cursor: 'pointer',
            height: '24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isSingleAgencyProfile ? (
              <Tooltip title={selectedProfile.label} placement="rightTop">
                {content}
              </Tooltip>
            ) : (
              content
            )}

            <span
              style={{
                fontSize: '10px',
                marginTop: '-3px',
                fontWeight: 400,
                fontFamily: 'Figtree',
                alignSelf: 'start',
                color: '#848484',
              }}
            >
              {capitalize(auth0Store.getCurrentProfile()?.role || '')}
            </span>
          </div>
          {!isSingleAgencyProfile && (
            <img src={dropdownIcon} width={12} height={12} />
          )}
        </button>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
}
SelectProfile.displayName = 'SelectProfile';
export default withRoles(observer(SelectProfile), [
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
  RoleType.ADMIN,
]);
