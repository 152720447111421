import { LicenseStatus } from '../enums/licenseStatus.enum';

export const activeLicenseFilter = [
  LicenseStatus.Active,
  LicenseStatus.MISSING_LOA,
  LicenseStatus.ACTIVE_LOA_DECLINED,
  LicenseStatus.ACTIVE_RENEWAL_DECLINED,
  LicenseStatus.ACTIVE_RENEWAL_PENDING,
];

export const renewalLicenseFilter = [
  LicenseStatus.Active,
  LicenseStatus.ACTIVE_RENEWAL_DECLINED,
  LicenseStatus.MISSING_LOA,
  LicenseStatus.Expired,
  LicenseStatus.EXPIRED_RENEWAL_DECLINED,
];

export const needsAttentionLicenseFilter = [
  LicenseStatus.MISSING_LOA,
  LicenseStatus.Inactive,
  LicenseStatus.ACTIVE_LOA_DECLINED,
  LicenseStatus.INACTIVE_DECLINED,
  LicenseStatus.ACTIVE_RENEWAL_DECLINED,
  LicenseStatus.Expired,
  LicenseStatus.ACTIVE_RENEWAL_DECLINED,
];
