import { Button, Card, Form, Input, Modal, Radio } from 'antd';
import {
  EMAIL_REGEX,
  LAST_NAME_REGEX,
  NPN_REGEX,
} from '../../../../constants/regex.constants';
import {
  INDIVIDUAL_ONBOARD_STEPS,
  OnboardProducerType,
  ProducerOnboardingLookupType,
} from './individualOnboardV2Type.type';

import { AgentService } from '../../../../services/agent.service';
import ErrorModal from '../../../../components/common/errorModal/errorModal';
import { IdConstants } from '../../../../constants/id.constants';
import StateSelect from '../../../../components/common/stateSelect/StateSelect';
import { adminStore } from '../../../../stores/admin.store';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import { useAuth } from '../../../../auth/authProvider';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';

const IndividualOnboardType = {
  SSN: 'Social Security Number',
  NPN: 'National Producer Number',
  LN: 'License Number',
};

const IndividualOnboardOptions: React.FC = () => {
  const { bearerToken } = useAuth();
  const [form] = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(
    IndividualOnboardType.SSN
  );
  const [watchlistModalVisible, setWatchlistModalVisible] = useState('');

  const onFinish = async (
    values: any,
    ignoreWatchlistWarning: boolean = false
  ) => {
    setLoading(true);

    try {
      if (bearerToken && adminStore.agency?.id) {
        let requestBody: OnboardProducerType;
        let email: string = '';
        if (selectedType === IndividualOnboardType.SSN) {
          email = values?.ssnEmail;
          requestBody = {
            ssn: values.ssn,
            lastName: values.lastname,
            lookupType: ProducerOnboardingLookupType.LOOKUP_SSN,
            ignoreWatchlistWarning,
            producerEmailAddress: email,
          };
        } else if (selectedType === IndividualOnboardType.LN) {
          email = values?.lnEmail;
          requestBody = {
            licenseNumber: values.licenseNo,
            licenseState: values.state,
            lookupType: ProducerOnboardingLookupType.LOOKUP_LICENSE,
            ignoreWatchlistWarning,
            producerEmailAddress: email,
          };
        } else {
          email = values?.npnEmail;
          requestBody = {
            npn: values.npn,
            lookupType: ProducerOnboardingLookupType.LOOKUP_NPN,
            ignoreWatchlistWarning,
            producerEmailAddress: email,
          };
        }
        const response = await AgentService.onboardIndividualProducer(
          requestBody,
          bearerToken
        );
        if (response?.data?.watchListReason) {
          setWatchlistModalVisible(response.data?.watchListReason);
          return;
        }
        const producerId = response?.data?.result || response.data?.producerId;
        individualOnboardStore.setProducerId(producerId);
        individualOnboardStore.setIsProducerDataFetching(true);
        individualOnboardStore.setCurrentOnboardStep(
          INDIVIDUAL_ONBOARD_STEPS.INDIVIDUAL_ONBOARD_DETAILS
        );
        individualOnboardStore.setProducerEmail(email);
      }
    } catch (error: any) {
      console.error(error);
      setErrorMessage(
        error?.response?.data?.message ||
          'Failed to onboard Producer.Please Try Again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="individual-onboard-v2-options">
      <div>
        <div
          className="font-poppins"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#222222',
            paddingBottom: 8,
          }}
        >
          Search with
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          {Object.values(IndividualOnboardType).map((data) => {
            return (
              <div
                key={data}
                className="cursor-pointer"
                id={`agency-all-licenses-type--${data}`}
                style={{
                  padding: '16px 22px',
                  border: `1px solid ${
                    data === selectedType ? 'var(--primary-color)' : '#E6E6E6'
                  }`,
                  borderRadius: 6,
                  margin: '3px 0 24px',
                  display: 'flex',
                  width: 262,
                  height: 53,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pointerEvents: loading ? 'none' : 'inherit',
                }}
                onClick={() => {
                  if (data !== selectedType) {
                  }
                  setSelectedType(data);
                }}
              >
                {data}
                <span style={{ display: 'block' }}>
                  <Radio checked={data === selectedType} />
                </span>
              </div>
            );
          })}
        </div>
        <Form form={form} onFinish={(values) => onFinish(values, false)}>
          {selectedType === IndividualOnboardType.NPN ? (
            <div style={{ display: 'flex', gap: 16 }}>
              <Form.Item
                style={{ width: 343 }}
                name="npn"
                label="National Producer Number"
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: NPN_REGEX,
                    message: 'Please enter a valid NPN',
                  },
                ]}
              >
                <Input
                  id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.NPN_INPUT}
                  disabled={loading}
                  placeholder="Enter a National Producer Number"
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="npnEmail"
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  {
                    pattern: EMAIL_REGEX,
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  id="email-input"
                  placeholder="Enter Email address"
                />
              </Form.Item>
            </div>
          ) : selectedType === IndividualOnboardType.SSN ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Form.Item
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                name="ssn"
                label="Social Security Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Social Security Number',
                  },
                  // {
                  //   pattern: SSN_REGEX,
                  //   message: 'Please enter a valid Social Security Number',
                  // },
                ]}
                // initialValue={individualOnboardStore.ssn}
              >
                <Input
                  id={
                    IdConstants.AGENTS.INDIVIDUAL_ONBOARDING
                      .SOCIAL_SECURITY_INPUT
                  }
                  placeholder="Enter a Social Security Number"
                  // maxLength={9}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                name="lastname"
                label="Last Name"
                rules={[
                  { required: true },
                  {
                    pattern: LAST_NAME_REGEX,
                    message: 'Please enter a valid last name',
                  },
                ]}
                // initialValue={individualOnboardStore.lastName}
              >
                <Input
                  id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.LAST_NAME_INPUT}
                  placeholder="Enter a Last Name"
                  onChange={(e) => {
                    const value = e.target.value;
                    const cleanedValue = value.replace(/[^A-Za-z\s']/g, '');
                    const capitalizedValue =
                      cleanedValue.charAt(0).toUpperCase() +
                      cleanedValue.slice(1);
                    form.setFieldsValue({ lastname: capitalizedValue });
                  }}
                  onKeyPress={(event) => {
                    const regex = /^[A-Za-z\s']+$/;
                    const char = String.fromCharCode(
                      event.which || event.keyCode
                    );
                    if (!regex.test(char)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    const regex = /^[A-Za-z\s']+$/;
                    if (!regex.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="ssnEmail"
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  {
                    pattern: EMAIL_REGEX,
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  id="email-input"
                  placeholder="Enter Email address"
                />
              </Form.Item>
            </div>
          ) : selectedType === IndividualOnboardType.LN ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Form.Item
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                name="licenseNo"
                label="License Number"
                rules={[{ required: true }]}
                // initialValue={individualOnboardStore.licenseNo}
              >
                <Input
                  id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.LAST_NAME_INPUT}
                  placeholder="Enter a License Number"
                  onKeyPress={(event) => {
                    if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[a-zA-Z0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={10}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                name="state"
                label="Select State"
                rules={[
                  {
                    required: true,
                    message: 'Please select the State',
                  },
                ]}
                // initialValue={individualOnboardStore.state}
              >
                <StateSelect
                  onChange={(value: string) => {
                    form.setFieldsValue({ state: value });
                  }}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="lnEmail"
                style={{ width: 343 }}
                labelCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  {
                    pattern: EMAIL_REGEX,
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  id="email-input"
                  placeholder="Enter Email address"
                />
              </Form.Item>
            </div>
          ) : null}
          <Form.Item name="getDetails">
            <Button
              htmlType="submit"
              size="large"
              id={
                IdConstants.INDIVIDUAL_ONBOARDING.PRODUCER_DETAILS.FETCH_BUTTON
              }
              className="button primary-button"
              style={{
                width: '264px',
                float: 'right',
                // opacity: !individualOnboardStore.isOnboarding ? '100%' : '50%',
              }}
              loading={loading}
              // disabled={individualOnboardStore.isOnboarding}
            >
              Onboard Producer
            </Button>
          </Form.Item>
        </Form>
      </div>
      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      <Modal
        title="Watchlisted Producer"
        open={!!watchlistModalVisible}
        onOk={() => {
          onFinish(form.getFieldsValue(), true);
          setWatchlistModalVisible('');
        }}
        onCancel={() => {
          setWatchlistModalVisible('');
        }}
        okText="Confirm"
      >
        {watchlistModalVisible}
      </Modal>
    </Card>
  );
};

export default IndividualOnboardOptions;
