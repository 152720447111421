import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { type MenuProps } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import information from '../../assets/icons/agentInfo.svg';
import { withRoles } from '../../auth/useRoles';
import { IconConstants } from '../../constants/icons.constants';
import { RouteConstants } from '../../constants/routes.constants';
import { PayeeEnum } from '../../enums/payee.enum';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import { RoleType } from '../../enums/roles.enum';
import { agentSideStore } from '../../stores/agentPortalStore';
import CustomMenu from '../common/customMenu/customMenu';
import CustomIcon from '../common/customiseIcon/customIcon';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void,
  disabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    disabled,
    id: `sider-menu-${label?.toString().toLowerCase().replace(' ', '-')}`, // changing this will cause issue in the component tour, refer tour.constant.ts file
  } as MenuItem;
}

type menuProp = {
  collapsed: boolean;
};

function AgentMenu({ collapsed }: menuProp) {
  const navigate = useNavigate();
  const handleNavigate = (path: string): void => {
    navigate(path);
  };
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    path === '/' && navigate(RouteConstants.agentDashBoard.path);
  }, []);

  const adminMenu: MenuItem[] = [
    getItem(
      'Home',
      RouteConstants.agentDashBoard.key,
      RouteConstants.agentDashBoard.icon,
      undefined,
      () => handleNavigate(RouteConstants.agentDashBoard.path)
    ),

    getItem(
      'My Producer',
      'agent-licenses',
      <CustomIcon icon={IconConstants.agentLicenses} />,
      [
        getItem(
          'Manage Licenses',
          RouteConstants.agentAllLicenses.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentAllLicenses.path)
        ),
        // agentSideStore.paymentConfig?.renewalPaymentPayee ===
        //   PayeeEnum.AGENCY &&
        // agentSideStore.paymentConfig?.initialPaymentPayee === PayeeEnum.AGENCY
        //   ? null
        //   :
        getItem(
          'Pending Applications',
          RouteConstants.agentApplications.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentApplications.path)
        ),
        getItem(
          'Appointments',
          RouteConstants.agentPortalRTS.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentPortalRTS.path)
        ),
      ],
      undefined
    ),
    getItem(
      'Onboarding',
      'agent-information_',
      <CustomIcon icon={information} />,
      [
        getItem(
          'Employment History',
          RouteConstants.employmentHistory.key,
          undefined,

          undefined,
          () => handleNavigate(RouteConstants.employmentHistory.path)
        ),
        getItem(
          'Background Questions',
          RouteConstants.backgroundQuestions.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.backgroundQuestions.path)
        ),
        getItem(
          'Supporting Documents',
          RouteConstants.agentSupportDocuments.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentSupportDocuments.path)
        ),
      ]
    ),

    getItem(
      'My Account',
      'admin-settings',
      <CustomIcon icon={IconConstants.settings} />,
      [
        getItem(
          'Account',
          RouteConstants.agentProfile.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentProfile.path)
        ),
        getItem(
          'Team Invites',
          RouteConstants.invites.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.invites.path)
        ),
        // getItem(
        //   'Wallet',
        //   RouteConstants.wallet.key,
        //   RouteConstants.wallet.icon,
        //   undefined,
        //   () => handleNavigate(RouteConstants.wallet.path)
        // ),
      ]
    ),
  ];

  return (
    <CustomMenu
      menuKeys={adminMenu.map((itr) => itr?.key || '')}
      items={adminMenu}
      defaultOpenKey={['agent-licenses']}
      defaultSelectedKey={[RouteConstants.agentDashBoard.key]}
      style={{
        width: collapsed ? 100 : 260,
        height: 'calc(100vh - 200px)',
        boxSizing: 'border-box',
        overflow: 'auto',
      }}
    />
  );
}
AgentMenu.displayName = 'AgentMenu';

export default withRoles(AgentMenu, [RoleType.AGENT]);
