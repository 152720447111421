import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';

interface ContinueOnboardingModalProps {
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleContinueOnboarding: () => void;
}

const ContinueOnboardingModal: React.FC<ContinueOnboardingModalProps> = ({
  modalVisibility,
  setModalVisibility,
  handleContinueOnboarding,
}) => {
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      modalBodyRef.current &&
      !modalBodyRef.current.contains(event.target as Node)
    ) {
      setModalVisibility(false);
    }
  };

  useEffect(() => {
    if (modalVisibility) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisibility]);

  return (
    <Modal
      open={modalVisibility}
      centered
      closable={false}
      footer={null}
      bodyStyle={{
        overflow: 'auto',
        padding: 0,
      }}
      style={{
        height: '218px',
        width: '464px',
      }}
      className="continue-onboarding-modal"
    >
      <div ref={modalBodyRef} className="p-[42px_52px] rounded-[6px]">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '22px',
          }}
        >
          <span
            style={{
              marginBottom: 0,
              color: '#07212D',
              fontWeight: 600,
              fontSize: '20px',
              fontFamily: 'Figtree',
            }}
          >
            Do you want to continue onboarding?
          </span>
          <span
            style={{
              marginBottom: 0,
              color: '#99ACB5',
              fontWeight: 500,
              fontSize: '14px',
              fontFamily: 'Figtree',
              width: '360px',
            }}
          >
            You can start where you left off in the bulk onboarding process
          </span>
        </div>
        <div className="flex flex-row gap-4">
          <Button
            style={{
              color: '#001F45',
              lineHeight: '1.5',
            }}
            className="bg-[#E8EBEE] w-[102px] h-[36px] px-6 text-sm font-normal border-none shadow-none flex items-center justify-center font-figtree !important"
            onClick={() => setModalVisibility(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              color: '#fff',
              lineHeight: '1.5',
            }}
            className="bg-[#001F45] w-[242px] h-[36px] px-6 text-base font-normal border-none shadow-none flex items-center justify-center font-figtree !important"
            onClick={handleContinueOnboarding}
          >
            Continue Onboarding
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContinueOnboardingModal;
