import { StateConstants } from '../constants/state.constants';

export const getStateNameFromCode = (stateCode: string): string => {
  const stateName = StateConstants[stateCode];
  if (stateName) return stateName;
  else return stateCode;
};

export function getStateCodeFromName(stateName: string): string | undefined {
  for (const [code, name] of Object.entries(StateConstants)) {
    if (name.toLowerCase() === stateName.toLowerCase()) {
      return code;
    }
  }
  return stateName;
}

export function capitalizeFirstLetter(
  str: string | null | undefined
): string | null | undefined {
  return str && str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str;
}

export function convertStringToTitleCase(input: string): string {
  if (!input) return input;
  const result = input.replace(/_/g, ' ').replace(/-/g, ' ');
  return result.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
}

export function stringToColor(str: string) {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 9) - hash);
  });
  let threshold = 128;
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    if (value < threshold) {
      value = value + threshold;
    }
    color += value.toString(16).padStart(2, '0');
  }
  return color;
}

export function getTextColor(hexcolor: string) {
  return parseInt(hexcolor.replace('#', ''), 16) > 0xffffff / 2
    ? 'black'
    : 'white';
}

export const isDisableFilter = (tableData: Object[], filters: Object) => {
  return (
    !tableData.length &&
    (!Object.keys(filters).length ||
      (filters as { filterGroups: Object[] })?.filterGroups?.length === 0)
  );
};

export const applySort = (
  sorter: any,
  cb: (
    search?: undefined,
    page?: undefined,
    size?: undefined,
    sort?: object
  ) => void
): object => {
  let sort = {};
  if (Array.isArray(sorter)) return sort;
  if (sorter.order) {
    sort = {
      sortField: sorter.columnKey,
      sortOrder: sorter.order === 'ascend' ? 1 : -1,
    };
    cb(undefined, undefined, undefined, sort);
  } else {
    cb(undefined, undefined, undefined, {});
  }
  return sort;
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const filterRequestBody = (requestBody: any, url_: URL) => {
  if (requestBody?.filterGroups?.length) {
    requestBody?.filterGroups?.forEach((group: any) => {
      const key = group?.filters?.[0]?.key;
      const values = group?.filters?.map((filter: any) => filter?.value);

      if (key && values) {
        values.forEach((value: string) => {
          url_.searchParams.append(key, value);
        });
      }
    });
  }
  if (requestBody?.sortField) {
    url_.searchParams.append('sortField', requestBody?.sortField);
    url_.searchParams.append('sortOrder', requestBody?.sortOrder);
  }
};

/**
 * Converts fees (number) into string for display
 * - Converts Fees to have 2 decimal places if its not a whole number
 * - Returns the fees as it is if its a whole number
 * @param fees
 * @returns
 */
export const getDisplayFees = (fees?: number): string => {
  if (!fees) return '0';
  if (fees % 1 !== 0) {
    return fees.toFixed(2).toString();
  } else return fees.toString();
};

// Function to check if a string is an enum value
export function isEnumValue<T extends Record<string, string>>(
  enumObj: T,
  value: string
): value is T[keyof T] {
  return Object.values(enumObj).includes(value as T[keyof T]);
}
