import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { filterRequestBody } from '../utils/common.utils';

export class ScbReportService {
  static host = UrlConstants.backend;
  static modulePath = '/scb-reports';

  static async getAllScbReport(
    pageNumber: number,
    pageSize: number,
    bearerToken: string,
    reqBody?: object
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = new URL(
          `${this.host}/agency/bulk/onboard?size=${pageSize}&page=${pageNumber}`
        );
        if (reqBody) filterRequestBody(reqBody, url);
        const response = await apiStore.getApiClient(bearerToken).get(`${url}`);
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static async retrieveScbReport(bulkOnboardId: string, bearerToken: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}/agency/bulk/onboard/${bulkOnboardId}`;
        const response = await apiStore.getApiClient(bearerToken).get(url);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }

  static async bulkNpnLookUp(uploadedCsvData: Object, bearerToken: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}/agency/bulk/onboard`;
        const response = await apiStore.getApiClient(bearerToken).post(url, {
          bulkOnboardRequest: uploadedCsvData,
        });
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static async getBulkProducerMinimal(
    bearerToken: string,
    bulkOnboardId: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}/agency/bulk/onboard/proceed/${bulkOnboardId}`;
        const response = await apiStore.getApiClient(bearerToken).get(`${url}`);
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static async getProducerIdsForBulkOnboard(
    bearerToken: string,
    bulkOnboardId: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}/agency/bulk/onboard/producers/${bulkOnboardId}`;
        const response = await apiStore.getApiClient(bearerToken).get(`${url}`);
        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
  }
}
