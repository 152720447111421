export const costFormatter = (
  val: string | undefined | number,
  limitDecimals = true
): string => {
  return val
    ? `${limitDecimals ? (+val).toFixed(2) : val}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      )
    : '0';
};

export const isValidObjectId = (value: string) =>
  value.match(/^[0-9a-fA-F]{24}$/);
