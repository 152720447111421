interface LevelProps {
  children: React.ReactNode;
  styles?: object;
}

export const ListBuilderLevel: React.FC<LevelProps> = ({
  children,
  styles,
}) => {
  return (
    <div
      style={{
        padding: '1px 8px',
        background: '#FCEFCF',
        border: '1px solid #F8D79A',
        borderRadius: '26px',
        width: 'fit-content',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#001F45',
        ...(styles || {}),
      }}
      className="figtree"
    >
      {' '}
      {children}
    </div>
  );
};

interface TypeProps extends LevelProps {
  isProducer?: boolean;
}

export const ListBuilderType: React.FC<TypeProps> = ({
  children,
  styles,
  isProducer,
}) => {
  return (
    <div
      style={{
        padding: '1px 6px',
        background: isProducer
          ? '#EDF9FF'
          : 'var(--Secondary-Secondary100, #D3E7FF)',
        border: `1px solid ${isProducer ? '#001F45' : '#00A6FB'}`,
        borderRadius: '26px',
        width: 'fit-content',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#001F45',
        ...(styles || {}),
      }}
      className="figtree"
    >
      {' '}
      {children}
    </div>
  );
};

export const deleteButtonStyles = {
  background: '#EB3131',
  color: '#ffffff',
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 500,
  margin: '0 0 0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 120,
  gap: 12,
  height: 28,
};

export const duplicateButtonStyles = {
  background: 'var(--Secondary-Secondary, #001F45)',
  color: '#ffffff',
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
  height: 28,
};

export const listBuilderTitleStyle = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.5',
};
