import { FilterCondition, FilterOperator } from '../../../../enums/fiters.enum';

import { StateConstants } from '../../../../constants/state.constants';
import { filterType } from '../listView/appliedFilter';

export const conditionOptions = [
  { label: 'And', value: 'and' },
  { label: 'Or', value: 'or' },
];

export enum FilterTitles {
  TYPE = 'type',
  MATCH = 'match',
  VALUE = 'value',
}

export const typeOptions = [
  { label: 'Agency', value: 'agency' },
  { label: 'Producer', value: 'producer' },
];

export const WHO_ARE_LICENSED = 'Licensed';
export const WHO_ARE_NOT_LICENSED = 'Not Licensed';
export const WHO_ARE_APPOINTED_WITH_CARRIERS = 'Appointed with';
export const WHO_ARE_NOT_APPOINTED_WITH_CARRIERS = 'Not Appointed With';
export const WHO_ARE_IN_LEVEL = 'In Level';
export const WHO_ARE_IN_NOT_LEVEL = 'Not In Level';

export const matchOptions = [
  { label: WHO_ARE_LICENSED, value: WHO_ARE_LICENSED },
  { label: WHO_ARE_NOT_LICENSED, value: WHO_ARE_NOT_LICENSED },
  {
    label: WHO_ARE_NOT_APPOINTED_WITH_CARRIERS,
    value: WHO_ARE_NOT_APPOINTED_WITH_CARRIERS,
  },
  {
    label: WHO_ARE_APPOINTED_WITH_CARRIERS,
    value: WHO_ARE_APPOINTED_WITH_CARRIERS,
  },
  { label: WHO_ARE_IN_LEVEL, value: WHO_ARE_IN_LEVEL },
  { label: WHO_ARE_IN_NOT_LEVEL, value: WHO_ARE_IN_NOT_LEVEL },
];

export const stateOptions = Object.entries(StateConstants).map(([k, v]) => ({
  label: v,
  value: k,
}));

export const SelectedChip: React.FC<{
  value: string;
  onClick?: (value: string) => void;
  hideClose?: boolean;
}> = ({ value }) => {
  return (
    <span
      style={{
        background: '#005B8A',
        padding: '2px 8px',
        borderRadius: 26,
        color: '#ffffff',
      }}
      className="no-wrap"
    >
      <span style={{ textTransform: 'capitalize' }}>{value}</span>
      {/* {!hideClose && onClick ? (
        <span
          style={{ marginLeft: 4 }}
          className={!!onClick ? 'cursor-pointer' : ''}
          onClick={() => {
            if (onClick) {
              onClick(value);
            }
          }}
        >
          <CloseOutlined />{' '}
        </span>
      ) : null} */}
    </span>
  );
};

export const modalTitle = {
  fontFamily: 'Figtree',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '28px',
  color: '#07212D',
};

export const modalDesc = {
  fontFamily: 'Figtree',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  color: '#99ACB5',
  marginBottom: 24,
};

export const organizationOptions = [
  {
    label: 'Overview',
    key: '1',
  },
  {
    label: 'Lists',
    key: '2',
  },
];

export interface hiearchyListFilterType {
  condition?: string | null;
  type: string[];
  match: string;
  value: string[];
}

export interface ListAppointmentDTO {
  producerId?: string;
  companyName: string;
  _id: string;
  stateCode: string;
  agencyId?: string;
}

export interface createFilterPayload {
  listName: string;
  hierarchyListFilters: hiearchyListFilterType;
}

export const LICENSE_FILTER = [WHO_ARE_LICENSED, WHO_ARE_NOT_LICENSED];
export const LEVEL_FILTER = [WHO_ARE_IN_LEVEL, WHO_ARE_IN_NOT_LEVEL];
export const APPOINTMENTS_FILTER = [
  WHO_ARE_NOT_APPOINTED_WITH_CARRIERS,
  WHO_ARE_APPOINTED_WITH_CARRIERS,
];

// filters

export interface IAdvancedFilterType {
  key: string;
  type: string[];
  condition: FilterCondition;
  value: string | string[];
}

export interface IFilterGroup {
  operator: FilterOperator;
  filters: IAdvancedFilterType[];
  filterGroups?: IFilterGroup[];
}

export interface AdvancedFiltersDtoType {
  filterGroups: IFilterGroup[];
}

export enum HiearchyListFilterType {
  BOTH = 'both',
  LICENSE = 'license',
  APPOINTMENTS = 'appointments',
}

// Type guard functions to help with type checking
export const isStringArray = (value: string | string[]): value is string[] => {
  return Array.isArray(value);
};

export const isFilterGroup = (value: any): value is IFilterGroup => {
  return (
    value &&
    typeof value.operator === 'string' &&
    Array.isArray(value.filters) &&
    (!value.filterGroups || Array.isArray(value.filterGroups))
  );
};

export interface appliedFilterType {
  condition: string;
  type: string[];
  match: string;
  value: string[];
}

export const convertFiltersToIFilterGroups = (
  filters: appliedFilterType[]
): AdvancedFiltersDtoType | undefined => {
  if (!filters?.length) return undefined;

  const operator = filters?.[1]?.condition || 'and';

  const getFilterGroups = (
    filter: appliedFilterType,
    index: number
  ): IFilterGroup[] => {
    const LICENSE_KEY = 'licenseDetails.stateCode';
    const APPOINTMENT_KEY = 'appointmentDetails.companyName';
    const key = LEVEL_FILTER.includes(filter?.match)
      ? 'level'
      : LICENSE_FILTER.includes(filter?.match)
      ? LICENSE_KEY
      : APPOINTMENT_KEY;
    const condition = [
      WHO_ARE_IN_LEVEL,
      WHO_ARE_LICENSED,
      WHO_ARE_APPOINTED_WITH_CARRIERS,
    ].includes(filter?.match)
      ? FilterCondition.CONTAINS
      : FilterCondition.DOES_NOT_CONTAINS;
    return [
      {
        operator: operator as FilterOperator,
        filters: [
          {
            type: filter?.type,
            value: filter?.value,
            key,
            condition,
          },
        ],
        filterGroups: filters?.[index + 1]
          ? getFilterGroups(filters?.[index + 1], index + 1)
          : undefined,
      },
    ];
  };

  const filterGroups = getFilterGroups(filters?.[0], 0);

  if (filterGroups?.length) return { filterGroups };

  return undefined;
};

export const DEFAULT_FILTER = [
  { condition: FilterOperator.AND, type: null, match: null, value: null },
];

export const NEW_FILTER = [
  { condition: '', type: null, match: null, value: null },
];

//

export const buttonStyle = {
  background: 'var(--Secondary-Secondary, #001F45)',
  color: '#ffffff',
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 500,
  margin: '12px 0',
};

export const getFilterType = (filters: filterType[]) => {
  if (!filters?.length) return undefined;

  const filterCondition = filters?.map((d) => d.match).filter(Boolean) || [];

  const isLicenseIncluded =
    filterCondition?.includes(WHO_ARE_LICENSED) ||
    filterCondition?.includes(WHO_ARE_NOT_LICENSED);

  const isAppointmentsIncluded =
    filterCondition?.includes(WHO_ARE_APPOINTED_WITH_CARRIERS) ||
    filterCondition?.includes(WHO_ARE_NOT_APPOINTED_WITH_CARRIERS);

  if (isLicenseIncluded && isAppointmentsIncluded)
    return HiearchyListFilterType.BOTH;

  if (isAppointmentsIncluded) return HiearchyListFilterType.APPOINTMENTS;

  if (isLicenseIncluded) return HiearchyListFilterType.LICENSE;

  return undefined;
};
