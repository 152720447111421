import './draggerComponent.css';

import { Button, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import Dragger from 'antd/es/upload/Dragger';
import Papa from 'papaparse';
import downloadIcon from '../../../assets/icons/download-button.svg';
import googleSheetsLogo from '../../../assets/icons/googleSheetsLogo.svg';
import uploadBasket from '../../../assets/icons/uploadBasket.svg';

interface DraggerProps {
  handleFileChange: (info: any, fileContent: any) => void;
  setIsUploadComplete: (isUploadComplete: boolean) => void;
  acceptedFileSize: number;
  acceptedFileTypes: string[];
  isFileUploaded: boolean;
  uploadedFileName: string;
  setUploadedFile?: React.Dispatch<React.SetStateAction<any>>;
  onUpload?: (values?: any) => void;
}

const DraggerComponent = ({
  handleFileChange,
  setIsUploadComplete,
  acceptedFileSize,
  acceptedFileTypes,
  isFileUploaded,
  uploadedFileName,
  setUploadedFile,
  onUpload,
}: DraggerProps) => {
  const [fileData, setFileData] = useState<File>();
  const [fileContent, setFileContent] = useState<string>('');
  const [isFileUploadedState, setIsFileUploadedState] =
    useState(isFileUploaded);
  const [fileLink, setFileLink] = useState<string>(
    `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Onboard_SSN_NPN.csv`
  );

  useEffect(() => {
    setIsFileUploadedState(isFileUploaded);
  }, [isFileUploaded]);

  const formatFileSize = (size: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (size >= gigabyte) {
      return `${(size / gigabyte).toFixed(2)} GB`;
    } else if (size >= megabyte) {
      return `${(size / megabyte).toFixed(2)} MB`;
    } else if (size >= kilobyte) {
      return `${(size / kilobyte).toFixed(2)} KB`;
    } else {
      return `${size} B`;
    }
  };

  const handleBeforeUpload = (file: File) => {
    const fileType = file.type;
    const fileSize = file.size;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result;
      setFileContent(fileContent);
    };
    reader.readAsText(file);

    if (!acceptedFileTypes.includes(fileType)) {
      const supportedFormats = acceptedFileTypes.join(', ');
      notification.error({
        message: 'Error',
        description: `Unsupported file type. Supported file type(s): ${supportedFormats}`,
      });
      return false;
    }

    if (fileSize > acceptedFileSize) {
      const maxSizeFormatted = formatFileSize(acceptedFileSize);
      const fileSizeFormatted = formatFileSize(fileSize);
      notification.error({
        message: 'Error',
        description: `File size exceeds the maximum limit of ${maxSizeFormatted}. Current file size: ${fileSizeFormatted}`,
      });
      return false;
    }

    if (setUploadedFile) {
      const excludeColumns = ['s no', 'reason (optional)'];
      Papa.parse(file, {
        header: true,
        transform: (value, column: string) => {
          // Exclude unwanted columns
          if (excludeColumns.includes(column)) {
            return undefined; // Exclude the column
          }

          // Transform the value if needed
          return value.trim();
        },
        beforeFirstChunk: (chunk) => {
          // Split the headers
          const lines = chunk.split('\n');

          // Modify the headers
          const modifiedHeaders = lines[0]
            .split(',')
            .map((header) => transformHeader(header));
          // .filter((header) => !excludeColumns.includes(header));

          // Replace the original headers with modified headers
          lines[0] = modifiedHeaders.join(',');

          // Join the lines back together
          const modifiedChunk = lines.join('\n');

          return modifiedChunk;
        },
        complete: (results: any) => {
          setUploadedFile(results.data);
        },
        error: (error) => {
          console.error('Error:', error.message);
        },
      });
    }

    setFileData(file);

    setIsFileUploadedState(isFileUploaded);
    return true;
  };

  const transformHeader = (header: string) => {
    const lowercaseHeader = header.trim().toLowerCase();

    // Additional rules for specific headers
    switch (lowercaseHeader) {
      case 'ssn':
        return 'ssn';
      case 'npn':
        return 'npn';
      case 'lastname':
        return 'lastName';
      case 'email':
        return 'email';
      case 'name':
        return 'name';
      case 'License Number':
        return 'licenseNumber';

      // Add more rules as needed
      default:
        // If no specific rule, return the lowercase header
        return lowercaseHeader;
    }
  };

  const handleVerifyData = async () => {
    try {
      const response = { success: true };
      setIsUploadComplete(response.success);
    } catch (error) {
      const response = { failure: false };
      setIsUploadComplete(response.failure);
    }
  };

  const handleFileUpload = (info: any) => {
    handleFileChange(info, fileContent);
    handleVerifyData();
  };

  const dummyRequest = (onSuccess: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 1000);
  };
  return (
    <Dragger
      accept=".csv,.xls,.xlsx"
      beforeUpload={handleBeforeUpload}
      onChange={handleFileUpload}
      multiple={false}
      customRequest={({ onSuccess }) => {
        dummyRequest(onSuccess);
      }}
      className="custom-dragger"
      style={{
        height: '70px',
      }}
    >
      <p className="ant-upload-drag-icon">
        {isFileUploadedState ? (
          <img
            src={googleSheetsLogo}
            alt="googleSheetsLogo"
            style={{ width: '51.35px', height: '38px' }}
          />
        ) : (
          <img src={uploadBasket} alt="dropperLogo" />
        )}
      </p>
      <p
        className="ant-upload-hint"
        style={{ fontWeight: 500, margin: '5px 0 8px' }}
      >
        {isFileUploadedState ? (
          <span>{uploadedFileName}</span>
        ) : (
          <>
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Figtree !important',
                marginBottom: 0,
              }}
            >
              Drop your CSV File onto this area to upload
              <br />
              or
              <br />
            </Typography.Paragraph>
            <Button
              type="primary"
              style={{
                backgroundColor: '#001F45',
                fontFamily: 'Figtree !important',
                fontSize: '14px',
                fontWeight: 500,
                padding: '0 12px',
                color: '#fff',
                margin: '8px 0',
              }}
              className="upload-hint-choose-file"
            >
              Choose a CSV File
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <img src={downloadIcon} alt="downloadIcon" />
              <a
                href={fileLink}
                style={{
                  textDecoration: 'underline',
                  color: '#2286FF',
                  fontSize: '12px',
                  fontWeight: 500,
                  fontFamily: 'Figtree',
                }}
                download
                onClick={(e) => e.stopPropagation()}
              >
                Download Sample File
              </a>
            </div>
          </>
        )}
      </p>
      {isFileUploadedState && (
        <>
          <Button
            type="primary"
            style={{
              backgroundColor: '#001F45',
              fontFamily: 'Figtree !important',
              fontSize: '14px',
              fontWeight: 500,
              padding: '0 12px',
              color: '#fff',
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (onUpload) onUpload();
            }}
          >
            Confirm Upload
          </Button>
          <p
            className="ant-upload-hint"
            style={{ fontWeight: 500, marginTop: '8px' }}
          >
            <span className="upload-hint-choose-file">Replace File</span>
          </p>
        </>
      )}
    </Dragger>
  );
};

export default DraggerComponent;
