import {
  ProducerDetails,
  producerStateAssignments,
} from '../../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { Skeleton, Space, Tag } from 'antd';
import {
  getStateNameFromCode,
  getTextColor,
  stringToColor,
} from '../../utils/common.utils';

import { AddressDetails } from '../../types/data/addressDetails.type';
import { AssignedState } from '../../types/data/stateGroup.types';
import CustomDescription from '../common/descriptions/description';
import { GenderType } from '../../enums/genderType.enum';
import { PaymentConfig } from '../../types/response/data/agents/paymentConfig.type';
import StatesCircled from '../common/statesCircled/StatesCircled';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { maskSSN } from '../../utils/hash.utils';

export interface StateAddress {
  addresses: AddressDetails[];
  state: string;
  id: string;
}

export interface AgentBiographicInfo {
  id: string;
  name: {
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  gender: string;
  dateOfBirth: dayjs.Dayjs;
  businessEmail: string;
  agencyId: any;
  fax: string;
  phone: string;
  npn: string;
  ssn: string;
  stateAddresses: StateAddress[];
  assignedStates: AssignedState[];
  paymentConfig: PaymentConfig;
  autoRenewEnabled: boolean;
  selfAmountUsed: number;
  parentAmountUsed: number;
  homeState: string;
  addressLastUpdatedAt?: Date;
}

export interface GroupedStateAssignment {
  stateCode: string;
  territories?: {
    territoryId: string;
    territoryName: string;
  }[];
}

export const groupStateAssignments = (
  stateAssignments: producerStateAssignments[] | undefined
): GroupedStateAssignment[] => {
  if (!stateAssignments || stateAssignments.length === 0) {
    return [];
  }

  return Object.values(
    stateAssignments.reduce<{ [key: string]: GroupedStateAssignment }>(
      (acc, current) => {
        const { stateCode, territoryId, territoryName } = current;

        if (!acc[stateCode]) {
          acc[stateCode] = {
            stateCode,
            territories: [], // Initialize territories as an empty array
          };
        }

        if (territoryId && territoryName) {
          acc[stateCode].territories!.push({
            territoryId,
            territoryName,
          });
        }

        return acc;
      },
      {}
    )
  );
};

const AgentBiographicInfoCard: React.FC<{
  isLoading?: boolean;
  agent?: ProducerDetails;
  isDisabled?: boolean;
  onAssignmentClick?: () => void;
}> = ({ isLoading = false, agent, onAssignmentClick, isDisabled }) => {
  const getAddressForDisplay = (addressType: string) => {
    const addressInfo = agent?.address?.find(
      (data) => data.addressType === addressType
    );

    return addressInfo ? (
      <>
        {addressInfo.addressLine1 && (
          <>
            {addressInfo.addressLine1}
            <br />
          </>
        )}
        {addressInfo.addressLine2 && (
          <>
            {addressInfo.addressLine2}
            <br />
          </>
        )}
        {addressInfo.addressLine3 && (
          <>
            {addressInfo.addressLine3}
            <br />
          </>
        )}
        {addressInfo.city}, {addressInfo.stateCode}
        <br />
        {addressInfo.zip}
      </>
    ) : (
      '-'
    );
  };

  // const stateAssignments = agent?.stateAssignments?.[0]?.stateAssignments;

  const stateAssignments = agent?.stateAssignments?.[0]?.stateAssignments;

  const updatedStateAssignments: GroupedStateAssignment[] =
    groupStateAssignments(stateAssignments);

  const hashmap: { [key: string]: string } = {};

  stateAssignments
    ?.filter((itr) => itr.territoryId)
    .forEach((itr) => {
      if (itr.territoryId) hashmap[itr.territoryId] = itr.territoryName || 'a';
    });

  const FirstRowItems = [
    {
      label: 'First Name',
      descItem: <>{agent?.name?.firstName || '-'}</>,
    },
    {
      label: 'Last Name',
      descItem: <>{agent?.name?.lastName || '-'}</>,
    },
    {
      label: 'Business Email Address',
      descItem: <>{agent?.businessEmail ? agent?.businessEmail : '-'}</>,
    },
    {
      label: 'Date Of Birth',
      descItem: (
        <>
          {agent?.dateOfBirth
            ? new Date(dayjs(agent?.dateOfBirth).toDate()).toLocaleDateString(
                'en-US',
                {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                }
              )
            : '-'}
        </>
      ),
    },
    {
      label: 'Gender',
      descItem: (
        <Tag>
          {agent?.gender === GenderType.Male
            ? 'Male'
            : agent?.gender === GenderType.Female
            ? 'Female'
            : '-'}
        </Tag>
      ),
    },
  ];
  const SecondRowItems = [
    {
      label: 'Phone',
      descItem: <>{agent?.businessPhone ? agent?.businessPhone : '-'}</>,
    },
    {
      label: 'FAX',
      descItem: <>{agent?.fax ? agent?.fax : '-'}</>,
    },
    {
      label: 'NPN',
      descItem: <>{agent?.npn}</>,
    },
    {
      label: 'SSN',
      descItem: <>{agent?.ssn ? maskSSN(agent?.ssn) : '-'}</>,
    },
    {
      label: 'Home State',
      descItem: (
        <>
          {agent?.residentState ? (
            getStateNameFromCode(agent?.residentState)
          ) : (
            <span style={{ color: 'red' }}>MISSING</span>
          )}
        </>
      ),
    },
  ];
  const ThirdRowItems = [
    {
      label: 'Business Address',
      descItem: getAddressForDisplay('Business'),
    },
    {
      label: 'Mailing Address',
      descItem: getAddressForDisplay('Mailing'),
    },
    {
      label: 'Resident Address',
      descItem: getAddressForDisplay('Residence'),
    },
  ];
  const FourthRowItems = [
    // {
    //   label: 'Producer Type',
    //   descItem: (
    //     <>
    //       {agent?.agencyId === adminStore.agency?.id
    //         ? 'Line Only Producer'
    //         : 'General Producer'}
    //     </>
    //   ),
    // },
    {
      label: 'Assigned Territories',
      descItem: (
        <div>
          {Object.keys(hashmap).length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 8 }}>
              {Object.entries(hashmap).map(([stateGroupId, stateGroupName]) => {
                const bgColor = stringToColor(stateGroupId);
                return (
                  <Space size={[0, 8]} wrap key={stateGroupName}>
                    <Tag
                      color={'#D3F3F5'}
                      onClick={() => onAssignmentClick && onAssignmentClick()}
                      style={{ cursor: isDisabled ? 'default' : 'pointer' }}
                    >
                      <span
                        style={{
                          color: getTextColor(bgColor),
                        }}
                      >
                        {stateGroupName}
                      </span>
                    </Tag>
                  </Space>
                );
              })}
            </div>
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      label: 'Assigned States',
      descItem: (
        <Space wrap>
          {!isEmpty(updatedStateAssignments) ? (
            updatedStateAssignments?.map((assignedState) => {
              if (assignedState.territories?.length === 0)
                return (
                  <div onClick={() => onAssignmentClick && onAssignmentClick()}>
                    <StatesCircled
                      label={assignedState.stateCode}
                      key={assignedState.stateCode}
                      showTooltip={true}
                      tooltipTitle={`${getStateNameFromCode(
                        assignedState.stateCode
                      )}`}
                      style={
                        onAssignmentClick
                          ? { cursor: isDisabled ? 'default' : 'pointer' }
                          : {}
                      }
                    />
                  </div>
                );
              else {
                // const bgColor = stringToColor(assignedState.territoryId);
                const territoryNames = assignedState.territories
                  ? assignedState.territories
                      .map((t) => t.territoryName)
                      .join(', ')
                  : '';
                return (
                  <div
                    onClick={() => onAssignmentClick && onAssignmentClick()}
                    style={onAssignmentClick ? { cursor: 'pointer' } : {}}
                  >
                    <StatesCircled
                      key={assignedState.stateCode}
                      // backgroundColor={bgColor}
                      // labelColor={getTextColor(bgColor)}
                      label={assignedState.stateCode}
                      showTooltip={true}
                      tooltipTitle={`${getStateNameFromCode(
                        assignedState.stateCode
                      )} (Inherited from : ${territoryNames})`}
                      style={onAssignmentClick ? { cursor: 'pointer' } : {}}
                    />
                  </div>
                );
              }
            })
          ) : (
            <>-</>
          )}
        </Space>
      ),
    },
  ];

  return isLoading ? (
    <Skeleton active />
  ) : (
    <div>
      <CustomDescription
        column={5}
        items={FirstRowItems}
        layout="vertical"
        style={{ marginTop: '10px' }}
      />
      <CustomDescription
        column={5}
        items={SecondRowItems}
        layout="vertical"
        style={{ marginTop: '10px' }}
      />
      <hr className="primary-line" style={{ marginTop: '20px' }} />
      <CustomDescription
        column={5}
        items={ThirdRowItems}
        layout="vertical"
        style={{ marginTop: '10px' }}
      />
      <hr className="primary-line" style={{ marginTop: '20px' }} />
      <CustomDescription
        column={3}
        items={FourthRowItems}
        layout="vertical"
        style={{ marginTop: '10px' }}
      />
    </div>
  );
};

export default AgentBiographicInfoCard;
