import AdminMenu, { ADMIN_AGENT_MENU_ROUTES } from './siderMenu/siderMenu';
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Popover, Typography } from 'antd';
import { getInitials, getNameString } from '../utils/name.utils';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AgentSiderMenu from './siderMenu/agentSiderMenu';
import { MenuType } from '../enums/menuType.enum';
import { RouteConstants } from '../constants/routes.constants';
import SelectProfileSideNavbar from './common/userProfile/selectProfileSideNavbar';
import Sider from 'antd/es/layout/Sider';
import { adminStore } from '../stores/admin.store';
import { agentSideStore } from '../stores/agentPortalStore';
import { observer } from 'mobx-react-lite';
import questionIcon from '../assets/icons/question-fill.svg';

interface MenuProps {
  menuType: MenuType;
}

function Menu(props: MenuProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [historyStack, setHistoryStack] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState('');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    const userName = adminStore.account
      ? `${getNameString(adminStore.account.name)}`
      : `${agentSideStore.agentName}`;
    setDisplayName(userName);
    setInitials(getInitials(userName));
    if (
      location.pathname?.toLowerCase()?.startsWith('/agent') &&
      ADMIN_AGENT_MENU_ROUTES.some((route: string) =>
        location.pathname?.toLowerCase()?.endsWith(route?.toLowerCase())
      )
    )
      return;
    setHistoryStack(location.pathname);
  }, [location.pathname]);

  const popoverContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography.Paragraph
        style={{
          color: '#001F45',
          fontWeight: 600,
          fontFamily: 'Figtree !important',
          marginBottom: '4px',
          alignSelf: 'start',
        }}
      >
        Contact our Support Team!
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          color: '#001F45',
          fontWeight: 400,
          fontSize: '10px',
          opacity: '0.7',
          fontFamily: 'Figtree !important',
        }}
      >
        If you encounter any issues, have suggestions or need assistance. We’ll
        respond promptly
      </Typography.Paragraph>
      <Button
        type="primary"
        style={{
          alignSelf: 'start',
          backgroundColor: '#001F45',
          fontFamily: 'Figtree',
          fontSize: '12px',
          fontWeight: 500,
          padding: '0 12px',
        }}
        onClick={() => {
          window.location.href = `mailto:support@insuretrek.com`;
        }}
      >
        Raise an issue
      </Button>
    </div>
  );

  return (
    <Sider
      collapsible={false}
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
        adminStore.setCollapsed(value);
      }}
      style={{ height: '100vh', position: 'sticky', top: '0' }}
      width={260}
      collapsedWidth={100}
      theme="light"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          boxShadow: '4px 0px 13px 0px rgba(0, 31, 69, 0.06)',
        }}
      >
        <div style={{ flex: 1, justifyContent: 'center' }}>
          {/* <Card
            style={{
              width: '100%',
              border: 'none',
              margin: '0 16px',
              marginInlineStart: 'auto',
            }}
          >
            <div style={{ display: 'flex', gap: '4px', flexDirection: 'row' }}>
              <img
                src={logo}
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '4px',
                  border: '1px solid #EBEBEB',
                }}
                alt="logo"
              />
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                <span style={{ fontFamily: 'Figtree !important' }}>
                  {adminStore.account
                    ? `${adminStore.account?.name.firstName} ${adminStore.account?.name.lastName}`
                    : `${agentSideStore.agentName}`}
                </span>
              </Typography.Paragraph>
            </div>
          </Card> */}
          <SelectProfileSideNavbar />
          {props.menuType !== MenuType.MainMenu && (
            <Card
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                borderRadius: '0px',
                backgroundColor: '#fafafa',
                borderTop: 'none',
                borderBottom: 'none',
              }}
            >
              <div style={{ flex: 1 }}>
                <Button
                  id="back-to-agents-view-button"
                  {...(!collapsed ? { icon: <ArrowLeftOutlined /> } : {})}
                  style={{
                    color: 'var(--primary-color)',
                    border: 'none',
                    boxShadow: 'none',
                    ...(!collapsed && { padding: 0 }),
                    backgroundColor: '#fafafa',
                  }}
                  onClick={() => {
                    // removed window.history as it saves the state if we navigate within the subsection as well.
                    if (historyStack) {
                      navigate(historyStack);
                    } else {
                      // the current entry in the history stack will be replaced with the new one with { replace: true }
                      navigate(RouteConstants.allAgents.path);
                    }
                  }}
                >
                  {!collapsed && <>Back</>}
                  {collapsed && <LeftOutlined />}
                </Button>
              </div>
            </Card>
          )}
          <AgentSiderMenu collapsed={collapsed} />

          <AdminMenu
            type={props.menuType === MenuType.MainMenu ? 'main' : 'subMenu'}
            collapsed={collapsed}
          />
        </div>
        {/* <img
          src={sideBarLogo}
          alt="overlapping image"
          style={{
            position: 'absolute',
            top: '-20px', // Adjust as needed to control overlap
            left: '16px', // Adjust horizontal position as needed
            height: '40px', // Set desired image dimensions
            width: '40px',
            borderRadius: '8px', // Optional styling
          }}
        /> */}
        <Card
          style={{
            margin: '0 16px',
            marginInlineStart: 'auto',
            borderTop: 'none',
            borderRadius: 0,
            borderRight: '1px solid rgba(5, 5, 5, 0.06)',
          }}
          className="sider-menu-img-card"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <Avatar
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '4px',
                  border: '1px solid #EBEBEB',
                  backgroundColor: '#33485E',
                  color: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '7px',
                }}
                alt="logo"
              >
                {initials}
              </Avatar>
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  maxWidth: '170px',
                  textOverflow: 'ellipsis',
                  marginBottom: 0,
                }}
                ellipsis
              >
                <span
                  style={{
                    fontFamily: 'Figtree',
                    textAlign: 'start',
                    fontWeight: 500,
                  }}
                >
                  {displayName}
                </span>
              </Typography.Paragraph>
            </div>
            <Popover
              content={popoverContent}
              trigger="click"
              placement="top"
              overlayInnerStyle={{
                backgroundColor: '#D3E7FF',
                width: '229px',
              }}
              overlayClassName="need-help-sidebar"
            >
              <img
                src={questionIcon}
                alt="questionIcon"
                style={{
                  marginLeft: 'auto',
                  height: '20px',
                  cursor: 'pointer',
                }}
              />
            </Popover>
          </div>
        </Card>
      </div>
    </Sider>
  );
}

export default observer(Menu);
