import React from 'react';

const AssignProducerIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.58008 6.3335V3.66683H7.91341V6.3335H10.5801V7.66683H7.91341V10.3335H6.58008V7.66683H3.91341V6.3335H6.58008ZM7.24674 13.6668C3.56484 13.6668 0.580078 10.682 0.580078 7.00016C0.580078 3.31826 3.56484 0.333496 7.24674 0.333496C10.9286 0.333496 13.9134 3.31826 13.9134 7.00016C13.9134 10.682 10.9286 13.6668 7.24674 13.6668ZM7.24674 12.3335C10.1923 12.3335 12.5801 9.9457 12.5801 7.00016C12.5801 4.05464 10.1923 1.66683 7.24674 1.66683C4.30122 1.66683 1.91341 4.05464 1.91341 7.00016C1.91341 9.9457 4.30122 12.3335 7.24674 12.3335Z"
        fill="#001F45"
      />
    </svg>
  );
};

export default AssignProducerIcon;
