import {
  Card,
  DatePicker,
  Form,
  Input,
  Progress,
  Radio,
  Switch,
  Tooltip,
} from 'antd';
import { EMAIL_REGEX, SSN_REGEX } from '../../../../constants/regex.constants';
import {
  IndividualOnboardAgentProps,
  IndividualOnboardDetailsProps,
} from './individualOnboardV2Type.type';
import {
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  baseFontStyles,
  calculateCompletenessPercentage,
  getAddressLine,
  paymentConfigOptions,
} from './IndividualOnboardHelpers';
import dayjs, { isDayjs } from 'dayjs';

import { IdConstants } from '../../../../constants/id.constants';
import StatesCircled from '../../../../components/common/statesCircled/StatesCircled';
import { formatDateFromString } from '../../../../utils/date.utils';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import location from '../../../../assets/icons/locationPin.svg';
import moment from 'moment';
import { useCallback } from 'react';
import { useForm } from 'antd/es/form/Form';

const IndividualOnboardDetails: React.FC<IndividualOnboardDetailsProps> = ({
  agentBioInfo,
  setAgentBioInfo,
  paymentOptions,
  setPaymentOptions,
  loading,
}) => {
  const [form] = useForm();

  const calculateScore = () =>
    calculateCompletenessPercentage(agentBioInfo, paymentOptions);

  const getAddresses = useCallback(() => {
    const addresses = [];

    const residence = agentBioInfo?.agentHomeStateAddresses?.filter(
      (data) => data.addressType === 'Residence'
    )?.[0];
    addresses.push({
      title: 'Residence address',
      ...getAddressLine(residence),
    });

    const business = agentBioInfo?.agentHomeStateAddresses?.filter(
      (data) => data.addressType === 'Business'
    )?.[0];
    addresses.push({
      title: 'Business address',
      ...getAddressLine(business),
    });

    const mailing = agentBioInfo?.agentHomeStateAddresses?.filter(
      (data) => data.addressType === 'Mailing'
    )?.[0];
    addresses.push({
      title: 'Mailing address',
      ...getAddressLine(mailing),
    });

    return addresses;
  }, [agentBioInfo.agentHomeStateAddresses]);

  return (
    <div className="individual-onboard-v2-details">
      <Card style={{ marginBottom: 12 }} className="producer-onboarding-cards">
        <AgentDetailsCard agentBioInfo={agentBioInfo} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 24,
            padding: '16px 20px 16px 22px',
          }}
          className="individual-onboarding-addresses"
        >
          {' '}
          {getAddresses().map((data, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 3,
                  ...(index + 1 !== 3 // details length
                    ? {
                        border: '1px',
                        borderRight: '1px solid #90D9FF',
                      }
                    : {}),
                }}
              >
                {' '}
                <div
                  className="font-poppins"
                  style={{
                    color: '#848484',
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  {' '}
                  {data.title}:
                </div>{' '}
                <div
                  className="font-poppins"
                  style={{
                    color: '#0C0C21',
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '16.8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <img src={location} alt="location" />{' '}
                  {!data.addressLine1 ? (
                    <div> - </div>
                  ) : (
                    <div>
                      <div>{data.addressLine1}</div>
                      {data.addressLine2}{' '}
                    </div>
                  )}
                </div>{' '}
              </div>
            );
          })}
        </div>
      </Card>
      <OnboardMsg
        score={calculateScore()}
        agentName={agentBioInfo?.firstName}
      />
      <div style={{ display: 'flex', gap: 12, width: '100%' }}>
        <Card className="complete-producer-profile" style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: '21px',
              marginBottom: 12,
            }}
          >
            Complete Profile{' '}
            <Tooltip title={`Complete ${agentBioInfo.firstName || ''} profile`}>
              {' '}
              {/* todo */}
              <QuestionCircleOutlined
                style={{
                  fontSize: '12px !important',
                  marginLeft: 6,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>
          <Form disabled={loading}>
            <div style={{ display: 'flex', gap: 24 }}>
              {/* <Form.Item
                label="Fax"
                name="fax"
                labelCol={{
                  span: 24,
                }}
                // initialValue={
                //   producerDetailsEnabled
                //     ? producerDetails?.fax
                //     : agencyDetails?.fax
                // }
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();

                      const numericValue = value.replace(/\D/g, '');
                      const formattedValue = formatPhoneAndFax(numericValue);
                      if (isPhoneNumberValid(formattedValue)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please enter a valid fax');
                    },
                  }),
                ]}
                style={{ flex: 1, maxWidth: 'calc(50% - 12px)' }}
              >
                <Input
                  placeholder="Enter FAX Number"
                  onChange={(e) => {
                    const formattedValue = formatPhoneAndFax(e.target.value);
                    if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                      form.setFieldsValue({ fax: formattedValue });
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item> */}
              {!agentBioInfo?.agentEmail ? (
                <Form.Item
                  label="Email address"
                  name="email"
                  labelCol={{
                    span: 24,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: 'Please enter a valid email',
                    },
                  ]}
                  style={{ width: 'calc(50% - 12px)' }}
                >
                  <Input
                    id="email-input"
                    placeholder="Enter Email address"
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ email: value });
                    }}
                    disabled={loading}
                    onBlur={() => {
                      const email = form.getFieldValue('email');
                      setAgentBioInfo((prev) => ({
                        ...prev,
                        agentUpdatedEmail: email || '',
                      }));
                    }}
                  />
                </Form.Item>
              ) : null}
            </div>
            <div style={{ display: 'flex', gap: 24 }}>
              {!agentBioInfo?.agentDateOfBirth ? (
                <Form.Item
                  label="Date of Birth"
                  name="dateOfBirth"
                  labelCol={{
                    span: 24,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select your date of birth',
                    },
                  ]}
                  initialValue={
                    agentBioInfo?.agentDateOfBirth
                      ? dayjs(new Date(agentBioInfo?.agentDateOfBirth))
                      : null
                  }
                  style={{ flex: 1, maxWidth: 'calc(50% - 12px)' }}
                >
                  <DatePicker
                    disabled={loading}
                    format="MM/DD/YYYY"
                    allowClear={false}
                    disabledDate={(current) =>
                      !current.isBefore(moment().subtract(18, 'years').toDate())
                    }
                    onSelect={(current) => {
                      form.setFieldsValue({ dateOfBirth: dayjs(current) });
                    }}
                    onBlur={() => {
                      const dob = form.getFieldValue('dateOfBirth');
                      setAgentBioInfo((prev) => ({
                        ...prev,
                        agentUpdatedDOB: dob || '',
                      }));
                    }}
                  />
                </Form.Item>
              ) : null}
              {!agentBioInfo?.agentSsn ? (
                <Form.Item
                  label="SSN"
                  name="ssn"
                  labelCol={{
                    span: 24,
                  }}
                  rules={
                    // making this conditional because we get encrypted ssn when a producer is existing in our system
                    !agentBioInfo?.agentSsn
                      ? [
                          {
                            required: true,
                            pattern: SSN_REGEX,
                            message: 'Please enter a valid ssn',
                          },
                          // {
                          //   validator: (_, value) =>
                          //     value.length === 9
                          //       ? Promise.resolve()
                          //       : Promise.reject(),
                          //   message: 'SSN needs to be 9 digits',
                          // },
                        ]
                      : []
                  }
                  initialValue={agentBioInfo?.agentSsn}
                  style={{ flex: 1, maxWidth: 'calc(50% - 12px)' }}
                >
                  <Input
                    placeholder="SSN"
                    maxLength={9}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ ssn: value });
                    }}
                    disabled={loading}
                    onPaste={(event) => {
                      const pastedText =
                        event.clipboardData.getData('text/plain');
                      if (!/^[0-9]+$/.test(pastedText)) {
                        event.preventDefault();
                      }
                      form.setFieldValue('ssn', pastedText);
                    }}
                    onBlur={() => {
                      const ssn = form.getFieldValue('ssn');
                      const isValid = SSN_REGEX.test(ssn);
                      setAgentBioInfo((prev) => ({
                        ...prev,
                        agentUpdatedSSN: isValid ? ssn : '',
                      }));
                    }}
                  />
                </Form.Item>
              ) : null}
            </div>
          </Form>
          {/* {agentBioInfo.agentGender ? ( */}
          <div>
            <div
              className="font-poppins"
              style={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '40px',
                color: '#222222',
              }}
            >
              Gender <span style={{ color: '#e91e1e' }}>*</span>
            </div>
            <div style={{ display: 'flex', gap: 12 }}>
              {[
                { key: 'M', label: 'Male' },
                { key: 'F', label: 'Female' },
              ].map((data) => {
                const gender =
                  agentBioInfo.agentUpdatedGender || agentBioInfo.agentGender;
                return (
                  <div
                    key={data.key}
                    className="cursor-pointer"
                    id={`individual-onboarding-v2-gender-type--${data}`}
                    style={{
                      padding: '16px 22px',
                      height: 45,
                      alignItems: 'center',
                      border: `1px solid ${
                        data.key === gender ? 'var(--primary-color)' : '#d9d9d9'
                      }`,
                      borderRadius: 6,
                      margin: '3px 0 24px',
                      display: 'flex',
                      width: 262,
                      flex: 1,
                      justifyContent: 'space-between',
                      pointerEvents: loading ? 'none' : 'inherit',
                    }}
                    onClick={() => {
                      if (data.key !== gender) {
                      }
                      setAgentBioInfo((prev) => ({
                        ...prev,
                        agentUpdatedGender: data.key,
                      }));
                    }}
                  >
                    {data.label}
                    <span style={{ display: 'block' }}>
                      <Radio checked={data.key === gender} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {/* ) : null} */}
        </Card>
        <Card className="complete-producer-profile" style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: '21px',
              marginBottom: 12,
            }}
          >
            Setup Payment{' '}
            <Tooltip
              title={`Setup Payment Details for ${
                agentBioInfo.firstName || ''
              }`}
            >
              {' '}
              {/* todo */}
              <QuestionCircleOutlined
                style={{
                  fontSize: '12px !important',
                  marginLeft: 6,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>
          <div>
            <div
              className="font-poppins"
              style={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '40px',
                color: '#222222',
              }}
            >
              New Licenses
            </div>
            <div style={{ display: 'flex', columnGap: 24 }}>
              {Object.values(paymentConfigOptions).map((data) => {
                return (
                  <div
                    key={data}
                    className="cursor-pointer"
                    id={`individiual-onboarding-v2-new-licenses--${data}`}
                    style={{
                      padding: '16px 22px',
                      alignItems: 'center',
                      border: `1px solid ${
                        data === paymentOptions.newLicenses
                          ? 'var(--primary-color)'
                          : '#d9d9d9'
                      }`,
                      borderRadius: 6,
                      margin: '3px 0 24px',
                      display: 'flex',
                      width: 209,
                      height: 45,
                      // flex: 1,
                      justifyContent: 'space-between',
                      pointerEvents: loading ? 'none' : 'inherit',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => {
                      if (data !== paymentOptions.newLicenses) {
                      }
                      setPaymentOptions((prev) => ({
                        ...prev,
                        newLicenses: data,
                        ...(data === paymentConfigOptions.PRODUCER
                          ? { autoApply: false }
                          : {}),
                      }));
                    }}
                  >
                    {data}
                    <span style={{ display: 'block' }}>
                      <Radio checked={data === paymentOptions.newLicenses} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              className="font-poppins"
              style={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '40px',
                color: '#222222',
              }}
            >
              Renewal Licenses
            </div>
            <div style={{ display: 'flex', columnGap: 24 }}>
              {Object.values(paymentConfigOptions).map((data) => {
                return (
                  <div
                    key={data}
                    className="cursor-pointer"
                    id={`individual-onboarding-v2-renewal--${data}`}
                    style={{
                      padding: '16px 22px',
                      alignItems: 'center',
                      border: `1px solid ${
                        data === paymentOptions.renewalLicenses
                          ? 'var(--primary-color)'
                          : '#d9d9d9'
                      }`,
                      borderRadius: 6,
                      margin: '3px 0 12px',
                      display: 'flex',
                      width: 209,
                      height: 45,
                      justifyContent: 'space-between',
                      pointerEvents: loading ? 'none' : 'inherit',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => {
                      if (data !== paymentOptions.renewalLicenses) {
                      }
                      setPaymentOptions((prev) => ({
                        ...prev,
                        renewalLicenses: data,
                        ...(data === paymentConfigOptions.PRODUCER
                          ? { autoApply: false }
                          : {}),
                      }));
                    }}
                  >
                    {data}
                    <span style={{ display: 'block' }}>
                      <Radio
                        checked={data === paymentOptions.renewalLicenses}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              margin: '24px 0',
            }}
          >
            <Switch
              id={
                IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
                '-onboarding-agents'
              }
              disabled={
                loading ||
                paymentOptions.newLicenses === paymentConfigOptions.PRODUCER ||
                paymentOptions.renewalLicenses === paymentConfigOptions.PRODUCER
              }
              checked={paymentOptions.autoApply}
              onChange={(e) =>
                setPaymentOptions((prev) => ({
                  ...prev,
                  autoApply: !prev.autoApply,
                }))
              }
            />
            <div style={{ ...baseFontStyles.subHeading }}>Auto Apply</div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default IndividualOnboardDetails;

const OnboardMsg: React.FC<{ score: number; agentName: string }> = ({
  score,
  agentName,
}) => {
  return (
    <div
      className="font-poppins"
      style={{
        width: '100%',
        height: 80,
        borderRadius: '0 0 8px 8px',
        background: '#001F45',
        border: '7px',
        borderTop: `7px solid ${
          score === 100 ? '#0F9D58' : 'rgba(255, 201, 66, 1)'
        }`,
        padding: '12px 24px',
        marginBottom: 12,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          className="font-poppins"
          style={{
            color: '#FAFAFA',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '27px',
          }}
        >
          Complete {agentName}'s InsureTrek Profile
        </div>
        <div
          className="font-poppins"
          style={{
            color: 'rgba(107, 205, 255, 1)',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '15px',
            marginTop: 2,
          }}
        >
          By making sure this profile is complete, it'll be easy to apply and
          renew licenses down the road.
        </div>
      </div>
      <div>
        <Progress
          percent={score}
          size={48}
          type="circle"
          trailColor={score === 100 ? '#0F9D58' : '#FCEFCF'}
          strokeColor={score === 100 ? '#0F9D58' : '#FFC942'}
          strokeWidth={16}
          format={(percent) => (
            <span
              style={{
                color: score === 100 ? '#0F9D58' : '#FFC942',
                fontWeight: 600,
                fontSize: 10,
                lineHeight: '12px',
              }}
            >
              {percent}%
            </span>
          )}
        />
      </div>
    </div>
  );
};

export const AgentDetailsCard: React.FC<{
  agentBioInfo: IndividualOnboardAgentProps;
  showNpn?: boolean;
}> = ({ agentBioInfo, showNpn = true }) => {
  const details = [
    { title: 'NPN', value: agentBioInfo?.agentNPN || '' },
    {
      title: 'SSN',
      value: agentBioInfo?.agentSsn || agentBioInfo?.agentUpdatedSSN,
    },
    {
      title: 'Date of Birth',
      value:
        agentBioInfo?.agentDateOfBirth || agentBioInfo?.agentUpdatedDOB
          ? formatDateFromString(
              (agentBioInfo?.agentDateOfBirth ||
                agentBioInfo?.agentUpdatedDOB) as string
            )
          : '',
    },
  ];

  const homeState = agentBioInfo?.agentHomeStateAddresses?.[0]?.stateCode;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
        padding: '16px 20px 16px 22px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: 8,
          ...(showNpn
            ? { border: '1px', borderRight: '1px solid #E6E6E6' }
            : {}),
          width: 'fit-content',
          minWidth: 375,
        }}
      >
        {' '}
        <div
          style={{
            width: 78,
            height: 78,
            borderRadius: '50%',
            display: 'grid',
            placeContent: 'center',
            backgroundColor: '#EDF9FF',
            fontSize: 32,
            color: '#0074B0',
            fontWeight: 'bolder',
          }}
        >
          {agentBioInfo?.firstName?.[0] || ''}
          {agentBioInfo?.lastName?.[0] || ''}
        </div>
        <div style={{ marginTop: 6, marginRight: 24 }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: '500',
              wordBreak: 'break-word',
              lineHeight: '24px',
              color: '#222222',
              display: 'flex',
              gap: 16,
              alignItems: 'center',
            }}
          >
            {agentBioInfo?.firstName || ''} {agentBioInfo?.lastName || ''}{' '}
            {homeState ? (
              <StatesCircled
                label={homeState}
                key={homeState}
                showTooltip={true}
                tooltipTitle={`${getStateNameFromCode(homeState)}`}
              />
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              gap: 24,
              fontSize: 10,
              lineHeight: '20px',
              fontWeight: 400,
              alignItems: 'center',
              color: '#1D2C50',
            }}
            className="overide-icon-size"
          >
            <p
              style={{
                minWidth: 70,
                whiteSpace: 'nowrap',
              }}
            >
              <PhoneOutlined style={{ marginRight: 6 }} />
              {agentBioInfo?.agentPhone || '-'}
            </p>
            <p
              style={{
                minWidth: 70,
                whiteSpace: 'nowrap',
              }}
            >
              <MailOutlined style={{ marginRight: 6 }} />
              {agentBioInfo?.agentEmail ||
                agentBioInfo?.agentUpdatedEmail ||
                '-'}
            </p>
            {/* <UserAddOutlined style={{ marginRight: 3 }} />{' '} */}
          </div>
        </div>
      </div>
      {showNpn ? (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#EDF9FF',
            padding: '6px 22px',
            flex: 1,
            alignItems: 'center',
            borderRadius: 12,
            gap: 48,
            height: 71,
            alignSelf: 'flex-start',
            border: '1.32px solid #DAF2FF',
          }}
        >
          {details.map((data, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 3,
                  ...(index + 1 !== details?.length
                    ? {
                        border: '1px',
                        borderRight: '1px solid #E6E6E6',
                      }
                    : {}),
                }}
              >
                {' '}
                <div
                  className="font-poppins"
                  style={{
                    color: '#4F9EFF',
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: '22.7px',
                  }}
                >
                  {' '}
                  {data.title}
                </div>{' '}
                <div
                  style={{
                    marginLeft: !data.value ? 12 : '',
                    color: '#1D2C50',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '26.43px',
                  }}
                >
                  {' '}
                  {data.title === 'Date of Birth' && isDayjs(data.value)
                    ? dayjs(data.value, 'MM/DD/YYYY').toString()
                    : (data.value || '-')?.toString()}
                </div>{' '}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
