import {
  AssignedState,
  StateLicenseConfigIndividualOnboarding,
} from '../../../../types/data/stateGroup.types';

import { Address } from '../../../../types/data/address.type';
import { AgentBioInfoProps } from '../../../../types/data/agentBioInfoProps.type';
import { LoaDetails } from '../../../../types/data/masterData';
import { Name } from '../../../../types/data/name.type';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import { State } from '../../../../components/agencyLicensing/agencyLicensingInterfaces.type';

export enum ProducerOnboardingLookupType {
  'LOOKUP_NPN' = 'lookup-npn',
  'LOOKUP_SSN' = 'lookup-ssn',
  'LOOKUP_LICENSE' = 'lookup-license',
}

export enum INDIVIDUAL_ONBOARD_STEPS {
  INDIVIDUAL_ONBOARDING_ONBOARD,
  INDIVIDUAL_ONBOARD_DETAILS,
  INDIVIDUAL_ONBOARD_ASSIGNMENTS,
  INDIVIDUAL_ONBOARD_ASSIGNMENTS_REVIEW,
}

export interface IndividualOnboardAgentProps extends AgentBioInfoProps {
  firstName: string;
  lastName: string;
  agentUpdatedEmail?: string;
  agentUpdatedSSN?: string;
  agentUpdatedDOB?: string;
  agentUpdatedGender?: string;
  producerId: string;
}

export interface paymentOptionsType {
  newLicenses: string;
  renewalLicenses: string;
  autoApply: boolean;
}

export interface IndividualOnboardDetailsProps {
  agentBioInfo: IndividualOnboardAgentProps;
  setAgentBioInfo: React.Dispatch<
    React.SetStateAction<IndividualOnboardAgentProps>
  >;
  paymentOptions: paymentOptionsType;
  setPaymentOptions: React.Dispatch<React.SetStateAction<paymentOptionsType>>;
  loading: boolean;
}

export interface IndividualOnboardOptionsProps {
  onHandleFinish: (value: IndividualOnboardAgentProps) => void;
}

export interface AssignmentProps {
  stateGroups: IndividualOnboardStateGroupProps[];
  selectedItems: StateLicenses[];
  setStateGroups: React.Dispatch<
    React.SetStateAction<IndividualOnboardStateGroupProps[]>
  >;
  setSelectedItems: React.Dispatch<React.SetStateAction<StateLicenses[]>>;
  isReview?: boolean;
  selectedTerritory: string;
  setSelectedTerritory: React.Dispatch<React.SetStateAction<string>>;
  selectedState: string;
  setSelectedState: React.Dispatch<React.SetStateAction<string>>;
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

export interface IndividualOnboardAssignmentProps extends AssignmentProps {
  setDisableSave: React.Dispatch<React.SetStateAction<boolean>>;
  producerDetails: ProducerDetails;
  // setAgentBioInfo: React.Dispatch<
  //   React.SetStateAction<IndividualOnboardAgentProps>
  // >;
}

export const AssignmentOptions = {
  TERRITORIES: 'Assign Territories',
  STATE: 'Assign States',
};

export type StateLicenses = {
  [key: string]: {
    name: string;
    loas: {
      name: string;
      loaCode: string;
      id?: string;
    }[];
  };
} & {
  name: string;
};

export interface IndividualOnboardStateGroupProps {
  id: string;
  name: string;
  isSelected: boolean;
  stateLicenseConfigs: StateLicenseConfigIndividualOnboarding[];
}

export interface StateProps extends State {
  isDisabled?: boolean;
  tooltipText?: string | null;
}

export interface AssignmentStateTerritoriesProps {
  onSelect: (state: string, type: 'State' | 'Territories') => void; // Function to handle state selection
  currentSelected: string;
  filteredItems: StateProps[] | string[];
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  placeHolder: string;
  type: 'Territories' | 'State';
  selectedCount: any[];
  onCheck: (checked: boolean, type: string) => void;
  checkBoxProps?: Object;
  disabledItems?: any[];
  isCheckboxNeeded: boolean;
  activeState: string[];
  isAssignmentPage?: boolean;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  selectedTab?: string;
  selectedItems?: any[];
}

export interface OnboardedWelcomeScreenProps {
  firstName: string;
  lastName: string;
  AgentAmount: number;
  TerritoryCount: number;
  StateCount: number;
  producerId: string;
  onOnboardAnotherProducer: () => void;
}

export interface LoaProps {
  text: string;
  loaId: string;
  licenseClassCode: string;
  isCheckboxNeeded?: boolean;
  selectedIds: string[];
  disableCheckBox?: boolean;
  handleCheckboxChange: (
    licenseClassCode: string,
    id: string,
    loaId: { loaId: string; id: string },
    checked: boolean
  ) => void;
  loaDbId: string;
  activeLoa?: boolean;
  tooltipText: string | null;
}

export interface LicenseClassProps {
  text: string;
  licenseClassCode: string;
  isCheckboxNeeded?: boolean;
  residencyType: ResidencyType;
  LoaDetails: LoaDetails[];
  selectedIds: string[];
  handleCheckboxChange: (
    licenseClassCode: string,
    id: string,
    loaId: { loaId: string; id: string } | null,
    checked: boolean
  ) => void;
  stateCode: string;
  disableCheckBox?: boolean;
  isLicenseChecked: boolean;
  activeLicense?: ActiveLicense;
  isAssignmentPage?: boolean;
  tooltipText: string | null;
}

export interface OnboardProducerType {
  lookupType: ProducerOnboardingLookupType;
  npn?: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  licenseState?: string;
  producerEmailAddress: string;
  ignoreWatchlistWarning?: boolean;
}

export interface producerStateAssignments {
  stateCode: string;
  territoryId?: string;
  territoryName?: string;
  licenseStatus?: string;
}

export interface producerAgencyStateAssignments {
  agencyId: string;
  stateAssignments: producerStateAssignments[];
}

export interface ProducerDetails {
  id: string;
  residentState: string;
  address: Address[];
  ssn: string;
  npn: string;
  account: Account;
  contactEmail: string;
  stateAssignments?: producerAgencyStateAssignments[];

  //
  name?: Name;
  gender: string;
  dateOfBirth: string;
  paymentConfig: UpdatedPaymentConfig;
  businessPhone: string;
  accountId: string;
  businessEmail: string;
  engineLights: EngineLights;
  processingFlags: ProcessingFlags;
  addressLastUpdatedAt?: Date;
  isMailingSameAsBusinessAddress?: boolean;
  isResidentSameAsBusinessAddress?: boolean;
  //

  // agencyId?: {
  //   id?: string;
  // };
  fax?: string;
  assignedStates?: AssignedState[];
}

//
export interface ProducerLicensingInfo {
  status: string;
  data: ProducerDetails;
}

interface Account {
  id: string;
  name: AccountName;
  email: string;
  roles: any[];
}

interface AccountName {
  firstName: string;
  middleName: string;
  lastName: string;
}

interface EngineLights {
  paymentConfig: boolean;
  employmentHistory: boolean;
  backgroundQuestion: boolean;
  isResidentLicenseActive: boolean;
}

export interface UpdatedPaymentConfig {
  licenseInitialPayee: string;
  licenseRenewalPayee: string;
  paymentType: string;
  isAutoRenewalActive: boolean;
}

interface ProcessingFlags {
  isLicenseProcessing: boolean;
  isAssignmentProcessing: boolean;
}

export interface updateProducerAssignmetPayload {
  stateCode: string;
  territoryId?: string;
  licenseConfigurations: string[];
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'Unknown',
}

export interface UpdateProducerDTO {
  dateOfBirth?: Date;
  gender?: Gender;
  ssn?: string;
  paymentConfig?: UpdatedPaymentConfig;
  stateAddresses?: {
    stateCode: string;
    addresses: Address;
  };
  businessEmail?: string;
}

export type LoaCode = {
  loa: string;
  loaCode: string;
  id: string;
};

export type ActiveLicense = {
  stateCode: string;
  status: string;
  licenseClass: string;
  licenseClassCode: string;
  loaDetails: LoaCode[];
};
