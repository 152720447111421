import { Button, Form, Pagination, Row, Table, notification } from 'antd';
import {
  CsvJson,
  ScbReportBody,
  multipleOnboardStore,
} from '../../../../stores/multipleOnboardStore';
import {
  EMAIL_REGEX,
  LAST_NAME_REGEX,
  NPN_REGEX,
  SSN_REGEX,
} from '../../../../constants/regex.constants';
import {
  isErrorNoPaymentMethodsFound,
  isErrorSsnIsInvalid,
  isErrorStripeCustomerMissing,
  isErrorStripeOnboardingRequired,
  isErrorUnprocessedScbReport,
} from '../../../../utils/errorUtils';
import { useEffect, useState } from 'react';

import { BulkOnboardingStage } from '../../../../enums/bulkOnboardingStage.enum';
import ContinueOnboardModal from '../multipleOnboard/continueOnboardModal';
import ContinueOnboardingModal from '../multipleOnboard/continueOnboardingModal';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import LookUpModal from '../../../../utils/modal/mutiple-onboard/upload-csv/upload-csv.modal';
import { MultipleOnboardLookup } from '../../../../enums/onboardSteps.enum';
import { NotificationType } from '../../../../types/common/notification.type';
import OnboardingStatusCell from './onbardingStatusCell';
import PreviewCSVModal from '../../../../utils/onboard-agents/multipleOnboard/preview-csv';
import ProgressBar from '../../../../components/common/progressBar/progressBar';
import { RedoOutlined } from '@ant-design/icons';
import { ScbReportService } from '../../../../services/scb-report.service';
import StripeOnboardingRequiredToast from '../../../../components/common/stripeOnboardingRequiredToast';
import { applySort } from '../../../../utils/common.utils';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { useAuth } from '../../../../auth/authProvider';
import { verifyCSV } from '../../../../enums/verifyCSV.enum';

export interface NiprLookUpData {
  npn?: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  stateCode?: string;
  prefferredEmail?: string;
  name?: string;
}

interface RefreshCellProps {
  record: Report;
  disable: boolean;
  updateReport: (reportId: string, response: Object) => void;
}

function RefreshCell({ record, disable, updateReport }: RefreshCellProps) {
  const [pending, setPending] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();

  const showAlert = (message: string, type: NotificationType) => {
    api[type]({
      message: 'Notification',
      description: message,
    });
  };

  const retrieveScbReport = async () => {
    try {
      setPending(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.retrieveScbReport(
        record.id,
        token
      );

      if (response.status === 'SUCCESS' && response.message) {
        showAlert(response.message, 'warning');
      }

      if (response.status === 'SUCCESS' && response.data) {
        updateReport(record.id, response?.data);
      }
      if (response.status !== 'SUCCESS') {
        showAlert('SomeThing went wrong please try again later', 'error');
      }
      setPending(false);
    } catch (err: any) {
      if (isErrorStripeCustomerMissing(err.response.data))
        showAlert(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING, 'error');
      else if (isErrorNoPaymentMethodsFound(err.response.data)) {
        showAlert(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND, 'error');
      } else if (isErrorUnprocessedScbReport(err.response.data))
        showAlert(err?.response.data.error?.message, 'error');
      else
        showAlert(
          'Please review the CSV file for any incorrect data and try uploading again.',
          'error'
        );

      setPending(false);
      console.error(err);
    }
  };

  return pending ? (
    <>
      {contextHolder}
      <Button style={{ width: '100px' }} loading={pending}></Button>
    </>
  ) : (
    <>
      {contextHolder}
      <Button
        disabled={
          disable ||
          (record.onboardingStage === BulkOnboardingStage.COMPLETED &&
            (record.bulkAssignmentProcessing === false ||
              isUndefined(record.bulkAssignmentProcessing))) ||
          record.onboardingStage === BulkOnboardingStage.CONTINUE_ONBOARDING ||
          record.onboardingStage === BulkOnboardingStage.BULK_ASSIGNMENT
        }
        onClick={() => {
          retrieveScbReport();
        }}
      >
        <RedoOutlined />
        Refresh
      </Button>
    </>
  );
}

interface NiprBulkLookUpDto {
  npns: string[];
  niprBulkSsnLookUpDto: string[];
  niprBulkLicenseLookUpDto: string[];
}

export interface Report {
  id: string;
  reportId: string;
  title: string;
  npnCount: number;
  niprBulkLookUpDto: NiprBulkLookUpDto;
  admin: string;
  agency: string;
  createdAt: string;
  niprResponseTimestamp: string;
  niprScbReportResponseDto: ScbReportBody;
  lastPollTimestamp: string;
  isOnboarded: boolean;
  csvJson: CsvJson[];
  isBeingProcessed: boolean;
  requestBody: any[];
  canBeOnboardedCount: number;
  cannotBeOnboardedCount: number;
  onboardingStage: string;
  bulkAssignmentProcessing: boolean;
}

interface OnboardDraftProps {
  handleNext: () => void;
  handleContinueOnboarding: (id: string) => void;
  onboardingStage?: string;
  setOnboardingStage?: any;
  refreshKey?: number;
}

function OnboardDraft({
  handleNext,
  handleContinueOnboarding,
  setOnboardingStage,
  onboardingStage,
  refreshKey,
}: OnboardDraftProps) {
  const [data, setData] = useState<Report[]>([]);
  const { getAccessTokenSilently } = useAuth();
  const [pageSize, setPageSize] = useState(100);
  const [isLookUpModalVisible, setLookUpModalVisible] = useState(false);
  const [
    isBulkOnboardCountConfirmModalVisible,
    setIsBulkOnboardCountConfirmModalVisible,
  ] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState<NiprLookUpData[]>();
  const [importAgentsSuccessful, setImportAgentsSuccessful] =
    useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [previewModalVisibility, setPreviewModalVisibility] =
    useState<boolean>(false);
  const [previewModalData, setPreviewModalData] = useState<CsvJson[]>([]);
  const [isContinueOnboardingModalOpen, setIsContinueOnboardingModalOpen] =
    useState<boolean>(false);
  const [onboardModalVisibility, setOnboardModalVisibility] =
    useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');
  const [sortValue, setSortValue] = useState({});
  const [recordId, setRecordId] = useState<string>('');

  const showAlert = (
    message: string,
    type: NotificationType,
    heading: string
  ) => {
    api[type]({
      message: heading,
      description: message,
    });
  };

  const showError = (message: React.ReactNode) => {
    api['error']({
      message: 'Stripe Onboarding Incomplete',
      description: message,
    });
  };

  useEffect(() => {
    multipleOnboardStore.setMaxStep(0);
    multipleOnboardStore.setMinStep(0);
    fetchScbReports();
  }, [refreshKey]);

  const updateReport = (reportId: string, response: any) => {
    const reportToUpdate: any = data.findIndex(
      (report: Report) => report.id === reportId
    );
    if (reportToUpdate !== -1) {
      const newData: any = data.map((data, index) => {
        if (index === reportToUpdate) {
          return {
            ...data,
            isBeingProcessed: response?.isBeingProcessed,
            requestBody: response?.requestBody,
            canBeOnboardedCount: response?.canBeOnboardedCount,
            cannotBeOnboardedCount: response?.cannotBeOnboardedCount,
            onboardingStage: response?.onboardingStage,
          };
        }
        return data;
      });
      setData(newData);
    }
  };
  const hasDuplicates = (array: any[]) => {
    const npnSet = new Set();
    const ssnSet = new Set();
    const emailSet = new Set();

    const generateDuplicateErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields have duplicate in this batch. Ensure there are no duplicates.`;
    const generateInvalidErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields are not valid in this batch. Please enter a valid ${field}`;

    for (const obj of array) {
      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.NPN) {
        if (obj.npn) {
          if (npnSet.has(obj.npn)) {
            return generateDuplicateErrorMessage('NPN', obj.npn);
          }
          if (NPN_REGEX.test(obj.npn.trim())) {
            npnSet.add(obj.npn);
          } else {
            return generateInvalidErrorMessage('NPN', obj.npn);
          }
        }
      }
      if (obj.ssn) {
        if (ssnSet.has(obj.ssn)) {
          return generateDuplicateErrorMessage('SSN', obj.ssn);
        }
        if (SSN_REGEX.test(obj.ssn.trim())) {
          ssnSet.add(obj.ssn);
        } else {
          return generateInvalidErrorMessage('SSN', obj.ssn);
        }
      }
      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.SSN) {
        if (obj.lastName) {
          if (!LAST_NAME_REGEX.test(obj.lastName.trim())) {
            return generateInvalidErrorMessage('last name', obj.lastName);
          }
        }
      }
      if (obj.email) {
        if (emailSet.has(obj.email)) {
          return generateDuplicateErrorMessage('Email', obj.email);
        }
        if (EMAIL_REGEX.test(obj.email)) {
          emailSet.add(obj.email);
        } else {
          return generateInvalidErrorMessage('Email', obj.email);
        }
      }
    }
    return verifyCSV.NO_DUPLICATE;
  };

  const onFinish = async (values: any) => {
    try {
      const token = await getAccessTokenSilently();
      if (uploadedFile) {
        setModalLoading(true);
        const filteredFileContent = uploadedFile.filter(
          (obj) => Object.keys(obj).length > 1
        );
        if (filteredFileContent) {
          const duplicatedValue = hasDuplicates(filteredFileContent);
          if (duplicatedValue !== verifyCSV.NO_DUPLICATE) {
            showAlert(`${duplicatedValue}`, 'error', 'ERROR');
            setModalLoading(false);
            return;
          }
          const response: any = await ScbReportService.bulkNpnLookUp(
            filteredFileContent,
            token
          );
          if (response.status === 'SUCCESS') {
            showAlert('CSV Uploaded SuccessFully', 'success', 'SUCCESS');
            setLookUpModalVisible(false);
          } else {
            showAlert('CSV Upload Failed , Try Again', 'error', 'ERROR');
            setLookUpModalVisible(false);
          }
          setModalLoading(false);
          fetchScbReports();
        }
        form.resetFields();
        multipleOnboardStore.setIsFileUploaded(false);
        multipleOnboardStore.setLookupMethod(MultipleOnboardLookup.SSN);
        multipleOnboardStore.setUploadFileName('');
        multipleOnboardStore.resetMultipleOnboard();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (isErrorStripeOnboardingRequired(error.response.data))
          showError(<StripeOnboardingRequiredToast />);
        else if (isErrorStripeCustomerMissing(error.response.data))
          showError(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING);
        else if (isErrorNoPaymentMethodsFound(error.response.data))
          showError(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND);
        else if (isErrorSsnIsInvalid(error.response.data))
          showError(ErrorMessageConstants.INVALID_SSNS);
        else
          showAlert(
            'Some fields in the uploaded CSV are missing. Please review and update the values accordingly before uploading again. You can refer to the sample CSV for the correct format if needed',
            'error',
            'ERROR'
          );
      } else {
        showAlert(
          'Something went wrong, please try again after some time.',
          'error',
          'ERROR'
        );
      }
      setModalLoading(false);
      console.error('Error:', error?.response?.data?.error?.message);
    } finally {
    }
  };

  const fetchScbReports = async (
    pageNumberprops?: number,
    pageSizeprops?: number,
    type?: any,
    sort?: object
  ) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getAllScbReport(
        pageNumberprops || pageNumber,
        pageSizeprops || pageSize,
        token,
        { ...(sort || sortValue || {}) }
      );
      setData(response.data);
      setTotalCount(response.totalCount);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchScbReports(newPageNumber, newPageSize);
  };

  const columns = [
    // {
    //   title: 'Report Id',
    //   dataIndex: 'reportId',
    //   width: '25%',
    // },
    // {
    //   title: 'Time Stamp',
    //   dataIndex: 'createdAt',
    //   sorter: true,
    //   key: 'createdAt',
    //   render: (date: string, record: Report) => {
    //     return getTimeDifference(date);
    //   },
    // },
    {
      title: 'No. of Producers',
      dataIndex: 'npnCount',
      key: 'npnCount',
      width: 154,
      sorter: true,
      render: (npnCount: number) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                borderRadius: '26px',
                border: '1px solid #00A6FB',
                backgroundColor: '#D3E7FF',
                color: '#001F45',
                fontWeight: 400,
                fontFamily: 'Figtree',
                fontSize: '12px',
              }}
            >
              {npnCount}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: 'Last Poll',
    //   dataIndex: 'lastPollTimestamp',
    //   render: (date: string, record: Report) => {
    //     return getTimeDifference(date);
    //   },
    // },
    {
      title: 'Ratio',
      dataIndex: 'canBeOnboardedCount',
      sorter: true,
      width: 165,
      key: 'canBeOnboardedCount',
      render: (item: string, record: Report) => {
        const total =
          record.canBeOnboardedCount + record.cannotBeOnboardedCount;
        return (
          <ProgressBar
            disabled={
              record.onboardingStage === BulkOnboardingStage.RETRIEVE_SCB
            }
            successPercentage={(record.canBeOnboardedCount / total) * 100}
            errorPercentage={(record.cannotBeOnboardedCount / total) * 100}
            successTooltipText={`${record.canBeOnboardedCount}/${total} Producers`}
            errorTooltipText={`${record.cannotBeOnboardedCount}/${total} Producers`}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'onboardingStage',
      sorter: true,
      key: 'onboardingStage',
      width: 153,
      render: (onboardingStage: string, record: Report) => {
        return <OnboardingStatusCell onboardingStage={onboardingStage} />;
      },
    },
    {
      title: 'Uploaded On',
      dataIndex: 'createdAt',
      sorter: true,
      width: 587,
      key: 'createdAt',
      render: (date: string, record: Report) => {
        return (
          <span
            style={{
              fontFamily: 'Figtree',
              color: '#001F45',
            }}
          >
            {moment(date).format('MM/DD/YY h:mma')}
          </span>
        );
      },
    },
    // {
    //   render: (_: any, record: Report) => {
    //     return (
    //       <RefreshCell
    //         record={record}
    //         disable={record.niprScbReportResponseDto ? true : false}
    //         updateReport={updateReport}
    //       />
    //     );
    //   },
    // },
    // {
    //   render: (_: any, record: Report) => {
    //     const onboardingStageText: any = {
    //       [BulkOnboardingStage.RETRIEVE_SCB]: 'Retrieving Report',
    //       [BulkOnboardingStage.CONTINUE_ONBOARDING]: 'Continue Onboarding',
    //       [BulkOnboardingStage.BULK_ASSIGNMENT]: 'Continue Assignments',
    //       [BulkOnboardingStage.PAYMENT_CONFIGURATION]: 'Continue Payment Step',
    //     };
    //     const isProcessingStage =
    //       record.onboardingStage === BulkOnboardingStage.ONBOARD_PROCESSING ||
    //       (record.bulkAssignmentProcessing &&
    //         record.onboardingStage === BulkOnboardingStage.COMPLETED);
    //     const displayText =
    //       onboardingStageText[record.onboardingStage] ||
    //       (isProcessingStage ? 'Processing' : 'Onboarding Completed');
    //     return (
    //       <Button
    //         style={{ width: '196px' }}
    //         disabled={
    //           record.isBeingProcessed ||
    //           record.onboardingStage === BulkOnboardingStage.COMPLETED ||
    //           [BulkOnboardingStage.ONBOARD_PROCESSING].includes(
    //             record.onboardingStage as BulkOnboardingStage
    //           )
    //         }
    //         onClick={() => {
    //           // record.niprScbReportResponseDto.csvJson = record.requestBody;
    //           multipleOnboardStore.setCanBeOnboardCount(
    //             record.canBeOnboardedCount
    //           );
    //           multipleOnboardStore.onBoardErrorCSV = record.requestBody.filter(
    //             (request: any) => request?.errorCondition?.length
    //           );
    //           multipleOnboardStore.cannotBeOnboardedCount =
    //             record.cannotBeOnboardedCount;

    //           multipleOnboardStore.bulkOnboardId = record.id;

    //           // multipleOnboardStore.setAgentWithValidNiprResponse(
    //           //   record.requestBody
    //           // );
    //           setOnboardingStage(record.onboardingStage);
    //           setPreviewModalData(record.requestBody);
    //           setReportId(record.reportId);
    //           setOnboardModalVisibility(true);
    //         }}
    //       >
    //         {displayText}
    //       </Button>
    //     );
    //   },
    // },

    {
      dataIndex: 'csvJson',
      render: (date: string, record: Report) => {
        return (
          <span
            className="preview-column"
            style={{
              fontSize: '12px',
              fontFamily: 'Figtree !important',
              fontWeight: 400,
              textDecoration: 'underline',
              color: '#2286FF',
              cursor: 'pointer',
            }}
            onClick={() => {
              setPreviewModalData(record.requestBody);
              setPreviewModalVisibility(true);
            }}
          >
            Preview
          </span>
        );
      },
    },
  ];

  return (
    <div>
      {contextHolder}
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <div>
          <Typography.Paragraph
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: 'var(--secondary-color)',
            }}
          >
            CSV Upload
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              marginTop: '-15px',
            }}
            className="reset-icon-size"
          >
            <TextWithIcon
              iconColor="#97BFBF"
              textStyle={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#97BFBF',
              }}
              text={`CSV upload may take some time to verify and fetch all producer details. Please wait and use the refresh option to obtain the latest updates from NIPR.`}
              iconAlignment="left"
              icon={<InfoCircleOutlined />}
            />
          </Typography.Paragraph>
        </div>
        <Button
          style={{ marginTop: '10px' }}
          className={isLoading ? '' : 'button secondary-button'}
          size="middle"
          disabled={isLoading}
          onClick={() => {
            setLookUpModalVisible(true);
          }}
        >
          Upload CSV
        </Button>
      </div> */}

      <>
        <Table
          loading={isLoading}
          dataSource={data}
          columns={columns}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchScbReports);
            setSortValue(sort);
          }}
          pagination={false}
          rowClassName={(record) =>
            record.onboardingStage === BulkOnboardingStage.COMPLETED ||
            (record.onboardingStage ===
              BulkOnboardingStage.CONTINUE_ONBOARDING &&
              record.canBeOnboardedCount === 0)
              ? 'disable-row'
              : 'enable-row'
          }
          className="bulk-onboarding-layout agency-license-table table-sort-center"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                const targetElement = event.target as HTMLElement;
                const targetColumn = targetElement.closest('td');
                const allColumns = (
                  event.currentTarget as HTMLElement
                ).querySelectorAll('td');
                if (targetColumn !== allColumns[allColumns.length - 1]) {
                  if (
                    record.onboardingStage === BulkOnboardingStage.COMPLETED ||
                    (record.onboardingStage ===
                      BulkOnboardingStage.CONTINUE_ONBOARDING &&
                      record.canBeOnboardedCount === 0)
                  ) {
                    event.stopPropagation();
                  } else {
                    setIsContinueOnboardingModalOpen(true);
                  }
                }
                multipleOnboardStore.setBulkOnboardId(record.id);
                multipleOnboardStore.setCanBeOnboardCount(
                  record.canBeOnboardedCount
                );
                multipleOnboardStore.setCannotBeOnboardedCount(
                  record.cannotBeOnboardedCount
                );
                setRecordId(record.id);
                setOnboardingStage(record.onboardingStage);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 10 }}>
          <Pagination
            total={totalCount}
            pageSize={pageSize}
            current={pageNumber}
            showSizeChanger={false}
            onChange={onPageChange}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </Row>
      </>
      {isLookUpModalVisible && (
        <LookUpModal
          visible={isLookUpModalVisible}
          setVisible={setLookUpModalVisible}
          error={modalError}
          form={form}
          setError={setModalError}
          onFinish={onFinish}
          isLoading={modalLoading}
          setUploadedFile={setUploadedFile}
          setImportAgentsSuccessful={setImportAgentsSuccessful}
          importAgentsSuccessful={importAgentsSuccessful}
        />
      )}
      {isContinueOnboardingModalOpen && (
        <ContinueOnboardingModal
          modalVisibility={isContinueOnboardingModalOpen}
          setModalVisibility={setIsContinueOnboardingModalOpen}
          handleContinueOnboarding={() => handleContinueOnboarding(recordId)}
        />
      )}
      {previewModalVisibility && (
        <PreviewCSVModal
          csvJson={previewModalData}
          modalVisibility={previewModalVisibility}
          setModalVisibility={setPreviewModalVisibility}
          handleContinueOnboarding={() => handleContinueOnboarding(recordId)}
          onboardingStage={onboardingStage}
        />
      )}
      {onboardModalVisibility && (
        <ContinueOnboardModal
          CsvJson={previewModalData}
          modalVisibility={onboardModalVisibility}
          setModalVisibility={setOnboardModalVisibility}
          reportId={reportId}
          handleNext={handleNext}
          onboardingStage={onboardingStage}
        />
      )}
    </div>
  );
}

export default OnboardDraft;
