import { ListBuilderLevel, ListBuilderType } from './listBuilderUtils';

import { Address } from '../../../../types/data/address.type';
import { Name } from '../../../../types/data/name.type';
import { Table } from 'antd';
import { getNameString } from '../../../../utils/name.utils';

export interface ListType {
  type: 'agency' | 'producer';
  _id: string;
  level: number;
  name: string | Name;
  npn: number;
  businessEmail: string;
  businessPhone: string;
  residentStateCode: string;
  residentLicenseNumber: string;
  upline: string;
  residentAddress: Address;
}

export interface SortType {
  field: keyof ListType;
  order: string;
}

interface ListBuilderProps {
  listDetails: ListType[];
  isLoading: boolean;
  setSortType: React.Dispatch<React.SetStateAction<SortType | null>>;
}

const ListBuilder: React.FC<ListBuilderProps> = ({
  listDetails,
  isLoading,
  setSortType,
}) => {
  const Columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: ListType, b: ListType) => a?.type?.localeCompare(b?.type),
      render: (text: string) => {
        return (
          <div>
            <ListBuilderType
              styles={{ textTransform: 'capitalize' }}
              isProducer={text === 'producer'}
            >
              {text}
            </ListBuilderType>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 280,
      sorter: (a: ListType, b: ListType) =>
        getNameString(a?.name)?.localeCompare(getNameString(b?.name)),
      render: (text: string | Name) => {
        return (
          <div>{typeof text === 'string' ? text : getNameString(text)}</div>
        );
      },
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      sorter: (a: ListType, b: ListType) => a?.npn - b?.npn,
    },
    {
      title: 'Email',
      dataIndex: 'businessEmail',
      key: 'businessEmail',
      width: 280,
      sorter: (a: ListType, b: ListType) =>
        a?.businessEmail?.localeCompare(b?.businessEmail),
      render: (text: string) => text || '-',
    },
    {
      title: 'Phone Number',
      dataIndex: 'businessPhone',
      key: 'businessPhone',
      sorter: (a: ListType, b: ListType) =>
        a?.businessPhone?.localeCompare(b?.businessPhone),
      render: (text: string) => text || '-',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a: ListType, b: ListType) => a?.level - b?.level,
      render: (text: string) => {
        return (
          <div>
            <ListBuilderLevel>{`Level ${text}`}</ListBuilderLevel>
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-layout-v2 relative width-100">
      <Table
        columns={Columns}
        dataSource={listDetails}
        scroll={{
          y: 'calc(100vh - 210px)',
        }}
        loading={isLoading}
        pagination={false}
        onChange={(_, __, sort: any, ___) => {
          if (!sort?.order) {
            setSortType(null);
          } else {
            setSortType({ field: sort?.field, order: sort?.order });
          }
        }}
      />
    </div>
  );
};

export default ListBuilder;
