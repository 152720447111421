import { AdminInvitesService } from '../services/adminInvites.service';
import { Invite } from '../types/data/invite.type';
import { RoleType } from '../enums/roles.enum';
import { auth0Store } from './auth0Store';
import { isNumber } from 'lodash';
import { makeAutoObservable } from 'mobx';

class InviteStore {
  constructor() {
    makeAutoObservable(this);
  }
  invites: Invite[] = [];
  invitesLoading = true;
  invitesCount: number = 0;

  setInvitesLoading(prop: boolean) {
    this.invitesLoading = prop;
  }
  setInvites(invites: Invite[]) {
    this.invites = invites;
  }
  setInvitesCount(count: number) {
    this.invitesCount = count;
  }

  async loadInvites(
    token: string,
    page?: number,
    size?: number,
    reqBody?: Object
  ) {
    try {
      this.setInvitesLoading(true);
      const response = await AdminInvitesService.findAllInvitesForUser(
        token,
        page,
        size,
        auth0Store.getCurrentProfile()?.role === RoleType.AGENT,
        reqBody
      ).catch((error) => {
        console.error('Error while fetching user invites');
      });

      if (response) {
        if (isNumber(response?.data?.length)) {
          inviteStore.setInvites(response.data);
        }
        inviteStore.setInvitesCount(response?.totalCount || 0);
      }
      return response;
    } catch (e) {
    } finally {
      this.setInvitesLoading(false);
    }
  }
}

export const inviteStore = new InviteStore();
