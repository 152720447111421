import {
  getStateNameFromCode,
  getTextColor,
  stringToColor,
} from './common.utils';

import { GroupedStateAssignment } from '../components/agentBiographicInfo/agentBiographicInfo';
import { sortBy } from 'lodash';

export const getCustomizedAgentAssignmentDataForMaps = (
  agentDetails: GroupedStateAssignment[],
  licenseDetails?: { [key: string]: string }
) => {
  const agentStateCustomization: any = {};
  sortBy(agentDetails, 'stateCode').forEach(
    (assignedState: GroupedStateAssignment) => {
      if (assignedState?.territories?.length === 0)
        agentStateCustomization[assignedState.stateCode] = {
          backgroundColor:
            licenseDetails && Object.keys(licenseDetails || {})?.length
              ? licenseDetails[assignedState?.stateCode] || '#DDDDDD'
              : '#DDDDDD',
          textColor: '#000',
          tooltip:
            getStateNameFromCode(assignedState.stateCode) +
            ' - Direct assignment',
        };
      else {
        const bgColor =
          licenseDetails && Object.keys(licenseDetails || {})?.length
            ? licenseDetails[assignedState?.stateCode] || '#DDDDDD'
            : stringToColor(assignedState?.territories?.[0].territoryId || '');

        const territoryNames = assignedState.territories
          ? assignedState.territories.map((t) => t.territoryName).join(', ')
          : '';

        agentStateCustomization[assignedState.stateCode] = {
          backgroundColor: bgColor,
          textColor: getTextColor(bgColor),
          tooltip:
            getStateNameFromCode(assignedState.stateCode) +
            ' - Inherited from ' +
            territoryNames,
        };
      }
    }
  );
  return agentStateCustomization;
};
