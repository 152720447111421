import { Row, Spin, Tooltip } from 'antd';

import AssignProducerIcon from '../common/icons/AssignProducerIcon';
import { CSSProperties } from 'react';

type AgentAssignmentStatusProps = {
  isAssigned: boolean;
  showAssignButton: boolean;
  handleClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  styles?: CSSProperties;
  tooltipTitle?: string;
};

function AgentAssignmentStatus(props: AgentAssignmentStatusProps) {
  return (
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        marginLeft: 12,
        ...(props.styles || {}),
      }}
    >
      <div
        className="figtree font-12"
        style={{
          color: '#222222',
          padding: '2px 8px',
          borderRadius: 16,
          lineHeight: '14.4px',
          border: `1.1px solid ${props.isAssigned ? '#31EB91' : '#FFC942'}`,
          background: props.isAssigned
            ? 'rgba(152, 245, 200, 0.5)'
            : 'rgba(252, 239, 207, 1)',
        }}
      >
        {props.isAssigned ? 'Assigned' : 'Not Assigned'}
      </div>
      {props.showAssignButton && (
        <Tooltip title={props?.tooltipTitle || 'Assign to producer'}>
          {props.isLoading ? (
            <Spin size="small" />
          ) : (
            <AssignProducerIcon
              className={props?.isDisabled ? '' : 'cursor-pointer'}
              onClick={() => {
                if (!props.isDisabled) {
                  props.handleClick && props.handleClick();
                }
              }}
            />
          )}
        </Tooltip>
      )}
    </Row>
  );
}

export default AgentAssignmentStatus;
