import React from 'react';

const CopySmall = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66678 4.00016V2.00016C4.66678 1.63198 4.96526 1.3335 5.33344 1.3335H13.3334C13.7016 1.3335 14.0001 1.63198 14.0001 2.00016V11.3335C14.0001 11.7017 13.7016 12.0002 13.3334 12.0002H11.3334V13.9996C11.3334 14.3681 11.0335 14.6668 10.6622 14.6668H2.67135C2.30064 14.6668 2.00024 14.3704 2.00024 13.9996L2.00198 4.66741C2.00204 4.2989 2.302 4.00016 2.67319 4.00016H4.66678ZM3.33519 5.3335L3.3337 13.3335H10.0001V5.3335H3.33519ZM6.00011 4.00016H11.3334V10.6668H12.6668V2.66683H6.00011V4.00016Z"
        fill="#ABABAB"
      />
    </svg>
  );
};

export default CopySmall;
