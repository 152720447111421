import {
  AutoComplete,
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Skeleton,
  Typography,
  notification,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { PayeeEnum, PayeeEnumV2 } from '../../../enums/payee.enum';
import { ReactNode, useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';

import { AUTO_RENEWAL_INFO } from '../../../constants/info.constant';
import { ActionableLicenseResponseError } from '../../../types/response/actionable-license.type';
import { AgentOverview } from '../../../types/data/allAgents.type';
import { AgentService } from '../../../services/agent.service';
import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { ApplicationTypeEnumInBackend } from '../../../enums/applicationType.enum';
import ApplyLicenseErrorModal from '../../../components/actionableLicenseModal/applyLicenseErrorModal';
import ApplyLicenseModal from '../../../utils/modal/licenses/actionable-licenses/apply-license.modal';
import AssociatedAgent from '../../../components/licenses/associatedAgent';
import EngineLights from '../../../components/licenses/engineLights';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { IndividualApplyButton } from './individualApplyButton';
import { InfoCircleOutlined } from '@ant-design/icons';
import LcLoaCell from '../../../components/licenses/lcLoaCell';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import LicenseModal from '../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import LicenseStatusCell from '../../../components/licenses/licenseStatusCell';
import { LicensesService } from '../../../services/licenses.service';
import { ObjectSuccessResponse } from '../../../types/response/objectSuccessResponse.type';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import PaymentConfigModal from '../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { PortalType } from '../../../enums/portalType.enum';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../components/licenses/stateCell';
import { StateConstants } from '../../../constants/state.constants';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { applySort } from '../../../utils/common.utils';
import { getNameString } from '../../../utils/name.utils';
import { getProducerName } from '../../agents/agentsOverview/agencyAgentOverview';
import { isEmpty } from 'lodash';
import { produce } from 'immer';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useParams } from 'react-router';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
export enum Tab {
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}

const tooltipMessage = 'Review and manage expired and missing state licenses.';

export const DYNAMIC_DEFAULT_SELECTED_FILTER = (
  stateCode: string,
  agent: { value: string; label: string }
) => {
  return {
    ...(stateCode
      ? {
          State: {
            labelValuePair: [
              {
                value: stateCode,
                label: StateConstants[stateCode] || '',
              },
            ],
            key: 'stateCode',
          },
        }
      : {}),
    ...(agent?.value
      ? {
          Producer: {
            labelValuePair: [
              {
                value: agent.value,
                label: agent.label,
              },
            ],
            key: 'producerIds',
          },
        }
      : {}),
  };
};

export const DYNAMIC_DEFAULT_FILTER = (stateCode: string, agent: string) => [
  ...(stateCode
    ? [
        {
          operator: 'or',
          filters: [
            {
              key: 'stateCode',
              condition: 'is',
              value: stateCode,
            },
          ],
        },
      ]
    : []),
  ...(agent
    ? [
        {
          operator: 'or',
          filters: [
            {
              key: 'producerIds',
              condition: 'is',
              value: agent,
            },
          ],
        },
      ]
    : []),
];

function ActionableLicenses({
  isAgentOverview,
}: {
  isAgentOverview?: boolean;
}) {
  const { agentId } = useParams();
  const { bearerToken } = useAuth();
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: ReactNode) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const [currentTab, setSelectedTab] = useState<Tab>(Tab.MISSING_LICENSES);
  const [licenses, setLicenses] = useState<LicenseApplicationResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>('');
  const [autoRenewEnabled, setAutoRenewEnabled] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedLicensesIds, setSelectedLicensesId] = useState<any[]>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  const [disableApplyButton, setDisableApplyButton] = useState(false);

  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [currentAgent, setCurrentAgent] = useState<AgentOverview>();
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  //filters
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [controlledFilters, setControlledFilters] = useState({
    missingLicense: [],
    missingLoa: [],
  });
  const [sortValue, setSortValue] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (appStateInfoStore.header === 'Overview') return;
    adminStore.agency?.id &&
      (!isAgentOverview || appStateInfoStore.header) &&
      fetchLicenses();
  }, [adminStore.account, appStateInfoStore.header, queryText, searchType]);

  const getAgentDetails = async () => {
    const response: any = await AgentService.getAgentDetails(
      agentId || '',
      bearerToken || ''
    ).catch((error) => {
      console.error('Error:: ', error.message);
    });
    if (response.data) {
      appStateInfoStore.setCurrentAgentId(response.data.id);
      appStateInfoStore.setHeader(getNameString(response.data.name));
    }
  };

  const showCustomError = (msg: { message: string; description?: string }) => {
    api['error']({
      message: msg.message,
      description: msg.description,
    });
  };

  useEffect(() => {
    if (
      !appStateInfoStore.header &&
      isAgentOverview &&
      agentId &&
      bearerToken
    ) {
      getAgentDetails();
    }
  }, [agentId]);

  useEffect(() => {
    refreshData && adminStore.agency?.id && fetchLicenses();
  }, [refreshData]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      fetchLicenses('', 1);
      setPageNumber(1);
    }
  }, [requestBody]);

  const clearFilters = () => {
    deleteQueryParams();
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    deleteQueryParams();
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const deleteAppliedLicense = async (licenseId: string) => {
    api['success']({
      message: 'License application submitted',
      description: SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS,
    });
    fetchLicenses();
  };

  const updateLicenseById = (
    licenseId: string,
    record: LicenseApplicationResponse
  ) => {
    const newLicenses = produce(licenses, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].license.id === licenseId) {
          console.debug('found license by id :: updating');
          draft[i] = { ...record };
          break;
        }
      }
    });
    setLicenses(newLicenses);
  };

  const setIsLoadingForGivenLicense = (
    licenseId: string,
    isLoading: boolean
  ) => {
    const newLicenses = produce(licenses, (draft) => {
      draft.forEach((itr) => {
        if (itr.id === licenseId) itr.isLoading = isLoading;
      });
    });
    setLicenses(newLicenses);
  };

  const refreshLicenseById = async (licenseId: string) => {
    try {
      setIsLoadingForGivenLicense(licenseId, true);
      const token = await getAccessTokenSilently();

      fetchLicenses();

      const response: ObjectSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.findLatestApplicationForGivenLicense(
          token,
          licenseId
        );
      if (response?.data) {
        updateLicenseById(licenseId, response.data);
      } else setIsLoadingForGivenLicense(licenseId, false);
    } catch (e) {
      setIsLoadingForGivenLicense(licenseId, false);
    }
  };

  const deleteQueryParams = () => {
    searchParams.delete('overview');
    searchParams.delete('state');
    searchParams.delete('producer');
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const fetchLicenses = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;

    try {
      const token = await getAccessTokenSilently();
      setIsLoading(true);
      let type,
        filter = {};
      let payload = requestBody;
      if (searchParams.has('overview')) {
        const agent = searchParams.get('producer') || '';
        const state = searchParams.get('state') || '';
        const agentId = searchParams.get('id') || '';

        if (state || agent || agentId) {
          setSelectedFilters({
            data: DYNAMIC_DEFAULT_SELECTED_FILTER(state, {
              value: agentId,
              label: agent,
            }),
          } as FiltersType);
          if (agentId)
            filter = {
              filterGroups: DYNAMIC_DEFAULT_FILTER(state, agentId),
            };
        }
      }

      if (currentTab === Tab.MISSING_LOAS)
        type = ApplicationTypeEnumInBackend.missingLoas;
      else if (currentTab === Tab.MISSING_LICENSES)
        type = ApplicationTypeEnumInBackend.missing;
      else type = ApplicationTypeEnumInBackend.creation;

      const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.getActionableLicensesForAgency(
          type,
          token,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : searchType || '',
          {
            ...(payload || {}),
            ...filter,
            ...(sort || sortValue || {}),
            ...(isAgentOverview && {
              agentIds: [agentId],
            }),
          },
          agentId
        );
      if (response.data) {
        setTotalCount(response.totalCount);
        setLicenses(response.data);
      } else {
        setTotalCount(0);
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }
    } finally {
      setRefreshData(false);
      if (!errorTypeRequestCancel) setIsLoading(false);
      setLoading(false);
    }
  };

  const fetchFiltersInfoForActionable: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        isLatest: true,
      };
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await LicensesService.getFilterInfoForFindActionable(
            ApplicationTypeEnumInBackend.creation,
            token,
            defaultRequestBody
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH &&
                (!isAgentOverview || data.key !== 'name')
            ),
            PageConstants.NEEDS_ATTENTION
          )
        );

        const result = isAgentOverview
          ? response.data?.filter(
              (data: { key: string }) => data.key !== 'producerIds'
            )
          : response.data;
        setControlledFilters((prev) => ({ ...prev, missingLicense: result }));
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    fetchFiltersInfoForActionable();
  }, [isAgentOverview]);

  const handleRowClick = (record: any) => {
    // Store the selected row data
    setSelectedLicenseDetails(record);
    setAutoRenewEnabled(record.agent?.autoRenewEnabled);
    setApplicationModalVisible(true); // Open the modal
  };

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenses(queryText, newPageNumber, newPageSize);
  };

  const columns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      width: 140,
      render: (text: string, record: any) => (
        <StateCell
          state={StateConstants[text]}
          licenseNumber={record.licenseNumber}
        />
      ),
    },
    ...(isAgentOverview
      ? []
      : [
          {
            title: 'Producer',
            dataIndex: 'producerDetails',
            sorter: true,
            width: 140,
            key: 'licenseProducer.name',
            render: (producer: any, record: any) => {
              return (
                <AssociatedAgent
                  agentId={producer.producerId}
                  agentName={producer.name}
                  isLicenseProcessing={producer.isLicenseProcessing}
                  isAssignmentProcessing={producer.isAssignmentProcessing}
                  isLoading={record.isLoading}
                  id={`${
                    IdConstants.LICENSES.ACTIONABLE_LICENSES.TABLE
                      .ASSOCIATED_AGENT +
                    '-' +
                    record.licenseNumber
                  }`}
                />
              );
            },
          },
        ]),
    {
      title: 'License Status',
      dataIndex: 'status',
      sorter: true,
      key: 'status',
      width: 100,
      render: (_: any, record: any) => (
        <LicenseStatusCell licenseStatus={record.status} />
      ),
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'producerDetails',
      sorter: !isAgentOverview,
      key: 'licenseProducer.producerDetails.paymentConfig.licenseRenewalPayee',
      width: 120,
      render: (producerDetails: any, record: any, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            {record.producerDetails.paymentConfig?.licenseRenewalPayee ? (
              getProducerName(
                record.producerDetails.paymentConfig?.licenseRenewalPayee
              )
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}
          </Row>
        );
      },
      // shouldCellUpdate: () => true,
      // onCell: (record, index) => {
      //   return {
      //     onClick: () => {
      //       if (!record.isLoading && selectedLicenses.length === 0) {
      //         setCurrentAgent({
      //           ...record.agent,
      //           id: record.agent.id || record.agent._id,
      //         });
      //         setPaymentConfigModalVisibility(true);
      //       }
      //     },
      //   };
      // },
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      sorter: true,
      width: 300,
      render: (loas: any, record: any) => {
        return (
          <Row align="middle">
            <LcLoaCell
              licenseClass={record.licenseConfigurations?.licenseClass}
              loaDetails={loas?.loaDetails}
              currentTab={currentTab}
              disableCell={
                record.producerDetails?.processingFlags
                  ?.isAssignmentProcessing ||
                record.producerDetails?.processingFlags?.isLicenseProcessing
              }
              // requiredStatus={LoaStatus.ACTIVE}
            />
          </Row>
        );
      },
    },
    {
      title: 'Application Status',
      dataIndex: 'latestApplicationStatus',
      sorter: true,
      key: 'latestApplicationStatus',
      align: 'center',
      width: 120,
      render: (text: string, record: any) => {
        return record.isLoading ? (
          <Skeleton.Button active size="small" />
        ) : (
          <Row
            align="middle"
            style={{ textAlign: 'center' }}
            onClick={() => {
              if (text) {
                handleRowClick(record);
              }
            }}
          >
            <ApplicationStatusCell
              applicationStatus={
                text
                  ? text.charAt(0).toUpperCase() + text.slice(1)
                  : ApplicationStatusEnum.Not_Applied
              }
            />
          </Row>
        );
      },
    },
    {
      width: 180,
      render: (record: any) => {
        return (
          <Col
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EngineLights
              payer={
                record.status === 'missing'
                  ? record.producerDetails?.paymentConfig?.licenseInitialPayee
                  : record.producerDetails?.paymentConfig?.licenseRenewalPayee
              }
              setPayerCallBack={() => {
                if (selectedLicenses.length === 0) {
                  setCurrentAgent({
                    ...record.producerDetails,
                    id:
                      record.producerDetails?.producerId ||
                      record.producerDetails.producerId,
                  });
                  // initial payer missing
                  if (
                    record.applicationType ===
                    ApplicationTypeEnumInBackend.creation
                  ) {
                    if (
                      record.producerDetails?.paymentConfig
                        ?.licenseInitialPayee !== PayeeEnum.AGENCY
                    )
                      setPaymentConfigModalVisibility(true);
                    else if (
                      // payment type is missing
                      !(
                        record.producerDetails?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.CAP ||
                        record.producerDetails?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.FULL
                      )
                    ) {
                      setPaymentConfigModalVisibility(true);
                    }
                  } else {
                    // renewal payer missing
                    if (
                      record.producerDetails?.paymentConfig
                        ?.renewalPaymentPayee !== PayeeEnum.AGENCY
                    )
                      setPaymentConfigModalVisibility(true);
                    else if (
                      // payment type is missing
                      !(
                        record.producerDetails?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.CAP ||
                        record.producerDetails?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.FULL
                      )
                    ) {
                      setPaymentConfigModalVisibility(true);
                    }
                  }
                }
              }}
              record={record}
            />
            <IndividualApplyButton
              payer={
                record.applicationType === ApplicationTypeEnumInBackend.creation
                  ? record.producerDetails?.paymentConfig?.licenseInitialPayee
                  : record.producerDetails?.paymentConfig?.licenseRenewalPayee
              }
              selectedLicensesLength={selectedLicenses.length}
              record={record}
              currentTab={currentTab}
              setRefreshData={setRefreshData}
              deleteAppliedLicense={deleteAppliedLicense}
              setDisableApplyButton={setDisableApplyButton}
              disableApplyButton={disableApplyButton || !!record.isLoading}
              showError={showCustomError}
              setApplyLicenseErrorModalVisibility={
                setApplyLicenseErrorModalVisibility
              }
              setErrors={setErrors}
              applyErrorCallback={refreshLicenseById}
              isRenewalPage={
                record.status === LicenseStatus.Active &&
                record.applicationType !==
                  ApplicationTypeEnumInBackend.missingLoas
              }
            />
          </Col>
        );
      },
    },
  ];

  const getRenewalIndex = () =>
    columns.findIndex((obj) => obj.title === 'Renewal Payment Account');

  columns.splice(getRenewalIndex(), 0, {
    title: 'Initial Payment Account',
    dataIndex: 'paymentConfig',
    key: 'producerDetails.paymentConfig.licenseInitialPayee',
    sorter: !isAgentOverview,
    width: 120,
    render: (_: any, record: any, index: number) => {
      return (
        <Row
          id={
            IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
            record.id +
            '-other-line-only-agents'
          }
          style={{
            justifyContent: 'space-between',
          }}
        >
          {record.producerDetails.paymentConfig?.licenseInitialPayee ? (
            getProducerName(
              record.producerDetails.paymentConfig?.licenseInitialPayee
            )
          ) : (
            <span style={{ color: '#92A69E', fontSize: '12px' }}>
              Payee not selected
            </span>
          )}
        </Row>
      );
    },
    // shouldCellUpdate: () => true,
    // onCell: (record, index) => {
    //   return {
    //     onClick: () => {
    //       if (!record.isLoading && selectedLicenses.length === 0) {
    //         setCurrentAgent({
    //           ...record.agent,
    //           id: record.agent.id || record.agent._id,
    //         });
    //         setPaymentConfigModalVisibility(true);
    //       }
    //     },
    //   };
    // },
  });

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_NEEDS_ATTENTION;

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <div style={{ width: '100%', height: 'fit-content' }}>
      {contextHolder}
      <div
        className="reset-icon-size"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Text
          type="secondary"
          style={{ marginBottom: 4, fontSize: 14 }}
        >
          <InfoCircleOutlined /> {tooltipMessage} {AUTO_RENEWAL_INFO}
        </Typography.Text>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
            columnGap: 10,
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            disabled={loading}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-actionable-licenses'}
              placeholder={
                isAgentOverview
                  ? 'Search Licenses by State, LOAs, License Class or License Number'
                  : 'Search Licenses by State, Producer, LOAs, License Class or License Number'
              }
              size="middle"
              style={{
                width: '100%',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>

          {selectedLicenses.length > 1 && (
            <Button
              id={IdConstants.LICENSES.ACTIONABLE_LICENSES.APPLY_ALL_LICENSES}
              className="button"
              onClick={() => {
                setIsUpdateModalVisible(!isUpdateModalVisible);
              }}
              disabled={
                true
                  ? selectedLicenses.some(
                      (itr) =>
                        itr?.agent?.paymentConfig?.renewalPaymentPayee !==
                        PayeeEnum.AGENCY
                    )
                  : selectedLicenses.some(
                      (itr) =>
                        itr?.agent?.paymentConfig?.initialPaymentPayee !==
                        PayeeEnum.AGENCY
                    )
              }
            >
              Apply All Licenses
            </Button>
          )}
          <FilterButton
            {...{
              filterInfoLoading,
              activeData: licenses,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>
      </div>
      <RenderFilterChip
        {...{ selectedFilters, removeFilter, clearFilters, pageName }}
      />
      <div
        style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
        className="table-layout-v2 relative"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Table
              rowKey={(record, index) => record.id + index}
              columns={columns}
              dataSource={licenses}
              onChange={(pagination, filters, sorter) => {
                const sort = applySort(sorter, fetchLicenses);
                setSortValue(sort);
              }}
              pagination={false}
              loading={isLoading}
              scroll={{
                y: 'calc(100vh - 290px)',
              }}
            />
            {!isEmpty(licenses) && (
              <Row justify="end">
                <Pagination
                  total={totalCount}
                  pageSize={pageSize}
                  current={pageNumber}
                  showSizeChanger={false}
                  onChange={onPageChange}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  disabled={isLoading}
                />
              </Row>
            )}
          </>
        )}
      </div>

      <ApplyLicenseModal
        visible={isUpdateModalVisible}
        setVisible={setIsUpdateModalVisible}
        licenseDetails={selectedLicenses}
        licenseIds={selectedLicensesIds}
        selectedFilter={currentTab}
        successCallback={() => {
          setSelectedLicenses([]);
          setSelectedLicensesId([]);
          fetchLicenses();
        }}
      />
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENCY}
        showTransactionDetails={false}
        autoRenewEnabled={autoRenewEnabled}
      />
      <PaymentConfigModal
        agents={currentAgent ? [currentAgent] : undefined}
        visible={paymentConfigModalVisibility}
        handleVisibility={() => {
          setPaymentConfigModalVisibility(false);
        }}
        successCallback={() => {
          if (adminStore.agency?.id) {
            fetchLicenses(undefined, 1);
            setPageNumber(1);
          }
        }}
      />
      <ApplyLicenseErrorModal
        applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
        setApplyLicenseErrorModalVisibility={
          setApplyLicenseErrorModalVisibility
        }
        errors={errors}
        fetchLicense={fetchLicenses}
      />

      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        controlledFilters={
          currentTab === Tab.MISSING_LOAS
            ? controlledFilters.missingLoa
            : controlledFilters.missingLicense
        }
        deleteParams={deleteQueryParams}
        pageName={pageName}
        filterPage={PageConstants.NEEDS_ATTENTION}
      />
    </div>
  );
}

export default withRoles(ActionableLicenses, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
