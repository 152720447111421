import './input.css';

import { Input, Typography } from 'antd';

import React from 'react';

type InputProps = {
  id?: string;
  label?: string | null;
  placeholder?: string;
  isDisabled?: boolean;
  value?: string;
  styles?: React.CSSProperties;
  bodyStyles?: React.CSSProperties;
  compulsory?: boolean;
  displayInfo?: boolean;
  setInputValue?: (value: string | '') => void;
  onKeyPress?: any;
  onPaste?: any;
  onChange?: any;
  maxLength?: number;
};

/**
 * InputField Component
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} props.isDisabled - Determines whether the input field is disabled or not.
 * @param {object} props.styles - Custom styles to apply to the input field.
 * @param {boolean} props.compulsory - Determines whether the input field is compulsory or not.
 * @param {string} props.value - The current value of the input field.
 * @param {function} props.setInputValue - Callback function to set the input value.
 * @returns {JSX.Element} The InputField component.
 */

function InputField({
  label,
  placeholder,
  isDisabled,
  styles,
  compulsory,
  value,
  displayInfo,
  setInputValue,
  bodyStyles,
  onKeyPress,
  onPaste,
  onChange,
  id,
  maxLength,
}: InputProps) {
  return (
    <div style={bodyStyles}>
      <Typography.Paragraph
        style={{ fontWeight: 500 }}
        className={compulsory ? 'input-labelTwo' : 'input-labelOne'}
      >
        {label}
      </Typography.Paragraph>
      <Input
        id={id || 'input-field'}
        className={displayInfo ? 'input-field input-display' : 'input-field'}
        style={styles}
        placeholder={placeholder}
        disabled={isDisabled}
        defaultValue={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        maxLength={maxLength}
      />
    </div>
  );
}

export default InputField;
