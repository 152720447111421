import React from 'react';

const DeleteIconCustom = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3335 2.9987H13.6668V4.33203H12.3335V12.9987C12.3335 13.3669 12.035 13.6654 11.6668 13.6654H2.3335C1.96531 13.6654 1.66683 13.3669 1.66683 12.9987V4.33203H0.333496V2.9987H3.66683V0.998698C3.66683 0.630511 3.96531 0.332031 4.3335 0.332031H9.66683C10.035 0.332031 10.3335 0.630511 10.3335 0.998698V2.9987ZM11.0002 4.33203H3.00016V12.332H11.0002V4.33203ZM5.00016 6.33203H6.3335V10.332H5.00016V6.33203ZM7.66683 6.33203H9.00016V10.332H7.66683V6.33203ZM5.00016 1.66536V2.9987H9.00016V1.66536H5.00016Z"
        fill="white"
      />
    </svg>
  );
};

export default DeleteIconCustom;
