import { Checkbox, Tooltip } from 'antd';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LoaProps } from './individualOnboardV2Type.type';
import React from 'react';

const AssignmentLoa: React.FC<LoaProps> = ({
  text,
  loaId,
  licenseClassCode,
  isCheckboxNeeded,
  selectedIds,
  handleCheckboxChange,
  disableCheckBox = false,
  loaDbId,
  activeLoa,
  tooltipText,
}) => {
  const handleLoaCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(
      licenseClassCode,
      text,
      { loaId, id: loaDbId },
      e.target.checked
    );
    e.stopPropagation();
  };
  return (
    <Tooltip title={tooltipText}>
      <div
        className="override-checkbox-bg"
        style={{
          height: isCheckboxNeeded ? '40px' : '24px',
          borderRadius: '4px',
          border: '1px solid #E6E6E6',
          padding: '8px 12px',
          gap: '12px',
          backgroundColor: activeLoa ? '#CBFAE3' : '#FFFFFF',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          borderColor: activeLoa ? '#0F9D58' : '#E6E6E6',
        }}
      >
        {isCheckboxNeeded && (
          <Checkbox
            style={{ width: '24px', height: '24px' }}
            checked={selectedIds.includes(loaId)}
            onChange={handleLoaCheckboxChange}
            disabled={disableCheckBox}
          />
        )}
        <p
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            fontSize: isCheckboxNeeded ? '14px' : '12px',
            lineHeight: '20px',
            color: '#222222',
            margin: 0,
            textTransform: 'capitalize',
          }}
        >
          {text}
        </p>
      </div>
    </Tooltip>
  );
};

export default AssignmentLoa;
