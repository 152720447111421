import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Radio,
  Typography,
  message,
} from 'antd';
import {
  FEIN_REGEX,
  NPN_REGEX,
  SSN_REGEX,
} from '../../../constants/regex.constants';
import { useEffect, useState } from 'react';

import AddressInput from '../../../components/common/addressInput';
import { Card } from 'antd';
import { adminStore } from '../../../stores/admin.store';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { userDetails } from './OwnershipDetails';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
export const OWNERSHIP_TYPE_FIELDS = {
  AGENCY: 'F',
  PRODUCER: 'I',
};

export const OWNERSHIP_TYPE_TITLE = {
  BUSINESS: 'Business',
  INDIVIDUAL: 'Individual',
};

const OWNERSHIP_TYPE = [
  OWNERSHIP_TYPE_FIELDS.AGENCY,
  OWNERSHIP_TYPE_FIELDS.PRODUCER,
];

interface OwnershipDetailsCardType {
  initialValues?: Record<string, string>;
  selected?: string;
  onSave: (
    isEdit: boolean,
    ownerDetails: userDetails,
    clear: () => void
  ) => void;
  loading: boolean;
  refreshInitialValues?: (type: string) => void;
}

const disabledDate: DatePickerProps['disabledDate'] = (current) => {
  return !current.isBefore(moment().subtract(18, 'years').toDate());
};

const OwnershipDetailsCard: React.FC<OwnershipDetailsCardType> = ({
  initialValues = {},
  selected = OWNERSHIP_TYPE_FIELDS.AGENCY,
  onSave,
  loading,
  refreshInitialValues,
}) => {
  const [agencyForm] = useForm();
  const [producerForm] = useForm();
  const [selectedType, setSelectedType] = useState(selected);
  const [ssn, setSsn] = useState<string>('');

  const onFinish = (values: userDetails) => {
    if (values.ownershipPercent > 100) {
      message.error('Ownership Percentage should not exceed 100');
      return;
    }
    if (values.ownershipPercent <= 0) {
      message.error('Ownership Percentage should be greater than 0.');
      return;
    }

    onSave(
      !!Object.keys(initialValues).length,
      {
        ...values,
        type: selectedType,
        agencyId: adminStore.agency?.id || '',
        stateCode: values?.state,
        zipCode: values?.zipcode,
      },
      () => {
        agencyForm.resetFields();
        producerForm.resetFields();
      }
    );
  };

  useEffect(() => {
    if (initialValues?.isReset) {
      if (initialValues?.isClearType === 'agency') {
        agencyForm.resetFields();
      } else {
        producerForm.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (initialValues.ssn) {
      setSsn(initialValues.ssn);
    }
  }, [initialValues?.ssn]);

  return (
    <div className="width-100">
      <Card className="width-100">
        <div>
          <Typography.Paragraph
            style={{
              marginBottom: 6,
              fontSize: 14,
              fontWeight: '500',
              color: 'rgba(0, 0, 0, 0.88)',
            }}
          >
            Select Type
          </Typography.Paragraph>
          <div style={{ display: 'flex', gap: 12 }}>
            {OWNERSHIP_TYPE.map((data) => {
              return (
                <div
                  className="cursor-pointer"
                  id={`agency-all-licenses-type--${data}`}
                  style={{
                    padding: '8px 12px',
                    border: `2px solid ${
                      data === selectedType ? 'var(--primary-color)' : '#F7FAFC'
                    }`,
                    borderRadius: 6,
                    margin: '3px 0 24px',
                    display: 'flex',
                    width: 262,
                    justifyContent: 'space-between',
                    pointerEvents: loading ? 'none' : 'inherit',
                  }}
                  onClick={() => {
                    if (data !== selectedType) {
                      producerForm.resetFields();
                      agencyForm.resetFields();

                      if (data === OWNERSHIP_TYPE_FIELDS.PRODUCER) {
                        if (refreshInitialValues)
                          refreshInitialValues('agency');
                      }
                      if (data === OWNERSHIP_TYPE_FIELDS.AGENCY) {
                        if (refreshInitialValues)
                          refreshInitialValues('producer');
                      }
                    }
                    setSelectedType(data);
                  }}
                >
                  {data === OWNERSHIP_TYPE_FIELDS.AGENCY
                    ? OWNERSHIP_TYPE_TITLE.BUSINESS
                    : OWNERSHIP_TYPE_TITLE.INDIVIDUAL}
                  <span style={{ display: 'block' }}>
                    <Radio checked={data === selectedType} />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {selectedType === OWNERSHIP_TYPE_FIELDS.AGENCY ? (
          <Form
            {...formItemLayout}
            key={'agencyForm-details'}
            initialValues={{
              title: 'Owner',
              ...initialValues,
            }}
            form={agencyForm}
            disabled={loading}
            layout="vertical"
            onFinish={onFinish}
          >
            <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="Agency Name"
                name="firmOrLastName"
                id="agency--agency-name"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please enter agency name!' },
                ]}
              >
                <Input
                  placeholder="Enter agency name"
                  style={{ display: 'block' }}
                />
              </Form.Item>

              <Form.Item
                label="FEIN"
                name="fein"
                id="agency--fein"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please enter FEIN number' },
                  { pattern: FEIN_REGEX, message: 'Please valid FEIN number' },
                ]}
              >
                <InputNumber
                  maxLength={9}
                  placeholder="Enter FEIN"
                  style={{ width: '100%' }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/[0-9]/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <div style={{ flex: 1 }} />
            </div>

            <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="NPN"
                name="npn"
                id="agency--npn"
                style={{ flex: 1 }}
                rules={[
                  { required: true },
                  { pattern: NPN_REGEX, message: 'Please enter npn number!' },
                ]}
              >
                <InputNumber
                  placeholder="Enter NPN"
                  style={{ width: '100%' }}
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Ownership percentage"
                name="ownershipPercent"
                id="agency--ownership-percentage"
                style={{ flex: 1 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter ownership percentage!',
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter percentage"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                style={{ pointerEvents: 'none', opacity: 0.7, flex: 1 }}
                label="Title"
                name="title"
                id="agency--title"
              >
                <Input />
              </Form.Item>
            </div>
            <AddressInput
              addressDetails={{
                addressTypeCode: '',
                addressType: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                zip: '',
                country: 'U.S.A',
              }}
              addressType={null}
              style={{ width: '100%', display: 'block' }}
              form={agencyForm}
              hideCountry={true}
              hideOptional3Address={true}
            />

            {/* <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                style={{ flex: 1 }}
                id="agency--date-of-birth"
                rules={[
                  { required: true, message: 'Please enter date of birth!' },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1 }} />
            </div> */}

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ float: 'right' }}
              id="agency-all-licenses-save-cta"
            >
              Save
            </Button>
          </Form>
        ) : (
          <Form
            {...formItemLayout}
            key={'producerForm-details'}
            initialValues={{
              title: 'Owner',
              ...initialValues,
            }}
            form={producerForm}
            onFinish={onFinish}
            layout="vertical"
          >
            <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="First Name"
                name="firstName"
                id="producer--firstName"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please enter first name!' },
                ]}
              >
                <Input
                  placeholder="Enter first name"
                  style={{ display: 'block' }}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                label="Middle Name"
                name="middleName"
                id="producer--middleName"
              >
                <Input
                  placeholder="Enter middle name"
                  style={{ display: 'block' }}
                />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                label="Last Name"
                name="firmOrLastName"
                id="producer--lastName"
                rules={[{ required: true, message: 'Please enter last name!' }]}
              >
                <Input
                  placeholder="Enter last name"
                  style={{ display: 'block' }}
                />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="NPN"
                id="producer--npn"
                name="npn"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please enter npn number!' },
                  { pattern: NPN_REGEX, message: 'Please valid npn number!' },
                ]}
              >
                <InputNumber
                  placeholder="Enter NPN"
                  style={{ width: '100%' }}
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="SSN"
                name="ssn"
                id="producer--ssn"
                style={{ flex: 1 }}
                rules={
                  !ssn.startsWith('**')
                    ? [
                        {
                          required: true,
                          message: 'Please enter Social Security Number',
                        },
                        {
                          pattern: SSN_REGEX,
                          message:
                            'Please enter a valid Social Security Number',
                        },
                      ]
                    : []
                }
              >
                <InputNumber
                  placeholder="Enter SSN"
                  style={{ width: '100%' }}
                  maxLength={9}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    const pastedText =
                      event.clipboardData.getData('text/plain');
                    if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e?.toString() || '';
                    if (value?.length <= 9) setSsn(value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && ssn?.startsWith('**')) {
                      producerForm.setFieldsValue({ ssn: '' });
                      setSsn('');
                      event.preventDefault();
                    }
                  }}
                  minLength={6}
                />
              </Form.Item>

              <Form.Item
                label="Ownership percentage"
                name="ownershipPercent"
                id="producer--ownership-percentage"
                style={{ flex: 1 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter ownership percentage!',
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter percentage"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: 24 }}>
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                id="producer--date-of-birth"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please enter date of birth!' },
                ]}
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>

              <Form.Item
                style={{ pointerEvents: 'none', opacity: 0.7, flex: 1 }}
                label="Title"
                name="title"
                id="producer--title"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <div
                style={{
                  flex: 1,
                }}
              />
            </div>

            <AddressInput
              addressDetails={{
                addressTypeCode: '',
                addressType: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                zip: '',
                country: 'U.S.A',
              }}
              addressType={null}
              style={{ width: '100%', display: 'block' }}
              form={producerForm}
              hideCountry={true}
              hideOptional3Address={true}
            />

            <Button
              type="primary"
              htmlType="submit"
              style={{ float: 'right' }}
              loading={loading}
              id="producer--save"
            >
              Save
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default OwnershipDetailsCard;
