export enum LicenseStatus {
  Active = 'active',
  MISSING_LOA = 'missing_loa',
  ACTIVE_RENEWAL_PENDING = 'activeRenewalPending',
  ACTIVE_RENEWAL_DECLINED = 'activeRenewalDeclined',
  ACTIVE_LOA_DECLINED = 'activeLOADeclined',
  Inactive = 'inactive',
  INACTIVE_DECLINED = 'inactiveDeclined',
  Missing = 'missing',
  Expired = 'expired',
  Pending = 'pending',
  EXPIRED_DECLINED = 'expiredDeclined',
  EXPIRED_RENEWAL_DECLINED = 'expiredRenewalDeclined',
  EXPIRED_RENEWAL_PENDING = 'expiredRenewalPending',
}
