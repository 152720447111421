import { Avatar, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

interface AgencyLogoProps {
  agencyNameInitial: string;
  style?: React.CSSProperties;
  imageUrl?: string | null;
}

const AgencyLogo = ({
  agencyNameInitial,
  style,
  imageUrl,
}: AgencyLogoProps) => {
  const [isLoading, setIsLoading] = useState(!!imageUrl);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setIsLoading(false);
        setImageLoaded(true);
      };
      img.onerror = () => {
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [imageUrl]);

  return (
    <Avatar
      style={{
        ...style,
        height: style?.height || '78px',
        width: style?.width || '78px',
        borderRadius: style?.borderRadius || '50%',
        backgroundColor: imageLoaded ? 'transparent' : '#B5E6FF',
        color: '#0074B0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: style?.fontSize || '33.5px',
      }}
      src={imageLoaded ? imageUrl : undefined}
      alt="Agency Logo"
    >
      {isLoading ? <Spin size="small" /> : !imageUrl && agencyNameInitial}
    </Avatar>
  );
};

export default AgencyLogo;
