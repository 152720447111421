import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Progress,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import ImportAgents from '../../../../pages/agents/onboardAgents/multipleOnboard/importAgents';
import { ModalContainer } from './upload-csv.modal.style';
import { MultipleOnboardLookup } from '../../../../enums/onboardSteps.enum';
import { PlusOutlined } from '@ant-design/icons';
import { TextDetail } from '../../application-status/applicationstatus.style';
import googleSheetsLogo from '../../../../assets/icons/googleSheetsLogo.svg';
import { multipleOnboardStore } from '../../../../stores/multipleOnboardStore';

interface LookUpProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onFinish: (values: any) => void;
  form: any;
  isLoading: boolean;
  error: string;
  setError: React.Dispatch<React.SetStateAction<any>>;
  setUploadedFile: React.Dispatch<React.SetStateAction<any>>;
  importAgentsSuccessful: boolean;
  setImportAgentsSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
}

const LookUpModal: React.FC<LookUpProps> = ({
  visible,
  setVisible,
  onFinish,
  form,
  isLoading,
  error,
  setError,
  setUploadedFile,
}) => {
  const { Option } = Select;
  const [fileName, setFileName] = useState<string>('Onboard_SSN_NPN.csv');
  const [importAgentsSuccessful, setImportAgentsSuccessful] =
    useState<boolean>(false);
  const [fileLink, setFileLink] = useState<string>(
    `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Onboard_SSN_NPN.csv`
  );

  const onClose = () => {
    form.resetFields();
    multipleOnboardStore.setIsFileUploaded(false);
    multipleOnboardStore.resetMultipleOnboard();
    setImportAgentsSuccessful(false);
    setVisible(false);
    multipleOnboardStore.setLookupMethod(MultipleOnboardLookup.SSN);
  };

  useEffect(() => {
    setImportAgentsSuccessful(false);
  }, []);

  const onChange = (value: MultipleOnboardLookup) => {
    multipleOnboardStore.setLookupMethod(value);
    multipleOnboardStore.setIsFileUploaded(false);
    setImportAgentsSuccessful(false);

    if (value === MultipleOnboardLookup.SSN) {
      setFileName('SSN-LastName.csv');
      setFileLink(
        `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/SSN_LastName.csv`
      );
    } else if (value === MultipleOnboardLookup.NPN) {
      setFileName('Name-NPN.csv');
      setFileLink(
        `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Name_NPN.csv`
      );
    } else {
      setFileName('License-State.csv');
      setFileLink(
        `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/License_State.csv`
      );
    }
  };

  return (
    <Modal
      afterClose={onClose}
      destroyOnClose={true}
      width={'800px'}
      open={visible}
      onCancel={() => {
        setVisible(false);
        onClose();
      }}
      okText="Ok"
      footer={null}
    >
      <ModalContainer style={{ width: '100%' }}>
        <TextDetail textColor={'#0588ca'} fontWeight={500} fontSize={'23px'}>
          Import Producers
        </TextDetail>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: '100%' }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <div style={{ marginBottom: 20, display: 'flex', gap: 10 }}>
            {` LookUp Type: `}
            <TextDetail
              fontWeight={500}
              fontSize={'16px'}
            >{`${MultipleOnboardLookup.SSN}`}</TextDetail>
          </div>
          {/* <Form.Item
            label="Select LookUp Type"
            name="selectedOption"
            style={{ width: '90%' }}
            rules={[{ required: true, message: 'Please select an option!' }]}
          >
            {/* <Select
              placeholder="Select an option"
              style={{ width: '70%' }}
              onChange={onChange}
              defaultValue={MultipleOnboardLookup.SSN}
            >
              <Option value={MultipleOnboardLookup.SSN}>
                Individual SSN and Last Name
              </Option>
              <Option value={MultipleOnboardLookup.NPN}>
                Name and NPN Number
              </Option>
            </Select> */}
          {/* </Form.Item> */}

          <Card
            style={{
              backgroundColor: '#FCFCFC',
              width: '90%',
              padding: '0',
              borderRadius: '0 0 8px 8px',
              marginBottom: '15px',
            }}
          >
            {multipleOnboardStore.isFileUploaded ? (
              multipleOnboardStore.uploadStatus === 'uploading' ||
              multipleOnboardStore.uploadStatus === 'done' ? (
                <Card
                  className="import-agents-inner-card"
                  style={{
                    marginTop: '16px',
                    // marginBottom: '40px',
                    backgroundColor: '#F5F5F7',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Row align="middle">
                        <img src={googleSheetsLogo} alt="logo" />
                        <Typography.Paragraph
                          style={{
                            fontSize: '14px',
                            marginLeft: '10px',
                            marginTop: '15px',
                          }}
                        >
                          {multipleOnboardStore.uploadedFileName ||
                            'Table Example.csv'}
                        </Typography.Paragraph>
                      </Row>
                    </Col>
                    <Col>
                      <Progress
                        type="circle"
                        percent={multipleOnboardStore.uploadProgress}
                        size={75}
                        status={
                          importAgentsSuccessful === undefined
                            ? 'active'
                            : importAgentsSuccessful
                              ? 'success'
                              : 'exception'
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              ) : null
            ) : (
              <Card style={{ backgroundColor: '#F5F5F7' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Row align="middle">
                      <img src={googleSheetsLogo} alt="logo" />
                      <Typography.Paragraph
                        style={{
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '15px',
                        }}
                      >
                        {fileName}
                      </Typography.Paragraph>
                    </Row>
                    <Typography.Paragraph
                      style={{ fontSize: '12px', color: '#ABABAB' }}
                    >
                      Download this attached example file and use it as a
                      reference <br /> to avoid error while matching column
                      attributes
                    </Typography.Paragraph>
                  </Col>
                  <Col>
                    <Row justify="end">
                      <Button href={fileLink} size="large" download>
                        Download
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )}
          </Card>

          <div>
            <ImportAgents
              fileLink={fileLink}
              onComplete={() => setImportAgentsSuccessful(true)}
              onUploadFailure={() => setImportAgentsSuccessful(false)}
              setUploadedFile={setUploadedFile}
            />
          </div>

          <Form.Item style={{ paddingTop: '5px' }}>
            <Space>
              <Button onClick={onClose} disabled={isLoading}>
                {' '}
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onFinish}
                loading={isLoading}
                disabled={!importAgentsSuccessful}
              >
                <PlusOutlined />
                Upload CSV
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default LookUpModal;
