export enum FilterCondition {
  IS = 'is',
  IS_NOT = 'isNot',
  CONTAINS = 'contains',
  DOES_NOT_CONTAINS = 'doesNotContain',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
}

export enum FilterOperator {
  AND = 'and',
  OR = 'or',
}

export enum FilterFieldType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}
