import {
  FilterTitles,
  LEVEL_FILTER,
  LICENSE_FILTER,
  SelectedChip,
} from '../organizationView/organizationUtils';

import React from 'react';
import { getStateNameFromCode } from '../../../../utils/common.utils';
import { listBuilderTitleStyle } from './listBuilderUtils';

export interface filterType {
  condition: null | string;
  type: null | string[];
  match: null | string;
  value: null | string[];
}

const AppliedFilter: React.FC<{
  appliedFilters: filterType[];
  onClick?: (field: string, value: string, index: number) => void;
  hideClose?: boolean;
}> = ({ appliedFilters, onClick, hideClose }) => {
  return (
    <div style={{ display: 'flex', gap: 12, marginTop: 12 }}>
      <div style={{ ...listBuilderTitleStyle, width: 80, color: '#001F45' }}>
        Filter Criteria:
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          justifyContent: 'center',
          padding: '16px 12px',
          background: '#F5F6F7',
          borderRadius: 6,
          flex: 1,
        }}
      >
        {appliedFilters?.map((d, i) => {
          return (
            <div
              style={{
                display: 'flex',
                gap: 24,
                ...(i + 1 < appliedFilters?.length
                  ? { borderBottom: '1px dashed #D3D3D3', paddingBottom: 12 }
                  : {}),
                alignItems: 'flex-end',
              }}
              key={i}
            >
              <div
                style={{
                  flexBasis: '40px',
                  flexGrow: 0,
                  ...listBuilderTitleStyle,
                  color: '#5D5D5D',
                  textTransform: 'capitalize',
                }}
              >
                {i === 0 ? 'Where' : d?.condition}
              </div>
              <div
                style={{
                  flexBasis: '200px',
                  flexGrow: 0,
                  borderRight: '1px solid #D3D3D3',
                }}
              >
                {i ? null : (
                  <div
                    style={{
                      width: 32,
                      ...listBuilderTitleStyle,
                      color: '#5D5D5D',
                      marginBottom: 8,
                    }}
                  >
                    {' '}
                    Type:
                  </div>
                )}
                <span
                  style={{ gap: 8, alignItems: 'center', width: 200 }}
                  className="font-12 flex figtree var(--color-primary) weight-400 line-height-16 no-wrap"
                >
                  {d.type?.map((d) => (
                    <SelectedChip
                      value={d}
                      hideClose={hideClose}
                      onClick={(value) => {
                        if (onClick) {
                          onClick(FilterTitles.TYPE, value, i);
                        }
                      }}
                    />
                  ))}
                </span>
              </div>
              <div
                style={{
                  flexBasis: '320px',
                  flexGrow: 0,
                  borderRight: '1px solid #D3D3D3',
                }}
              >
                {i ? null : (
                  <div
                    style={{
                      width: 32,
                      ...listBuilderTitleStyle,
                      color: '#5D5D5D',
                      marginBottom: 8,
                    }}
                  >
                    {' '}
                    Condition:
                  </div>
                )}
                <span className="font-12 figtree var(--color-primary) weight-400 line-height-16 no-wrap">
                  {d?.match ? (
                    <SelectedChip value={d.match} hideClose={hideClose} />
                  ) : null}
                </span>
              </div>
              <div
                style={{
                  gap: 8,
                  alignItems: 'flex-start',
                  flexGrow: 1,
                }}
              >
                {' '}
                {i ? null : (
                  <div
                    style={{
                      width: 32,
                      ...listBuilderTitleStyle,
                      color: '#5D5D5D',
                      marginBottom: 8,
                    }}
                  >
                    {' '}
                    Value:
                  </div>
                )}
                <div
                  className="font-12 flex figtree var(--color-primary) weight-400 line-height-16"
                  style={{ gap: 8, flexWrap: 'wrap' }}
                >
                  {' '}
                  {d.value?.map((data) => (
                    <SelectedChip
                      hideClose={hideClose}
                      onClick={(value) => {
                        if (onClick) {
                          onClick(FilterTitles.VALUE, data, i);
                        }
                      }}
                      value={
                        LEVEL_FILTER.includes(d?.match!)
                          ? `Level ${data}`
                          : LICENSE_FILTER.includes(d?.match!)
                          ? getStateNameFromCode(data)
                          : data
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AppliedFilter;
