export function getTimeDifference(givenDate: string): string {
  const dateToFindDifference = new Date(givenDate);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - dateToFindDifference.getTime();
  if (isNaN(timeDifference)) {
    return '-';
  }

  const seconds = Math.floor(Math.abs(timeDifference) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }
}

export const convertStopwatchTimeToLastUpdated = (
  days: number,
  hours: number,
  minutes: number
) => {
  if (days > 1) return 'a few days ago';
  else if (days === 1) return 'yesterday';
  else if (days === 0) {
    if (hours > 1) return `${hours} hours ago`;
    else if (hours === 1) return '1 hour ago';
    else if (hours === 0) {
      if (minutes > 1) return `${minutes} minutes ago`;
      else if (minutes === 1) return `1 minute ago`;
      else if (minutes === 0) return 'a few seconds ago';
    }
  }
};
