import React, { FC, ReactNode } from 'react';

import escapeStringRegexp from 'escape-string-regexp';

interface HighlighterProps {
  search: string | number | boolean | RegExp;
  caseSensitive?: boolean;
  ignoreDiacritics?: boolean;
  diacriticsBlacklist?: string;
  matchElement?: string | FC<any>;
  matchClass?: string;
  matchStyle?: React.CSSProperties;
  children: ReactNode;
}

const removeDiacritics = (str: string, blacklist?: string) => {
  if (!String.prototype.normalize) {
    return str;
  }

  if (!blacklist) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  } else {
    const blacklistChars = blacklist.split('');
    return str.normalize('NFD').replace(/.[\u0300-\u036f]+/g, (m) => {
      return blacklistChars.includes(m.normalize()) ? m.normalize() : m[0];
    });
  }
};

const Highlighter: FC<HighlighterProps> = ({
  search,
  caseSensitive = false,
  ignoreDiacritics = false,
  diacriticsBlacklist = '',
  matchElement = 'mark',
  matchClass = 'highlight',
  matchStyle = {},
  children,
}) => {
  const count = React.useRef(0);

  const isScalar = () => {
    return (
      typeof children === 'string' ||
      typeof children === 'number' ||
      typeof children === 'boolean'
    );
  };

  const hasSearch = () => {
    return search !== undefined && search !== null && search !== '';
  };

  const getSearch = (): RegExp => {
    if (search instanceof RegExp) {
      return search;
    }

    let flags = '';
    if (!caseSensitive) {
      flags += 'i';
    }

    let searchString =
      typeof search === 'string' ? escapeStringRegexp(search) : String(search);

    if (ignoreDiacritics) {
      searchString = removeDiacritics(searchString, diacriticsBlacklist);
    }

    return new RegExp(searchString, flags);
  };

  const getMatchBoundaries = (subject: string, searchRegex: RegExp) => {
    const matches = searchRegex.exec(subject);
    if (matches) {
      return {
        first: matches.index,
        last: matches.index + matches[0].length,
      };
    }
    return null;
  };

  const renderPlain = (text: string) => {
    count.current++;
    return <span key={count.current}>{text}</span>;
  };

  const renderHighlight = (text: string) => {
    count.current++;
    const Element = matchElement as keyof JSX.IntrinsicElements;
    return (
      <Element key={count.current} className={matchClass} style={matchStyle}>
        {text}
      </Element>
    );
  };

  const highlightChildren = (subject: string, searchRegex: RegExp) => {
    const childrenArray = [];
    let remaining = subject;

    while (remaining) {
      const remainingCleaned = ignoreDiacritics
        ? removeDiacritics(remaining, diacriticsBlacklist)
        : remaining;

      if (!searchRegex.test(remainingCleaned)) {
        childrenArray.push(renderPlain(remaining));
        return childrenArray;
      }

      const boundaries = getMatchBoundaries(remainingCleaned, searchRegex);
      if (!boundaries || (boundaries.first === 0 && boundaries.last === 0)) {
        return childrenArray;
      }

      const nonMatch = remaining.slice(0, boundaries.first);
      if (nonMatch) {
        childrenArray.push(renderPlain(nonMatch));
      }

      const match = remaining.slice(boundaries.first, boundaries.last);
      if (match) {
        childrenArray.push(renderHighlight(match));
      }

      remaining = remaining.slice(boundaries.last);
    }

    return childrenArray;
  };

  const renderElement = (subject: ReactNode) => {
    if (isScalar() && hasSearch()) {
      const searchRegex = getSearch();
      return highlightChildren(String(subject), searchRegex);
    }
    return children;
  };

  return <span>{renderElement(children)}</span>;
};

export default Highlighter;
