import { Row, Tag, Tooltip } from 'antd';

import { ApplicationTypeEnumInBackend } from '../../enums/applicationType.enum';
import { convertStringToCaps } from '../../utils/name.utils';

function ApplicationTypeCell({
  applicationType,
}: {
  applicationType: ApplicationTypeEnumInBackend | null;
}) {
  return applicationType ? (
    <Row align="middle">
      <Tooltip
        title={
          applicationType === ApplicationTypeEnumInBackend.creation
            ? 'Application was submitted successfully, and Application Status is now considered "In Progress". '
            : applicationType === ApplicationTypeEnumInBackend.renewal
              ? 'Renewals will be managed in the License Renewals section.'
              : null
        }
      >
        <div
          key={applicationType}
          className="bg-[#FCEFCF] border capitalize border-[#F8D79A] rounded-2xl text-xs font-figtree leading-5 text-[#001F45] px-2"
        >
          {applicationType?.replace(/_/g, ' ').replace(/-/g, ' ')}
        </div>
      </Tooltip>
    </Row>
  ) : (
    <></>
  );
}

export default ApplicationTypeCell;
