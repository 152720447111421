import { Button, Modal, Typography } from 'antd';
import { isEmpty, toUpper } from 'lodash';

import { InputOTP } from 'antd-input-otp';
import { SignupService } from '../../../../services/signup.service';
import { useAuth } from '../../../authProvider';
import { useState } from 'react';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onVerificationComplete: () => void;
  showError: (message: string, description: string) => void;
};
export const MagicCodeVerificationModal = ({
  visible,
  onCancel,
  onVerificationComplete,
  showError,
}: Props) => {
  const [code, setCode] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { bearerToken } = useAuth();

  const submitCodeForVerification = (value?: string[]) => {
    const code_ = value || code;
    const codeOTP = code_.join('');
    if (isEmpty(codeOTP) || codeOTP.length !== 6) {
      return;
    } else {
      setLoading(true);
      SignupService.verifyMagicCode(toUpper(codeOTP), bearerToken as string)
        .then(() => {
          onVerificationComplete();
        })
        .catch((err) => {
          console.error('Error while verification of magic code :: ', err);
          showError(
            'Magic Code Verification Failed',
            'Please check the code you entered'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCancelModal = () => {
    setCode([]);
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Email Verification with Code"
      closable={!loading}
      maskClosable={!loading}
      centered
      destroyOnClose
      onCancel={onCancelModal}
      footer={[
        <Button key="cancel" onClick={onCancelModal} disabled={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => submitCodeForVerification()}
          loading={loading}
          disabled={isEmpty(code) || code.join('').length !== 6}
        >
          Verify
        </Button>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          marginBottom: 24,
        }}
      >
        <Typography.Paragraph type="secondary" className="no-margin-bottom">
          Please enter the 6-digit code you received
        </Typography.Paragraph>
        <InputOTP
          onChange={(code: string[]) => setCode(code.map((c) => toUpper(c)))}
          value={code}
          autoSubmit={submitCodeForVerification}
        />
      </div>
    </Modal>
  );
};
