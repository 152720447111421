import { InsureTrekUser, auth0Store } from '../stores/auth0Store';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AdminService } from '../services/admin.service';
import { Auth0Service } from '../services/auth0.service';
import { DecodedJwtToken } from './interfaces/decodedJwtToken.interface';
import { InviteType } from '../enums/signupPage.enum';
import { RoleType } from '../enums/roles.enum';
import { RouteConstants } from '../constants/routes.constants';
import { UpLineService } from '../services/uplines.service';
import { UserType } from '../enums/userType.enum';
import { adminStore } from '../stores/admin.store';
import { agentSideStore } from '../stores/agentPortalStore';
import { inviteStore } from '../stores/invites.store';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import notification from 'antd/es/notification';
import { signupStore } from '../stores/signupStore';
import { useNavigate } from 'react-router';

/**
 * AuthContextProps interface represents the authentication context object.
 *
 * @property {User | undefined} userMetadata - The user metadata object.
 * @property {string[]} permissions - The array of permissions assigned to the user.
 * @property {string[]} roles - The array of roles assigned to the user.
 * @property {boolean} isAuthenticated - A boolean value indicating whether the user is authenticated or not.
 * @property {string | null} bearerToken - The bearer token used for authentication.
 */
export interface AuthContextProps {
  roles: string[];
  isAuthenticated: () => boolean;
  getAccessTokenSilently: () => Promise<string>;
  bearerToken: string | null;
  isOnboarded: boolean | null;
  isOnboardingEnabled: boolean | null;
  onboardingStage: string | null;
  setOnboardingStage: (prop: string | null) => void;
  refreshToken: (forceRefresh?: boolean) => Promise<void>;
  performLogin: (
    email: string,
    password: string,
    recaptchaToken: string
  ) => Promise<boolean>;
  performLogout: (logoutUrl?: string) => Promise<void>;
  processSignUp: (
    idToken: string,
    accessToken: string,
    refreshToken: string
  ) => void;
  loadUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

/**
 * AuthProviderProps interface represents the props object for the AuthProvider component.
 *
 * @property {ReactNode} children - The child components to be wrapped by the AuthProvider.
 */
interface AuthProviderProps {
  children: ReactNode;
}

/**
 * AuthProvider component is a wrapper component that provides authentication-related data and functionality to its child components.
 *
 * @param {AuthProviderProps} props - The props object containing the children components.
 * @returns {ReactNode} - The wrapped child components with authentication context.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<string[]>([]);
  const [userMetadata, setUserMetadata] = useState<any>();
  const [bearerToken, setBearerToken] = useState<string | null>(null);
  const [isOnboarded, setIsOnboarded] = useState<boolean | null>(null);
  const [isOnboardingEnabled, setIsOnboardingEnabled] = useState<
    boolean | null
  >(null);
  const [onboardingStage, setOnboardingStage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isMeApiLoading, setIsMeApiLoading] = useState(false);
  const [isInviteApiLoading, setIsInviteApiLoading] = useState(false);
  const [isTokenRefreshProcessing, setTokenRefreshProcessing] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  // // SESSION STORAGE LOGIC START
  // // State to store isLoggedIn status
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    //   // Get initial value from local storage
    const storedValue = localStorage.getItem('isLoggedIn');
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [onLogin, setOnLogin] = useState(false);

  // show loader if/mMe api is loading or if fetch invites api is loading
  useEffect(() => {
    signupStore.setIsAppLoading(isMeApiLoading || isInviteApiLoading);
  }, [isMeApiLoading, isInviteApiLoading]);

  const processLogout = () => {
    console.debug('Logging out...');
    setIsLoggedIn(false);
    performLogout();
  };

  // Effect to register changes and perform actions accordingly
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // Check if the event key is 'isLoggedIn'
      if (event.key === 'isLoggedIn') {
        const newIsLoggedIn = JSON.parse(event.newValue || 'false');

        // If isLoggedIn is true, simulate checkSession
        if (newIsLoggedIn) {
          // Simulate checkSession
          setTimeout(() => {
            console.debug('Session checked successfully.');
            // Update state
            setIsLoggedIn(true);
          }, 1000);
        } else {
          processLogout();
        }
      }
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Function to toggle isLoggedIn status
  const updateLoginStatus = (newIsLoggedIn: boolean) => {
    setIsLoggedIn(newIsLoggedIn);
    localStorage.setItem('isLoggedIn', JSON.stringify(newIsLoggedIn));
  };
  // SESSION STORAGE LOGIC END

  const isAuthenticated = () => {
    const accessToken = localStorage.getItem('insuretrek_at');
    if (isEmpty(accessToken)) return false;
    const decodedToken: DecodedJwtToken = jwtDecode(accessToken as string);
    return Date.now() < decodedToken.exp * 1000;
  };

  const processSignUp = (
    idToken: string,
    accessToken: string,
    refreshToken: string
  ): void => {
    if (idToken && accessToken && refreshToken) {
      localStorage.setItem('insuretrek_it', idToken);
      localStorage.setItem('insuretrek_at', accessToken);
      localStorage.setItem('insuretrek_rt', refreshToken);
      setBearerToken(accessToken);
      const decodedToken1: DecodedJwtToken = jwtDecode(accessToken);
      setUserMetadata(
        decodedToken1['https://dev.agent-sense.com/userMetadata']
      );
      setOnLogin(true);
    }
  };

  const performLogin = async (
    email: string,
    password: string,
    recaptchaToken: string
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      Auth0Service.performLogin(email, password, recaptchaToken)
        .then((result) => {
          updateLoginStatus(true);
          localStorage.setItem('insuretrek_it', result.idToken as string);
          localStorage.setItem('insuretrek_at', result.accessToken as string);
          localStorage.setItem('insuretrek_rt', result.refreshToken as string);
          setBearerToken(result.accessToken as string);
          const decodedToken: DecodedJwtToken = jwtDecode(
            result.accessToken as string
          );
          setUserMetadata(
            decodedToken['https://dev.agent-sense.com/userMetadata']
          );
          resolve(true);
          setOnLogin(true);
        })
        .catch((error) => {
          let errorMessage = 'Authentication Failed';
          if (error?.error_description) errorMessage = error?.error_description;
          reject(errorMessage);
        });
    });
  };

  const performLogout = async (
    logoutUrl = process.env.REACT_APP_REDIRECT_URI
  ): Promise<void> => {
    updateLoginStatus(false);
    const currentAgencyId = localStorage.getItem('x-agency-id');
    const currentProfileName = localStorage.getItem('x-role');
    localStorage.clear();
    if (currentAgencyId && currentProfileName) {
      localStorage.setItem('x-agency-id', currentAgencyId);
      localStorage.setItem('x-role', currentProfileName);
    }
    return Auth0Service.performLogout(logoutUrl ?? '');
    // return;
  };

  const getAccessTokenSilently = async (
    forceRefresh: boolean = false
  ): Promise<string> => {
    try {
      // Check if user logged in
      const accessToken = localStorage.getItem('insuretrek_at');
      if (isEmpty(accessToken)) {
        // performLogout(window.location.href);
        return '';
      } else {
        const decodedToken: DecodedJwtToken = jwtDecode(accessToken as string);
        //TODO Verify with JWKS File
        if (Date.now() >= decodedToken.exp * 1000 || forceRefresh) {
          //Token is expired - Fetch refresh token
          const refreshToken = localStorage.getItem('insuretrek_rt');
          if (isEmpty(refreshToken)) {
            console.error('Unable to refresh token - logging out');
            performLogout();
          } else {
            const result = await Auth0Service.performTokenRefresh(
              refreshToken as string
            );
            setBearerToken(result.accessToken as string);
            const decodedToken1: DecodedJwtToken = jwtDecode(
              result.accessToken as string
            );
            setUserMetadata(
              decodedToken1['https://dev.agent-sense.com/userMetadata']
            );
            localStorage.setItem('insuretrek_it', result.idToken as string);
            localStorage.setItem('insuretrek_at', result.accessToken as string);
            localStorage.setItem(
              'insuretrek_rt',
              result.refreshToken as string
            );
            return result.accessToken as string;
          }
        } else {
          // setBearerToken(accessToken);
          // setRoles(decodedToken['https://dev.agent-sense.com/roles']);
          // setUserMetadata(
          //   decodedToken['https://dev.agent-sense.com/userMetadata']
          // );
          return accessToken as string;
        }
      }
    } catch (err) {
      console.error('Performing Logout in Catch', err);
      performLogout();
    }
    return '';
  };

  const fetchInvites = async () => {
    try {
      !signupStore.isAdminAuthorized && setIsInviteApiLoading(true);

      if (auth0Store.user?.email && bearerToken) {
        let inUserInvite = false;
        if (window.location.pathname.includes('user-invite'))
          inUserInvite = true;
        const response = await AdminService.findInvitesForUser(
          bearerToken,
          inUserInvite
        ).catch((error) => {
          signupStore.setIsInvited(false);
        });
        if (response) {
          const data = response.data;
          signupStore.setIsInvited(true);
          signupStore.setInviteType(
            data.inviteType === 'user' ? InviteType.ADMIN : InviteType.DOWNLINE
          );
          if (data.inviteType === 'downline') {
            navigate(RouteConstants.onboarding.path);
          } else if (data.inviteType === 'user') {
            signupStore.setAdminInviteDetails(
              auth0Store.user.email,
              response.data.inviteeDetails.role,
              response.data.inviterDetails.agencyId,
              response.data.inviteToken,
              response.data.inviterDetails.agency.name
            );
            setOnboardingStage('admin-invite');
            if (!adminStore.agency?.id)
              navigate(RouteConstants.authUserInvite.path);
          }
        }
      }

      if (bearerToken) {
        const response = await inviteStore.loadInvites(bearerToken);
        if (response) {
          if (response.data.length > 0) {
            navigate(RouteConstants.invites.path);
          }
        }
      }
    } catch (e) {
      console.error('error while fetching invites :: ', e);
    } finally {
      setIsInviteApiLoading(false);
    }
  };

  useEffect(() => {
    if (!auth0Store.user?.email || !bearerToken || !onLogin) return;

    fetchInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Store.user?.email, bearerToken, onLogin]);

  /**
   * fetchToken is an asynchronous function that retrieves the access token using the getAccessTokenSilently function from the Auth0 library.
   * It then decodes the access token to extract the permissions and roles using the jwtDecode function from the jwt-decode library.
   * The decoded token is stored in the component's state variables.
   *
   * @returns {Promise<void>} - A promise that resolves when the access token is fetched and decoded successfully.
   */
  const fetchToken = async () => {
    try {
      //Initialise Token in state
      const accessToken = localStorage.getItem('insuretrek_at');
      if (accessToken) {
        const decodedToken: DecodedJwtToken = jwtDecode(accessToken as string);
        const userMetadata =
          decodedToken['https://dev.agent-sense.com/userMetadata'];
        if (
          Date.now() >= decodedToken.exp * 1000 ||
          ('isOnboarded' in userMetadata && userMetadata.isOnboarded !== true)
        ) {
          signupStore.setIsAppLoading(true);
          await getAccessTokenSilently(true);
        } else {
          setBearerToken(accessToken as string);
          setUserMetadata(
            decodedToken['https://dev.agent-sense.com/userMetadata']
          );
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const refreshToken = async (forceRefresh: boolean = false) => {
    if (!isTokenRefreshProcessing) {
      try {
        setTokenRefreshProcessing(true);
        await getAccessTokenSilently(forceRefresh);
        setTokenRefreshProcessing(false);
      } catch (e: any) {
        console.error(e.message);
      }
    }
  };

  useEffect(() => {
    userMetadata &&
      'isOnboarded' in userMetadata &&
      setIsOnboarded(userMetadata.isOnboarded);
    userMetadata &&
      'isOnboardingEnabled' in userMetadata &&
      setIsOnboardingEnabled(userMetadata.isOnboardingEnabled);
    userMetadata &&
      'onboardingStage' in userMetadata &&
      setOnboardingStage(userMetadata.onboardingStage);
  }, [userMetadata]);

  useEffect(() => {
    loadUser();
  }, [bearerToken]);

  const loadUser = async () => {
    if (bearerToken) {
      setIsMeApiLoading(true);
      let inUserInvite = false;
      if (window.location.pathname.includes('user-invite')) inUserInvite = true;
      auth0Store
        .loadProfiles(bearerToken, inUserInvite)
        .then(() => {
          return auth0Store.loadUser(bearerToken, inUserInvite, true);
        })
        .then(async (insureTrekUser: InsureTrekUser) => {
          auth0Store.setUser(insureTrekUser.auth0User);
          if (
            insureTrekUser.producer &&
            insureTrekUser.role === RoleType.AGENT
          ) {
            signupStore.setUserType(UserType.AGENT);
            if (insureTrekUser.agency) {
              setRoles([RoleType.AGENT]);
              agentSideStore.loadAgentByDocument(
                insureTrekUser.producer,
                insureTrekUser.agency
              );
              signupStore.setIsAgentAuthorized(true);
              // navigate(RouteConstants.agentDashBoard.path);
            } else {
              try {
                const uplineAgency: any =
                  await UpLineService.getUplineAgencyForCurrentAgent(
                    bearerToken
                  ).catch((e) => {});

                if (!uplineAgency) {
                  signupStore.setIsAgentAuthorized(false);
                  navigate('/unauthorized-page');
                } else {
                  setRoles([RoleType.AGENT]);
                  agentSideStore.loadAgentByDocument(
                    insureTrekUser.producer,
                    insureTrekUser.agency,
                    uplineAgency
                  );
                  signupStore.setIsAgentAuthorized(true);
                  navigate(RouteConstants.agentDashBoard.path);
                }
              } catch (error) {
                signupStore.setIsAgentAuthorized(false);
                console.error('fetchAgentById err :: ', error);
              }
            }
            signupStore.setUserType(UserType.AGENT);
          } else if (insureTrekUser.role !== RoleType.AGENT) {
            setRoles([RoleType.ADMIN]);
            signupStore.setIsAdminAuthorized(true);
            signupStore.setUserType(UserType.ADMIN);
          }
          if (insureTrekUser.account) {
            adminStore.setAccount(insureTrekUser.account);
            adminStore.setAgency(insureTrekUser.agency);
            adminStore.setRole(insureTrekUser.role);
          }
        })
        .catch((err) => {
          console.error('Error in /me API :: ', err?.message);
          if (
            err?.response?.data?.error?.message !==
            'Email exists for both agent and admin'
          ) {
            performLogout();
          } else {
            api['error']({
              message: 'Authentication Failed',
              description: err?.response?.data?.error?.message,
            });
          }
          // performLogout();
        })
        .finally(() => {
          setIsMeApiLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        roles,
        isAuthenticated,
        getAccessTokenSilently,
        bearerToken,
        isOnboarded,
        isOnboardingEnabled,
        onboardingStage,
        setOnboardingStage,
        refreshToken,
        performLogin,
        performLogout,
        processSignUp,
        loadUser,
      }}
    >
      {contextHolder}
      {children}
    </AuthContext.Provider>
  );
};

/**
 * useAuth is a custom hook that allows components to access the authentication context provided by the AuthProvider.
 *
 * @returns {AuthContextProps} The authentication context object, which includes the user's authentication state and related functions.
 * @throws {Error} Throws an error if useAuth is not used within an AuthProvider.
 */
export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used inside AuthProvider');
  }

  return context;
};
