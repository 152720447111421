import { Name } from '../types/data/name.type';

export const getNameString = (name: Name | string): string => {
  if (!name) return '';
  if (name instanceof String || typeof name === 'string') return name as string;
  const firstName = name?.firstName ?? '';
  const lastName = name?.lastName ?? '';
  return firstName.trim() + ' ' + lastName.trim();
};

export const convertStringToCaps = (input: string): string => {
  // Convert the string to uppercase and replace underscores with empty spaces
  if (!input) return input;
  const result = input.toUpperCase().replace(/_/g, ' ').replace(/-/g, ' ');
  return result;
};

export const cleanAndCapitalizeString = (name: string): string => {
  const cleanedValue = name.replace(/[^A-Za-z\s]/g, '');
  return cleanedValue.charAt(0).toUpperCase() + cleanedValue.slice(1);
};

export const getInitials = (name:string) => {
  if (!name) return '';
  const words = name.trim().split(' ').filter(Boolean);
  if (words.length === 0) return '';
  if (words.length === 1) return words[0][0].toUpperCase();

  return `${words[0][0].toUpperCase()}${words[words.length - 1][0].toUpperCase()}`;
};

