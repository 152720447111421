import { Row, Tooltip, Typography } from 'antd';
import { isEmpty, isUndefined } from 'lodash';

import { LicenseStatus } from '../../enums/licenseStatus.enum';
import { PERPETUAL_STATES } from '../../constants/perpetualStates.constants';
import { capitalizeFirstLetter } from '../../utils/common.utils';
import moment from 'moment';

function DaysToExpireCell({
  expiryDate,
  licenseStatus,
  licenseState,
}: {
  expiryDate: string;
  licenseStatus: LicenseStatus;
  licenseState: string;
}) {
  const expiryDateMomentObj = moment(expiryDate);
  const isExpired = moment(expiryDate).isBefore(moment());
  return (
    <Row align="middle" style={{ textAlign: 'center' }}>
      {expiryDate &&
      (licenseStatus === LicenseStatus.Active ||
        licenseStatus === LicenseStatus.MISSING_LOA) ? (
        <Tooltip
          title={
            `Expire${isExpired ? `d` : `s`} on ` +
            expiryDateMomentObj.format('ll')
          }
        >
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              marginBottom: '0.25em',
            }}
          >
            <>
              {expiryDateMomentObj.format('ll')} {'    '}
            </>
            ({capitalizeFirstLetter(expiryDateMomentObj.fromNow())})
          </Typography.Paragraph>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            !isUndefined(expiryDate)
              ? `Expire${isExpired ? `d` : `s`} on ` +
                expiryDateMomentObj.format('ll')
              : ''
          }
        >
          <Typography.Paragraph
            type="secondary"
            style={{
              fontSize: '12px',
              marginBottom: '0.25em',
            }}
          >
            {licenseStatus !== LicenseStatus.Active &&
              !(
                licenseStatus === LicenseStatus.MISSING_LOA &&
                PERPETUAL_STATES.includes(licenseState)
              ) &&
              'License is not active'}
            {(licenseStatus === LicenseStatus.Active ||
              licenseStatus === LicenseStatus.MISSING_LOA) &&
              (isEmpty(expiryDate) || isUndefined(expiryDate)) &&
              PERPETUAL_STATES.includes(licenseState) &&
              'Does Not Expire'}
            {licenseStatus === LicenseStatus.Active &&
              isEmpty(expiryDate) &&
              !PERPETUAL_STATES.includes(licenseState) &&
              'No Expiry Set'}
          </Typography.Paragraph>
        </Tooltip>
      )}
    </Row>
  );
}

export default DaysToExpireCell;
