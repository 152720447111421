import { Tooltip } from 'antd';

interface StateCellProps {
  disabled: boolean;
  successPercentage: number;
  errorPercentage: number;
  successTooltipText: string;
  errorTooltipText: string;
}

function ProgressBar({
  disabled,
  successPercentage,
  errorPercentage,
  successTooltipText,
  errorTooltipText,
}: StateCellProps) {
  return (
    <div
      style={{
        display: 'flex',
        height: '20px',
        width: '80%',
        borderRadius: '3.385px',
        overflow: 'hidden',
        backgroundColor: disabled ? '#E6E6E6' : 'transparent',
        border: disabled ? '1px solid #E6E6E6' : 'none',
      }}
    >
      {!disabled && (
        <>
          <Tooltip
            color="#fff"
            title={errorTooltipText}
            overlayInnerStyle={{
              color: '#001F45',
              fontFamily: 'Figtree',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: `${errorPercentage}%`,
                backgroundColor: '#9D0F0F',
                borderTopLeftRadius: '3.385px',
                borderBottomLeftRadius: '3.385px',
              }}
            />
          </Tooltip>
          <Tooltip
            color="#fff"
            title={successTooltipText}
            overlayInnerStyle={{
              color: '#001F45',
              fontFamily: 'Figtree',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: `${successPercentage}%`,
                backgroundColor: '#0F9D58',
                borderTopRightRadius: '3.385px',
                borderBottomRightRadius: '3.385px',
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default ProgressBar;
