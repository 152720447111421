import styled from 'styled-components';

interface TextDetailProps {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  padding?: string;
  border?: string;
  background?: string;
}

export const ModalContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 12px;
  background: #fff;
  overflow-y: scroll;
  max-height: calc(96vh - 100px);
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

export const ApplicationDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

export const DetailsContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.gap || '15px'};
  align-self: stretch;
`;

export const DetailsHeading = styled.div`
  color: #222222;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px; /* 150% */
`;

export const DetailsCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  row-gap: 12px;
  column-gap: 8px;
`;

export const LicenseDetails = styled.div<{ width?: string }>`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const TextDetail = styled.div<TextDetailProps>`
  color: ${(props) => props.textColor || '#222'};
  font-family: Figtree;
  font-size: ${(props) => props.fontSize || '12px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: ${(props) => props.lineHeight || '20px'}; /* 200% */
  padding: ${(props) => props.padding || 0};
  border: ${(props) => props.border || 'none'};
  background: ${(props) => props.background || 'none'};
`;

export const Insurancetypes = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;
export const ContactDetails = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: ${(props) => props.width || '100px'};
`;
export const InsuranceLayout = styled.div<{
  border?: string;
  background?: string;
}>`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 26px;
  border: ${(props) => props.border || '1px solid #dff4f5;'};
  background: ${(props) => props.background || '#f7fcfc'};
`;
export const TransactionDetails = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: ${(props) => props.width || '100px'};
`;

export const ButtonAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: disc;
`;

export const ErrorListItem = styled.li`
  color: #848484;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
`;
