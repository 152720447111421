import {
  AgencyRtsTableProps,
  AgentsRtsTableType,
  AgentsType,
  CarriersType,
  StateType,
} from './agencyRtsTypes';
import { AgentRtsCompanyHeader, AgentRtsHeader } from './agencyRtsByCarrier';
import React, { Key, useEffect } from 'react';

import { Appointment } from '../../../types/data/rts.type';
import { StateConstants } from '../../../constants/state.constants';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { expandAgents } from './agencyRts';
import { getNameString } from '../../../utils/name.utils';
import { groupBy } from 'lodash';

interface AgentData {
  [key: string]: {
    [state: string]: AgentsRtsTableType[];
  };
}

const AgencyRtsByStateTable: React.FC<AgencyRtsTableProps> = ({
  expandedKeys,
  setExpandedKeys,
  tableData,
  isExpanded,
  moveToTop,
  setAllExpandedKeys,
}) => {
  let updatedData: Record<string, any[]> = {};
  tableData?.forEach((d) => {
    updatedData[d.stateCode] = d?.appointments;
  });
  let agentData: AgentData = {};
  Object.keys(updatedData).map((key: string) => {
    agentData[key] = {
      ...groupBy(updatedData[key], 'companyName'),
    };
  });

  useEffect(() => {
    if (!isExpanded) return;
    const stateKeys: Key[] = [];
    const carrierKeys: Key[] = [];
    const agentKeys: Key[] = [];
    Object.keys(agentData).forEach((state: string) => {
      stateKeys.push(state);
      Object.keys(agentData[state]).forEach((carrier) => {
        carrierKeys.push(carrier + state);
        agentData?.[state]?.[carrier]?.forEach((agents) => {
          agentKeys.push(agents?.producer?.id + state + carrier);
        });
      });
    });
    setExpandedKeys({
      state: stateKeys,
      carrier: carrierKeys,
      agent: agentKeys,
    });
    setAllExpandedKeys((prev) => ({
      ...prev,
      state: {
        state: stateKeys,
        carrier: carrierKeys,
        agent: agentKeys,
      },
    }));
  }, [isExpanded]);

  // Carriers Table
  const expandCarriers = (e: CarriersType, state: string) => {
    const columns: TableColumnsType<AgentsType> = [
      {
        dataIndex: 'Agents',
        key: 'Agents',
        render: (text, record) => <AgentRtsHeader record={record} />,
      },
    ];

    const datas: AgentsType[] = [];
    const updatedFields: any[] = [];
    agentData[state]?.[e.companyName]?.forEach((key, index) => {
      updatedFields.push(key);
      if (
        datas
          .map((data) => data.key)
          .includes(key.producer?.id + state + e.companyName)
      )
        return;
      datas.push({
        key: key.producer?.id + state + e.companyName,
        Agents: getNameString(key.producer?.name),
        agentId: key.producer?.id,
      });
    });
    datas.sort((a, b) => a.Agents.localeCompare(b.Agents));

    const handleAgentRowClick = (record: AgentsType) => {
      const key = record.key;
      const isExpanded = expandedKeys.agent.includes(key);
      if (isExpanded) {
        setExpandedKeys((prevState) => ({
          ...prevState,
          agent: prevState.agent.filter((k) => k !== key),
        }));
      } else {
        setExpandedKeys((prevState) => ({
          ...prevState,
          agent: [...prevState.agent, key],
        }));
      }
    };

    return (
      <Table
        columns={columns}
        dataSource={datas}
        style={{ margin: '6px 0' }}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleAgentRowClick(record), // Handle row click
        })}
        expandable={{
          expandedRowRender: (e) =>
            expandAgents(e, updatedFields as Appointment[]),
          expandedRowKeys: expandedKeys.agent,
          onExpand: (expanded, record) => handleAgentRowClick(record),
        }}
      />
    );
  };
  //

  const expandStates = (event: StateType) => {
    const columns: TableColumnsType<CarriersType> = [
      {
        dataIndex: 'companyName',
        key: 'companyName',
        render: (text, record) => <AgentRtsCompanyHeader record={record} />,
      },
    ];

    const data: CarriersType[] = [];
    updatedData[event.state].forEach((key, index) => {
      if (data.map((data) => data.key).includes(key.companyName + event.state))
        return;
      data.push({
        key: key.companyName + event.state,
        companyName: key.companyName,
        coCode: key.coCode,
      });
    });
    data.sort((a, b) => a.companyName.localeCompare(b.companyName));
    const handleCarrierRowClick = (record: CarriersType) => {
      const key = record.key;
      const isExpanded = expandedKeys.carrier.includes(key);
      if (isExpanded) {
        setExpandedKeys((prevState) => ({
          ...prevState,
          carrier: prevState.carrier.filter((k) => k !== key),
        }));
      } else {
        setExpandedKeys((prevState) => ({
          ...prevState,
          carrier: [...prevState.carrier, key],
        }));
      }
    };

    return (
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (e) => expandCarriers(e, event.state),
          expandedRowKeys: expandedKeys.carrier,
          onExpand: (expanded, record) => handleCarrierRowClick(record),
        }}
        onRow={(record) => ({
          onClick: () => handleCarrierRowClick(record),
        })}
        dataSource={data}
        size="small"
        pagination={false}
      />
    );
  };

  const columns: TableColumnsType<StateType> = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text) => {
        return <span>{StateConstants[text]}</span>;
      },
    },
  ];

  const data: StateType[] = [];

  Object.keys(updatedData).forEach((key, index) => {
    data.push({
      key: key,
      state: key,
    });
  });

  data.sort((a, b) => a.state.localeCompare(b.state));

  const handleStateRowClick = (record: StateType) => {
    const key = record.key;
    const isExpanded = expandedKeys.state.includes(key);
    if (isExpanded) {
      setExpandedKeys((prevState) => ({
        ...prevState,
        state: prevState.state.filter((k) => k !== key),
      }));
    } else {
      setExpandedKeys((prevState) => ({
        ...prevState,
        state: [...prevState.state, key],
      }));
    }
  };

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowRender: expandStates,
        defaultExpandedRowKeys: data.map((i) => i.key),
        expandedRowKeys: expandedKeys.state,
        onExpand: (expanded, record) => handleStateRowClick(record),
      }}
      onRow={(record) => ({
        onClick: () => handleStateRowClick(record),
      })}
      dataSource={data}
      size="small"
      pagination={{
        defaultPageSize: 100,
        showSizeChanger: false,
        onChange: () => {
          moveToTop();
        },
      }}
    />
  );
};

export default AgencyRtsByStateTable;
