import {
  AutoComplete,
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Tooltip,
  notification,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { ApplicationTypeEnumInBackend } from '../../../enums/applicationType.enum';
import { ButtonProp } from '../../../components/common/RadioButton/radioButton';
import EngineLights from '../../../components/licenses/engineLights';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { IndividualApplyButton } from './individualApplyButton';
import LcLoaCell from '../../../components/licenses/lcLoaCell';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import LicenseModal from '../../../utils/modal/application-status/applictionStatus.modal';
import LicenseStatusCell from '../../../components/licenses/licenseStatusCell';
import { LicensesService } from '../../../services/licenses.service';
import { ObjectSuccessResponse } from '../../../types/response/objectSuccessResponse.type';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { PayeeEnum } from '../../../enums/payee.enum';
import { PortalType } from '../../../enums/portalType.enum';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../components/licenses/stateCell';
import { StateConstants } from '../../../constants/state.constants';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { applySort } from '../../../utils/common.utils';
import { getProducerName } from '../../agents/agentsOverview/agencyAgentOverview';
import { isEmpty } from 'lodash';
import { produce } from 'immer';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

enum Tab {
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}
const tooltipMessages = {
  [Tab.MISSING_LICENSES]:
    'Review and manage expired and missing state licenses.',
  [Tab.MISSING_LOAS]:
    'Please identify any License of Authority (LOA) that may be missing for a coordinating license. Submit an application for them either individually or in bulk.',
};

const { Search } = Input;

function AgentActionableLicenses() {
  const [selectedFilter, setSelectedValue] = useState(Tab.MISSING_LICENSES);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [count, setCount] = useState(10);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [activeData, setActiveData] = useState<LicenseApplicationResponse[]>(
    []
  );
  const [selectedLicenses, setSelectedLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  const [applyLicenseLoader, setApplyLicenseLoader] = useState(false);
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const { bearerToken } = useAuth();
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>('');
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [autoRenewEnabled, setAutoRenewEnabled] = useState<boolean>(false);

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [controlledFilters, setControlledFilters] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [currentTab, setSelectedTab] = useState<Tab>(Tab.MISSING_LICENSES);
  const { getAccessTokenSilently } = useAuth();
  const [sortValue, setSortValue] = useState({});

  const [api, contextHolder] = notification.useNotification();
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const clearFilters = (onTabSwitch?: boolean) => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const fetchFiltersInfoForFindAgentActionableLicenses: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      if (bearerToken) {
        let type;
        if (selectedFilter === Tab.MISSING_LOAS)
          type = ApplicationTypeEnumInBackend.missingLoas;
        else if (selectedFilter === Tab.MISSING_LICENSES)
          type = ApplicationTypeEnumInBackend.creation;
        else type = ApplicationTypeEnumInBackend.missing;
        const response: any =
          await LicensesService.getFilterInfoForFindAgentActionable(
            bearerToken
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH && data.key !== 'name'
            ),
            PageConstants.NEEDS_ATTENTION
          )
        );
        const filters = response.data.filter(
          (d: any) => d.key !== 'producerIds'
        );
        setControlledFilters(filters);
        return filters;
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(
        'An error occurred while fetching admins. Please try again later.'
      );
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const filters: ButtonProp[] = [
    {
      value: Tab.MISSING_LICENSES,
      label: 'Missing Licenses',
    },
    {
      value: Tab.MISSING_LOAS,
      label: 'Missing LOAs',
    },
  ];

  const filtersIfInitialPaymentAgency: ButtonProp[] = [
    { value: Tab.MISSING_LICENSES, label: 'Missing Licenses' },
    {
      value: Tab.MISSING_LOAS,
      label: 'Missing LOAs',
    },
  ];

  const deleteAppliedLicense = async (licenseId: string) => {
    api['success']({
      message: 'License application submitted',
      description: SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS,
    });
    const filteredData = activeData.filter(
      (item: any) => item.id !== licenseId
    );
    setActiveData(filteredData);
  };

  const handleRowClick = (record: any) => {
    // Store the selected row data
    setSelectedLicenseDetails(record);
    setAutoRenewEnabled(
      record.producerDetails?.paymentConfig?.isAutoRenewalActive
    );
    setApplicationModalVisible(true); // Open the modal
  };

  const updateLicenseById = (
    licenseId: string,
    record: LicenseApplicationResponse
  ) => {
    const newActiveData = produce(activeData, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].license.id === licenseId) {
          console.debug('found license by id :: updating');
          draft[i] = { ...record };
          break;
        }
      }
    });
    setActiveData(newActiveData);
  };

  const setIsLoadingForGivenLicense = (
    licenseId: string,
    isLoading: boolean
  ) => {
    setActiveData(activeData);
  };

  const refreshLicenseById = async (licenseId: string) => {
    try {
      setIsLoadingForGivenLicense(licenseId, true);
      const token = await getAccessTokenSilently();

      const response: ObjectSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.findLatestApplicationForGivenLicense(
          token,
          licenseId
        );
      if (response?.data) {
        updateLicenseById(licenseId, response.data);
      } else setIsLoadingForGivenLicense(licenseId, false);
    } catch (e) {
      setIsLoadingForGivenLicense(licenseId, false);
    }
  };

  const columns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      width: 140,
      render: (text: string, record: any) => (
        <StateCell
          state={StateConstants[text]}
          licenseNumber={record.licenseNumber}
        />
      ),
    },
    {
      title: 'License Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 130,

      render: (_: any, record: LicenseApplicationResponse) => (
        <LicenseStatusCell licenseStatus={_} />
      ),
    },
    {
      title: 'Application Status',
      dataIndex: 'latestApplication',
      sorter: true,
      key: 'latestApplicationStatus',
      align: 'center',
      width: 200,
      render: (_: any, record: any) => {
        return record.isLoading ? (
          <Skeleton.Button active size="small" />
        ) : (
          <Row
            align="middle"
            style={{ textAlign: 'center' }}
            onClick={() => {
              if (
                record?.status &&
                record?.status?.toLocaleLowerCase() !==
                  ApplicationStatusEnum.Not_Applied
              ) {
                handleRowClick(record);
              }
            }}
          >
            <ApplicationStatusCell
              applicationStatus={
                record?.latestApplication
                  ? record?.latestApplication?.applicationStatus
                  : ApplicationStatusEnum.Not_Applied
              }
            />
          </Row>
        );
      },
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'producerDetails',
      key: 'producerDetails.paymentConfig.licenseRenewalPayee',
      width: '100',
      sorter: true,

      render: (_: any, record: any, index: number) => {
        return (
          <p>
            {getProducerName(
              record?.producerDetails?.paymentConfig?.licenseRenewalPayee
            ) || '-'}
          </p>
        );
      },
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      sorter: true,
      width: 300,
      render: (loas: any, record: any) => {
        return (
          <Row align="middle">
            <LcLoaCell
              licenseClass={record.licenseConfigurations?.licenseClass}
              loaDetails={loas?.loaDetails}
              currentTab={currentTab}
              // requiredStatus={LoaStatus.ACTIVE}
            />
          </Row>
        );
      },
    },
    {
      width: 180,
      render: (record: any) => {
        const isPaymentConfiguredAsAgency =
          record?.producerDetails?.paymentConfig?.licenseInitialPayee ===
            'agency' &&
          record?.producerDetails?.paymentConfig?.licenseRenewalPayee ===
            'agency';
        return (
          <Col
            style={{
              alignItems: 'center',
              rowGap: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EngineLights
              isAgentPortal={true}
              disable={isPaymentConfiguredAsAgency}
              payer={
                record.applicationType === ApplicationTypeEnumInBackend.creation
                  ? record?.producerDetails?.paymentConfig?.licenseInitialPayee
                  : record?.producerDetails?.paymentConfig?.licenseRenewalPayee
              }
              setPayerCallBack={() => {}}
              record={record}
            />
            <IndividualApplyButton
              payer={
                record.applicationType === ApplicationTypeEnumInBackend.creation
                  ? record?.producerDetails?.paymentConfig?.licenseInitialPayee
                  : record?.producerDetails?.paymentConfig?.licenseRenewalPayee
              }
              selectedLicensesLength={selectedLicenses.length}
              record={record}
              deleteAppliedLicense={deleteAppliedLicense}
              setDisableApplyButton={setDisableApplyButton}
              disableApplyButton={
                disableApplyButton ||
                !!record.isLoading ||
                isPaymentConfiguredAsAgency
              }
              applyErrorCallback={refreshLicenseById}
            />
          </Col>
        );
      },
    },
  ];

  const getRenewalIndex = () =>
    columns.findIndex((obj) => obj.title === 'Renewal Payment Account');

  currentTab === Tab.MISSING_LICENSES &&
    columns.splice(getRenewalIndex(), 0, {
      title: 'Initial Payment Account',
      dataIndex: 'paymentConfig',
      key: 'producerDetails.paymentConfig.licenseInitialPayee',
      sorter: true,
      width: '12%',
      render: (_: any, record: any, index: number) => {
        return (
          <p>
            {getProducerName(
              record?.producerDetails?.paymentConfig?.licenseInitialPayee
            ) || '-'}
          </p>
        );
      },
    });

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectedValue(value);
    clearFilters(true);
    setSelectedTab(value);
  };

  const fetchActionableLicense = async (
    search?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;
    try {
      setIsLoading(true);
      if (bearerToken) {
        let type;
        if (selectedFilter === Tab.MISSING_LOAS)
          type = ApplicationTypeEnumInBackend.missingLoas;
        else if (selectedFilter === Tab.MISSING_LICENSES)
          type = ApplicationTypeEnumInBackend.missing;
        else type = ApplicationTypeEnumInBackend.missing;
        const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
          await LicensesService.getActionableLicensesForCurrentAgent(
            type,
            '',
            search || queryText || '',
            searchType ? searchType : '',
            bearerToken,
            size || pageSize,
            page || pageNumber,
            {
              ...requestBody,
              ...(sort || sortValue || {}),
            }
          );
        if (response) {
          setCount(response.totalCount);
          setActiveData(response.data);
          setErrorMessage('');
        } else {
          setCount(0);
          setErrorMessage('No producer data available.');
        }
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }
      setErrorMessage(
        'An error occurred while fetching admins. Please try again later.'
      );
    } finally {
      if (!errorTypeRequestCancel) setIsLoading(false);
    }
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  useEffect(() => {
    fetchActionableLicense();
  }, [bearerToken, queryText, searchType]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchActionableLicense('', 1);
    }
  }, [requestBody]);

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);

    fetchActionableLicense(queryText || '', newPageNumber, newPageSize);
  };

  useEffect(() => {
    try {
      fetchFiltersInfoForFindAgentActionableLicenses();
    } catch (error) {
      console.error(error);
    }
  }, [selectedFilter]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card style={{ width: '100%' }}>
      {/* DND */}
      {contextHolder}
      {/* DND End */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        {/* {
          <div style={{ cursor: loading ? 'not-allowed' : '' }}>
            <RadioButton
              buttons={
                agentSideStore.paymentConfig?.initialPaymentPayee ===
                  PayeeEnum.AGENCY &&
                agentSideStore.paymentConfig?.renewalPaymentPayee ===
                  PayeeEnum.AGENT
                  ? filtersIfInitialPaymentAgency
                  : filters
              }
              size="middle"
              buttonStyle="solid"
              style={{ pointerEvents: loading ? 'none' : 'auto' }}
              value={selectedFilter}
              onChange={handleRadioChange}
            />
          </div>
        }

        <div className="reset-icon-size">
          <Typography.Text
            type="secondary"
            style={{ marginBottom: 4, fontSize: 14 }}
          >
            <InfoCircleOutlined />{' '}
            {tooltipMessages[currentTab as unknown as Tab]}{' '}
          </Typography.Text>
          <br />
          <Typography.Text
            type="secondary"
            style={{ marginBottom: 10, fontSize: 12 }}
          >
            <InfoCircleOutlined /> {AUTO_RENEWAL_INFO}
          </Typography.Text>
        </div> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
            disabled={loading}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-actionable-licenses'}
              size="middle"
              placeholder="Search Licenses by State, LOAs or License Class"
              style={{
                width: '100%',
                marginRight: '10px',
                marginBottom: '10px',
                marginTop: '10px',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          {selectedLicenses.length > 1 && (
            <Button
              className="button"
              style={{ marginRight: '10px', marginTop: '10px' }}
              onClick={() => {
                setIsUpdateModalVisible(!isUpdateModalVisible);
              }}
              disabled={selectedLicenses.some(
                (itr) =>
                  itr?.agent?.paymentConfig?.initialPaymentPayee !==
                  PayeeEnum.AGENT
              )}
            >
              Apply All Licenses
            </Button>
          )}
          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading: loading,
              setIsFilterVisible,
              styles: {
                marginTop: '10px',
              },
            }}
          />
        </div>
      </ConfigProvider>

      <RenderFilterChip {...{ selectedFilters, removeFilter, clearFilters }} />

      <Table
        rowKey={(record) => record.id}
        loading={applyLicenseLoader || loading}
        columns={columns}
        dataSource={activeData}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, fetchActionableLicense);
          setSortValue(sort);
        }}
      />
      {!isEmpty(activeData) && (
        <Row className="pagination" style={{ marginTop: '10px' }} justify="end">
          <Pagination
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            current={pageNumber}
            onChange={onChange}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            disabled={applyLicenseLoader}
          />
        </Row>
      )}
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENT}
        showTransactionDetails={false}
        autoRenewEnabled={autoRenewEnabled}
      />
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        controlledFilters={controlledFilters}
        clearFilters={() => {
          clearFilters();
        }}
        filterPage={PageConstants.NEEDS_ATTENTION}
      />
    </Card>
  );
}

export default withRoles(AgentActionableLicenses, [RoleType.AGENT]);
