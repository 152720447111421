import { isEmpty } from 'lodash';

function generateRandomAlphanumericString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export function generateRandom10DigitAlphanumericString() {
  return generateRandomAlphanumericString(10);
}

export function maskSSN(ssn: string): string {
  if (!isEmpty(ssn)) {
    return ssn.replace(/.(?=.{4,}$)/g, '*');
  }
  return '';
}
