import AppliedFilter, { filterType } from './listView/appliedFilter';
import { Button, Input, Modal, Popover, Skeleton, Tabs, message } from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import {
  DEFAULT_FILTER,
  ListAppointmentDTO,
  NEW_FILTER,
  appliedFilterType,
  buttonStyle,
  convertFiltersToIFilterGroups,
  getFilterType,
  organizationOptions,
} from './organizationView/organizationUtils';
import ListBuilder, { ListType, SortType } from './listView/listBuilder';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import { DownlineService } from '../../../services/downlines.service';
import EmptyState from '../../../components/common/emptyState/emptyState';
import FileParser from '../../../utils/file-parser/fileParser';
import ListFilter from './listView/listFilter';
import { Name } from '../../../types/data/name.type';
import OrganizationView from './organizationView/organizationView';
import { RouteConstants } from '../../../constants/routes.constants';
import { adminStore } from '../../../stores/admin.store';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { getAddressLine } from '../onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { getNameString } from '../../../utils/name.utils';
import { isEqual } from 'lodash';
import { listBuilderTitleStyle } from './listView/listBuilderUtils';
import moment from 'moment';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const fileParser = new FileParser();

const Organization = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth();
  const [selectedTab, setSelectedTab] = useState('1');
  const [producers, setProducers] = useState<
    { producerName: Name; id?: string; npn: string }[]
  >([]);
  const [filterCondition, setFilterCondition] =
    useState<filterType[]>(DEFAULT_FILTER);
  const [appliedFilters, setAppliedFilters] = useState<filterType[]>([]);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  // organization View
  const [isFetching, setIsFetching] = useState({
    orgView: false,
    listView: false,
    isSaving: false,
    tableLoader: false,
  });
  const [downlineAgency, setDownlineAgency] = useState<any>([]);
  const [searchString, setSearchString] = useState({
    producers: '',
    list: '',
  });
  const [savedName, setSavedName] = useState<{
    updatedAt: string;
    agencyName: string;
  } | null>(null);
  const [directProducers, setDirectProducers] = useState<
    { producerName: Name; id?: string; npn: string }[]
  >([]);
  const [selectedProducerLevel, setSelectedProducerLevel] = useState('');
  const [producerDetailsFetching, setProducerDetailsFetching] = useState(false);
  // list view
  const [listDetails, setListDetails] = useState<ListType[]>([]);
  const [levels, setLevels] = useState<number[]>([]);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [isUpdate, setIsUpdateId] = useState('');
  const listId = searchParams.get('id');
  const [sortType, setSortType] = useState<SortType | null>(null);
  const [saveListModal, setSaveListModal] = useState(false);

  const [appointmentDetails, setAppointmentDetails] = useState<
    ListAppointmentDTO[]
  >([]);

  const getOrganizationDetails = async (agencyId: string) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        setIsFetching((prev) => ({ ...prev, orgView: true }));
        const response: any =
          await DownlineService.getAgenciesDownlineHierarchy(
            agencyId,
            bearerToken
          );
        setDownlineAgency(response?.data);

        const directProducers = await AgentService.getAllProducersForAgency(
          bearerToken,
          50000,
          1
        );
        setDirectProducers(
          directProducers.data
            ?.map((d) => ({
              producerName: d?.producer?.name,
              id: d.producer?.id,
              npn: d?.producer?.npn,
            }))
            ?.sort((a, b) =>
              getNameString(a.producerName).localeCompare(
                getNameString(b.producerName)
              )
            ) || []
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching((prev) => ({ ...prev, orgView: false }));
      }
    }
  };

  const getAppointmentDetails = async (agencyId: string) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        const response: any =
          await AgencyService.getAppointmentDetails(bearerToken);
        const appointmentsSet = new Set();
        const agencyAppointments = response?.agencyAppointments?.filter(
          (d: ListAppointmentDTO) => {
            if (appointmentsSet.has(d.companyName)) return false;
            appointmentsSet.add(d.companyName);
            return true;
          }
        );
        const producerAppointments = response?.producerAppointments?.filter(
          (d: ListAppointmentDTO) => {
            if (appointmentsSet.has(d.companyName)) return false;
            appointmentsSet.add(d.companyName);
            return true;
          }
        );

        setAppointmentDetails([
          ...(producerAppointments || []),
          ...(agencyAppointments || []),
        ]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateLevel = (listDetails: any) => {
    const levels = [
      ...new Set(
        (listDetails?.map(
          (item: { level: number }) => item?.level
        ) as number[]) || []
      ),
    ].sort((a, b) => a - b);

    setLevels(levels);
  };

  const getOrganizationList = async (
    agencyId: string,
    listId: string | null
  ) => {
    const bearerToken = await getAccessTokenSilently();
    if (agencyId && bearerToken) {
      try {
        setIsFetching((prev) => ({ ...prev, listView: true }));
        let savedList: any;
        if (listId) {
          savedList = await AgencyService.getHiearchyListViewById(
            bearerToken,
            listId
          );

          setAppliedFilters(savedList?.hiearchyListFilters);
          const isCondition = savedList?.hiearchyListFilters?.[1]?.condition;
          setFilterCondition([
            ...(savedList?.hiearchyListFilters || []),
            { ...NEW_FILTER?.[0], condition: isCondition || '' },
          ]);
          setSearchString((prev) => ({ ...prev, list: savedList?.listName }));
          setIsUpdateId(savedList?.id);
          setSavedName({
            agencyName: savedList?.agencyName,
            updatedAt: savedList?.updatedAt,
          });
        }
        const response: any =
          await DownlineService.getAgenciesDownlineHierarchyList(
            agencyId,
            bearerToken,
            savedList?.hiearchyListFilters?.length
              ? convertFiltersToIFilterGroups(
                  savedList?.hiearchyListFilters as appliedFilterType[]
                )
              : undefined,
            getFilterType(savedList?.hiearchyListFilters || [])
          );
        setListDetails(response?.data);
        if (!savedList?.hiearchyListFilters?.length) {
          updateLevel(response?.data);
        } else {
          setLevels([1, 2, 3]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching((prev) => ({ ...prev, listView: false }));
      }
    }
  };

  useEffect(() => {
    if (!adminStore?.agency?.id) return;
    setSavedName(null);
    getOrganizationDetails(adminStore.agency?.id);
    getOrganizationList(adminStore.agency?.id, listId);
    getAppointmentDetails(adminStore?.agency?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminStore.agency?.id, listId]);

  const showProducers = async () => {
    if (!selectedProducerLevel) return;

    setSearchString((prev) => ({ ...prev, producers: '' }));
    if (selectedProducerLevel === adminStore?.agency?.id) {
      setProducers(directProducers);
      if (cancelToken) cancelPreviousRequest(cancelToken);
      setProducerDetailsFetching(false);
    } else {
      try {
        setProducerDetailsFetching(true);
        const bearerToken = await getAccessTokenSilently();
        if (cancelToken) cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const downlineProducers = await AgentService.getAllAgents(
          bearerToken,
          null,
          5000,
          1,
          cancelTokenSource,
          selectedProducerLevel
        );
        if (selectedProducerLevel === adminStore?.agency?.id) return;
        setProducers(
          downlineProducers?.data
            ?.map((d) => ({
              producerName: d?.producer?.name,
              npn: d?.producer?.npn,
            }))
            ?.sort((a, b) =>
              getNameString(a.producerName).localeCompare(
                getNameString(b.producerName)
              )
            ) || []
        );
        setProducerDetailsFetching(false);
      } catch (error) {
        console.error(error);
        if (!axios.isCancel(error)) {
          setProducerDetailsFetching(false);
        }
      }
    }
  };

  useEffect(() => {
    showProducers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducerLevel]);

  const handleTabChange = (selected: string) => {
    if (selectedTab === selected) return;
    setSelectedTab(selected);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tab', selected);
    setSearchParams(newParams);
  };

  useEffect(() => {
    const selectedTab = searchParams.get('tab');
    if (selectedTab) {
      setSelectedTab(selectedTab);
    }
  }, []);

  const getProducers = () => {
    return producers.filter((d) =>
      getNameString(d.producerName)
        ?.toLowerCase()
        ?.trim()
        ?.includes(searchString?.producers?.trim()?.toLowerCase())
    );
  };

  const onSaveList = async () => {
    try {
      const isNotValidFilter = appliedFilters?.some(
        (d) => !d.match?.length || !d.type?.length || !d?.value?.length
      );
      if (isNotValidFilter) {
        message.error(
          'Please select all the mandatory fields before saving the list'
        );
        return;
      }
      setIsFetching((prev) => ({ ...prev, isSaving: true }));
      const bearerToken = await getAccessTokenSilently();
      const hiearchyLists = await AgencyService.createHiearchyListView(
        bearerToken,
        [
          {
            listName: searchString?.list,
            hiearchyListFilters: appliedFilters?.filter(
              (d) => d.match && d.type && d.value
            ),
          },
        ],
        isUpdate
      );
      message.success(
        `${searchString?.list} is ${
          !isUpdate ? 'created' : 'updated'
        } successfully`
      );
      setSaveListModal(false);
      setSearchString((prev) => ({ ...prev, list: '' }));
      setIsUpdateId(hiearchyLists?.[0]?.id);
    } catch (error: any) {
      console.error(error);
      message?.error(
        error?.response?.data?.message ||
          `Failed to ${!isUpdate ? 'create' : 'update'} list.Please Try Again.`
      );
    } finally {
      setIsFetching((prev) => ({ ...prev, isSaving: false }));
    }
  };

  const onApply = async (isClear?: boolean) => {
    try {
      const isNotFilled = filterCondition?.some(
        (d, i) =>
          (d.type?.length && (!d.match || !d.value?.length)) ||
          (d.match && (!d.type?.length || !d.value?.length)) ||
          (d.value?.length && (!d.type?.length || !d.match))
      );

      if (isNotFilled && !isClear) {
        message.error('Please select all the filter options');
        return;
      }

      setIsPopOverOpen(false);

      if (
        isEqual(
          filterCondition.slice(0, filterCondition?.length - 1),
          appliedFilters
        ) &&
        !isClear
      ) {
        return;
      }

      setIsFetching((prev) => ({ ...prev, tableLoader: true }));

      const bearerToken = await getAccessTokenSilently();
      const filters = isClear
        ? []
        : filterCondition.filter(
            (d, i) =>
              (i === 0 &&
                Object.values(d)?.filter((d) =>
                  Array.isArray(d) ? d?.length : Boolean(d)
                )?.length === 3) ||
              Object.values(d)?.filter((d) =>
                Array.isArray(d) ? d?.length : Boolean(d)
              )?.length === 4
          );
      const response: any =
        await DownlineService.getAgenciesDownlineHierarchyList(
          adminStore?.agency?.id!,
          bearerToken,
          filters?.length
            ? convertFiltersToIFilterGroups(filters as appliedFilterType[])
            : undefined,
          getFilterType(filters || [])
        );
      setListDetails(response?.data);
      if (!filters?.length) {
        updateLevel(response?.data);
      }

      setAppliedFilters(filters);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching((prev) => ({ ...prev, tableLoader: false }));
    }
  };

  const exportList = () => {
    const exportList = [...listDetails]?.map((d) => {
      const businessAddress = getAddressLine(d?.residentAddress);
      return {
        Type: d?.type,
        Name: getNameString(d?.name),
        NPN: d?.npn,
        'Business Email': d?.businessEmail,
        'Business Phone': d?.businessPhone,
        Level: d?.level,
        'Resident License Number': d?.residentLicenseNumber,
        'Resident State Code': d?.residentStateCode,
        Upline: d?.upline,
        'Business Address': `${businessAddress.addressLine1} ${businessAddress.addressLine2}`,
      };
    });

    const formattedDate = moment().format('MM_DD_YYYY');
    const formattedTime = new Date()
      .toTimeString()
      .slice(0, 8)
      .replace(/:/g, '_');

    const fileName = `${
      searchString.list || 'list'
    }_${formattedDate}_${formattedTime}`;

    if (!sortType) {
      fileParser.exportJsonToExcel(exportList, fileName);
    } else {
      const { field, order } = sortType;
      const isAscending = order === 'ascend';
      const sortedListDetails = exportList?.sort((a, b) => {
        switch (field) {
          case 'type':
            return isAscending
              ? a?.Type?.localeCompare(b?.Type)
              : b?.Type?.localeCompare(a?.Type);

          case 'level':
            return isAscending ? a?.Level - b?.Level : b?.Level - a?.Level;

          case 'npn':
            return isAscending
              ? Number(a?.NPN) - Number(b?.NPN)
              : Number(b?.NPN) - Number(a?.NPN);

          case 'name':
            return isAscending
              ? a?.Name?.localeCompare(b?.Name)
              : b?.Name?.localeCompare(a?.Name);

          case 'businessEmail':
            return isAscending
              ? a?.['Business Email']?.localeCompare(b?.['Business Email'])
              : b?.['Business Email']?.localeCompare(a?.['Business Email']);

          case 'businessPhone':
            return isAscending
              ? a?.['Business Phone']?.localeCompare(b?.['Business Phone'])
              : b?.['Business Phone']?.localeCompare(a?.['Business Phone']);

          default:
            return 0;
        }
      });
      fileParser.exportJsonToExcel(sortedListDetails, fileName);
    }
  };

  const onFilterClear = (type: string, value: string, index: number) => {
    setAppliedFilters((prev) => {
      return prev.map((d, i) => {
        if (i !== index) return d;
        const oldValues = d?.[type as keyof filterType];
        return {
          ...d,
          [type]: Array.isArray(oldValues)
            ? oldValues?.filter((d) => d !== value)
            : null,
        };
      });
    });
    setFilterCondition((prev) => {
      return prev.map((d, i) => {
        if (i !== index) return d;
        const oldValues = d?.[type as keyof filterType];
        return {
          ...d,
          [type]: Array.isArray(oldValues)
            ? oldValues?.filter((d) => d !== value)
            : null,
        };
      });
    });
  };

  const onSaveListCancel = () => {
    if (isFetching?.isSaving) return;
    setSaveListModal(false);
  };

  return (
    <div className="width-100 hide-bottom-tab-border flex relative">
      <div style={{ flex: 1, marginRight: 15 }}>
        <Tabs
          items={organizationOptions}
          activeKey={selectedTab}
          type="line"
          style={{
            width: '100%',
          }}
          onChange={handleTabChange}
          tabBarExtraContent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: 12,
                height: 46,
              }}
            >
              {selectedTab === '1' ? (
                <>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => navigate(RouteConstants.uplines.path)}
                  >
                    Uplines
                  </Button>{' '}
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => navigate(RouteConstants.downlines.path)}
                  >
                    Downlines
                  </Button>
                </>
              ) : null}
              {selectedTab === '2' ? (
                <>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                      const newParams = new URLSearchParams(searchParams);
                      newParams.delete('id');
                      setSearchParams(newParams);

                      setIsPopOverOpen(false);
                      setSortType(null);
                      navigate(RouteConstants.agencyHierarchySavedLists.path);
                    }}
                    disabled={isFetching?.listView}
                  >
                    View Saved Lists
                  </Button>
                  <Button
                    className="figtree"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                      exportList();
                    }}
                    disabled={isFetching?.listView || !listDetails?.length}
                  >
                    Export List
                  </Button>
                  <Popover
                    content={
                      <ListFilter
                        setAppliedFilters={setAppliedFilters}
                        appliedFilters={appliedFilters}
                        levels={levels}
                        appointmentDetails={appointmentDetails}
                        filterCondition={filterCondition}
                        setFilterCondition={setFilterCondition}
                        setIsPopOverOpen={setIsPopOverOpen}
                        onApply={onApply}
                        setSearchString={setSearchString}
                      />
                    }
                    placement="bottomRight"
                    open={isPopOverOpen}
                  >
                    <Button
                      className="figtree"
                      style={{
                        background: '#E8EBEE',
                        color: '#001F45',
                        fontSize: 14,
                        lineHeight: '20px',
                        fontWeight: 400,
                        margin: '12px 0',
                      }}
                      disabled={isFetching?.listView}
                      onMouseEnter={() =>
                        isFetching?.listView ? null : setIsPopOverOpen(true)
                      }
                      onClick={() =>
                        isFetching?.listView
                          ? null
                          : setIsPopOverOpen((prev) => !prev)
                      }
                    >
                      <FilterOutlined /> Filters
                    </Button>
                  </Popover>
                </>
              ) : null}
            </div>
          }
        />

        <div style={{ marginTop: 12 }}>
          {selectedTab === '1' && (
            <OrganizationView
              {...{
                setProducers,
                downlineAgency,
                isFetching: isFetching.orgView,
                directProducers,
                selectedProducerLevel,
                setSelectedProducerLevel,
              }}
            />
          )}
          {selectedTab === '2' ? (
            isFetching.listView ? (
              <Skeleton active />
            ) : (
              <div>
                {' '}
                {!appliedFilters?.length ? null : (
                  <div
                    style={{
                      padding: '16px',
                      border: '1px solid #e6e6e6',
                      background: '#ffffff',
                      borderRadius: 6,
                      marginBottom: 16,
                    }}
                  >
                    <AppliedFilter
                      appliedFilters={appliedFilters}
                      onClick={(type, value, index) =>
                        onFilterClear(type, value, index)
                      }
                    />
                    <div
                      className="flex mt-3"
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 12,
                      }}
                    >
                      <div
                        className="flex"
                        style={{ gap: 16, alignItems: 'center' }}
                      >
                        {' '}
                        <div
                          style={{
                            fontSize: 12,
                            lineHeight: '14.4px',
                            fontWeight: 500,
                            cursor: isFetching?.isSaving
                              ? 'default'
                              : 'pointer',
                          }}
                          className={'figtree'}
                          onClick={() => {
                            if (isFetching?.isSaving) return;
                            setAppliedFilters([]);
                            setFilterCondition(NEW_FILTER);
                            setIsUpdateId('');
                            onApply(true); // clear filters
                            setSearchString((prev) => ({ ...prev, list: '' }));
                          }}
                        >
                          Cancel
                        </div>
                        <Button
                          className="figtree save-list-button"
                          style={{
                            background: 'var(--Secondary-Secondary, #001F45)',
                            color: '#ffffff',
                            fontSize: 12,
                            lineHeight: '14.4px',
                            fontWeight: 500,
                            height: 24,
                          }}
                          disabled={isFetching?.isSaving}
                          onClick={() => {
                            setSaveListModal(true);
                          }}
                          size="small"
                        >
                          Save {isUpdate ? 'Changes' : 'List'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <ListBuilder
                  isLoading={isFetching?.tableLoader}
                  {...{ listDetails }}
                  setSortType={setSortType}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
      {(producers?.length || producerDetailsFetching) && selectedTab === '1' ? (
        <>
          <div
            style={{
              minWidth: 300,
              padding: '12px',
              background: '#ffffff',
              height: 'calc(100vh - 72px)',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 0 12px',
                cursor: 'pointer',
              }}
              className="ant-modal-close-selected-producer"
            >
              <CloseOutlined
                onClick={() => {
                  setProducers([]);
                  setSelectedProducerLevel('');
                  setSearchString((prev) => ({ ...prev, producers: '' }));
                }}
                style={{ padding: 5, borderRadius: 6 }}
              />
            </div>
            {producerDetailsFetching ? (
              <Skeleton active />
            ) : (
              <>
                <Input
                  placeholder="Search"
                  value={searchString.producers}
                  onChange={(e) =>
                    setSearchString((prev) => ({
                      ...prev,
                      producers: e.target.value,
                    }))
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                    padding: '16px 0',
                    fontWeight: 400,
                  }}
                  className="figtree"
                >
                  {getProducers()?.map((d) => (
                    <div>
                      <span
                        className={'producer-list-hover'}
                        style={{
                          cursor: d?.id ? 'pointer' : 'not-allowed',
                          width: 'fit-content',
                          fontSize: 14,
                          color: '#001F45',
                          lineHeight: '20px',
                        }}
                        onClick={() => {
                          if (d?.id) {
                            navigate(
                              RouteConstants.agentOverview.path.replace(
                                ':agentId',
                                d.id
                              )
                            );
                          }
                        }}
                      >
                        {' '}
                        {getNameString(d.producerName)}
                      </span>
                      <span
                        style={{
                          display: 'block',
                          fontSize: 10,
                          color: '#848484',
                          lineHeight: '12px',
                        }}
                      >
                        {d.npn}
                      </span>
                    </div>
                  ))}{' '}
                  {getProducers().length === 0 ? (
                    <div
                      style={{
                        display: 'grid',
                        placeContent: 'center',
                        height: '475px',
                      }}
                    >
                      <EmptyState
                        image="empty data"
                        heading=""
                        width="100%"
                        content="No Producers Found"
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
      <Modal
        title="Save List"
        closable={!isFetching.isSaving}
        maskClosable={false}
        centered
        onCancel={() => {
          onSaveListCancel();
        }}
        width={400}
        open={saveListModal}
        footer={null}
      >
        <div className="flex flex-col w-full gap-4 my-6 items-center">
          <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
            {' '}
            <div
              className="figtree w-40 font-12"
              style={{
                ...listBuilderTitleStyle,
                color: '#001F45',
              }}
            >
              List Name:
            </div>{' '}
            <Input
              placeholder="Enter the List name"
              style={{ height: 24, fontSize: 12 }}
              value={searchString.list}
              onChange={(e) =>
                setSearchString((prev) => ({
                  ...prev,
                  list: e.target.value,
                }))
              }
            />
          </div>
          <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
            {' '}
            <div
              className="figtree w-40 font-12"
              style={{
                ...listBuilderTitleStyle,
                color: '#001F45',
              }}
            >
              Created by:
            </div>{' '}
            <Input
              style={{ height: 24, fontSize: 12 }}
              value={savedName?.agencyName || adminStore.agency?.name || ''}
              disabled={true}
            />
          </div>
          <div className="flex" style={{ gap: 12, alignItems: 'center' }}>
            {' '}
            <div
              className="figtree w-40 font-12"
              style={{
                ...listBuilderTitleStyle,
                color: '#001F45',
              }}
            >
              Last Updated on:
            </div>{' '}
            <Input
              style={{ height: 24, fontSize: 12 }}
              value={moment(savedName?.updatedAt || new Date()).format(
                'MM/DD/YYYY'
              )}
              disabled={true}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 items-center">
          {' '}
          <div
            style={{
              fontSize: 12,
              lineHeight: '14.4px',
              fontWeight: 500,
              cursor: isFetching?.isSaving ? 'default' : 'pointer',
            }}
            className={'figtree'}
            onClick={() => {
              onSaveListCancel();
            }}
          >
            Cancel
          </div>
          <Button
            className="figtree save-list-button"
            style={{
              background: 'var(--Secondary-Secondary, #001F45)',
              color: '#ffffff',
              fontSize: 12,
              lineHeight: '14.4px',
              fontWeight: 500,
              height: 24,
              opacity: !searchString.list ? 0.7 : 1,
            }}
            disabled={isFetching?.isSaving || !searchString.list}
            onClick={() => {
              onSaveList();
            }}
            loading={isFetching?.isSaving}
            size="small"
          >
            Save {isUpdate ? 'Changes' : 'List'}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Organization;
