import {
  Button,
  Card,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { AgencyBlacklist } from '../../../types/data/agencyBlacklist';
import { BlackListService } from '../../../services/blacklist.service';
import BlacklistModal from '../../../utils/modal/admins/add-blacklist/addBlacklist.modal';
import { ErrorMessageConstants } from '../../../constants/error-message.constants';
import FilterButton from '../../../components/filterButton';
import { IdConstants } from '../../../constants/id.constants';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { adminStore } from '../../../stores/admin.store';
import { applySort } from '../../../utils/common.utils';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { getNameString } from '../../../utils/name.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function AgencyBlackList() {
  const [activeData, setActiveData] = useState<any>('');
  const [isLicenseModalVisible, setIsLicenseModalVisible] = useState(false);
  const { bearerToken } = useAuth();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');
  const [isBlockListAdded, setIsBlockListAdded] = useState(false);
  const [agencyId, setAgencyId] = useState(adminStore.agency?.id);
  const [form] = Form.useForm();
  const [modalError, setModalError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [sortValue, setSortValue] = useState({});

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchAgents('', 1);
    }
  }, [requestBody]);

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const fetchFiltersInfoForBlackList: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      if (bearerToken) {
        const response: any = await BlackListService.getFilterInfoForBlacklists(
          bearerToken
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const [api, contextHolder] = notification.useNotification();
  const showError = (errorMessage: string) => {
    api['error']({
      message: 'Error',
      description: errorMessage,
    });
  };

  useEffect(() => {
    adminStore.agency && setAgencyId(adminStore.agency.id);
  }, [adminStore.agency]);

  useEffect(() => {
    fetchAgents();
  }, [bearerToken, adminStore.account]);

  useEffect(() => {
    if (isBlockListAdded) {
      fetchAgents();
      setIsBlockListAdded(false);
    }
  }, [isBlockListAdded]);

  useEffect(() => {
    queryText && onSearch(queryText);
  }, [queryText]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const columns: ColumnsType<AgencyBlacklist> = [
    // {
    //   title: 'Producer',
    //   dataIndex: 'name',
    //   key: 'name',
    //   sorter: false,
    //   render: (text: string) => (
    //     <Row align="middle">
    //       <div>
    //         <Typography.Paragraph
    //           style={{
    //             color: 'var(--secondary-color)',
    //             fontSize: '14px',
    //             fontWeight: 400,
    //           }}
    //         >
    //           {text}
    //         </Typography.Paragraph>
    //       </div>
    //     </Row>
    //   ),
    // },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      // sorter: false,

      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            // onClick={openModalCallback}
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },

    {
      title: 'Date Of WatchListing',
      dataIndex: 'blockedOn',
      key: 'blockedOn',
      // sorter: false,

      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',

      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
            }}
          >
            {text ? text : ' - '}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'addedBy',
      key: 'addedBy',

      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
            }}
          >
            {text ? text : ' - '}
          </Typography.Paragraph>
        </Row>
      ),
    },
  ];

  const fetchAgents = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken && agencyId) {
        setIsLoading(true);
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response = await loadAllBlackListedAgents(
          pageNumberProp || pageNumber,
          pageSizeProp || pageSize,
          bearerToken,
          agencyId,
          cancelTokenSource,
          searchTextProp,
          sort
        );
        if (response) {
          setActiveData(response.watchlistData);
          setCount(response.count);
          setIsLoading(false);
          setCancelToken(undefined);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
      console.error('Error:', error);
    }
  };

  const onFinish = async (values: any) => {
    try {
      if (bearerToken && agencyId && adminStore.account) {
        setButtonLoading(true);

        const response = await addToBlackList(
          values.npn,
          values.reason,
          bearerToken
        );
        if (response === 'SUCCESS') {
          setIsBlockListAdded(true);
          setIsLicenseModalVisible(false);
          setModalError('');
          message.success(SuccessMessageConstants.WATCHLIST_SUCCESSFUL);
          form.resetFields();
        }
      }
    } catch (error: any) {
      console.error('Error:', error.response.data.error.message);
      showError(error.response.data.message);
    } finally {
      setButtonLoading(false);
    }
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchAgents(queryText, newPageNumber, newPageSize);
  };

  const searchInBlackList = async (
    searchText: string,
    size: number,
    page: number,
    token: string,
    cancelTokenSource: CancelTokenSource
  ) => {
    const response: any = await BlackListService.searchBlackList(
      searchText,
      size,
      page,
      token,
      cancelTokenSource
    );

    let BlacklistData = response.data.data;
    BlacklistData =
      BlacklistData &&
      BlacklistData.map((agent: any) => {
        return {
          name: agent.name,
          npn: agent.npn,
          blockedOn: moment(agent.agencyInfo[0].createdAt).format(
            'MMMM DD, YYYY'
          ),
          reason: agent.agencyInfo[0].reason,
          isVisible: agent.agencyInfo[0].isVisible ? 'Yes' : 'No',
        };
      });
    return {
      count: response.data.totalCount,
      BlacklistData: BlacklistData,
    };
  };

  const loadAllBlackListedAgents = async (
    page: number,
    size: number,
    token: string,
    agencyId: string,
    cancelTokenSource: CancelTokenSource,
    searchTextProp?: string,
    sort?: object
  ) => {
    const response: any = await BlackListService.searchBlackList(
      searchTextProp ? searchTextProp : queryText || '',
      size || pageSize,
      page || pageNumber,
      token,
      cancelTokenSource
    );

    let watchlistData = response.data.data;
    watchlistData = watchlistData?.map((data: any) => {
      return {
        npn: data.npn,
        blockedOn: moment(data.createdAt).format('MMMM DD, YYYY'),
        reason: data.reason,
        addedBy: getNameString(data.addedByAccount?.name),
      };
    });

    return {
      count: response.data.totalCount,
      watchlistData: watchlistData,
    };
  };

  const addToBlackList = async (npn: string, reason: string, token: string) => {
    const response: any = await BlackListService.createBlackList(
      npn,
      reason,
      token
    );

    return response.data.status;
  };

  const onSearch = async (searchTextProp?: string) => {
    if (!agencyId) return;
    try {
      if (bearerToken) {
        setIsLoading(true);
        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response = await searchInBlackList(
          searchTextProp ? searchTextProp : queryText || '',
          pageSize,
          pageNumber,
          bearerToken,
          cancelTokenSource
        );
        setActiveData(response);

        if (response) {
          setActiveData(response.BlacklistData);
          setCount(response.count);
          setIsLoading(false);
          setCancelToken(undefined);
        } else {
          setCount(0);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
      console.error('Error:', error);
    }
  };
  const pageName = PlausiblePageConstants.AGENCY_WATCHLIST;
  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      {contextHolder}
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 10,
        }}
      >
        <Tooltip title="Add to Watchlist">
          <Button
            id={
              IdConstants.AGENCY.WATCHLIST.ADD_TO_WATCHLIST +
              '-agency-watchlist'
            }
            onClick={() => {
              setIsLicenseModalVisible(!isLicenseModalVisible);
            }}
            className="button-secondary-color-important"
          >
            Add to Watchlist
          </Button>
        </Tooltip>
      </Row>
      {/* Comment by RB */}
      {/* Commenting out the code until new BE code is integrated */}
      {/* <div
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Search
          id={IdConstants.SEARCH_INPUT + '-agency-watchlist'}
          size="middle"
          placeholder="Search by Name or NPN"
          value={searchText}
          // allowClear
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={(value) => {
            if (queryText !== value) {
              setQueryText(value);
              setPageNumber(1);
            }
          }}
          onPressEnter={(e) => {
            if (queryText !== (e.target as HTMLInputElement).value) {
              setQueryText((e.target as HTMLInputElement).value);
              setPageNumber(1);
            }
          }}
          style={{
            width: '100%',
          }}
          disabled={isLoading}
        />

        <FilterButton
          {...{
            filterInfoLoading,
            activeData,
            requestBody,
            isLoading,
            setIsFilterVisible,
            styles: {
              marginLeft: '10px',
            },
            pageName,
          }}
        />
      </div>

      <RenderFilterChip
        {...{ selectedFilters, removeFilter, clearFilters, pageName }}
      /> */}

      {/* Comment by RB End */}

      <Row className="table">
        <Table
          style={{ width: '100%' }}
          columns={columns}
          dataSource={activeData}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchAgents);
            setSortValue(sort);
          }}
          loading={isLoading}
          pagination={false}
        />
      </Row>

      {!isEmpty(activeData) && (
        <Row className="pagination" style={{ marginTop: '10px' }} justify="end">
          <Pagination
            disabled={isLoading}
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            onChange={onChange}
            current={pageNumber}
            showSizeChanger={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </Row>
      )}
      <BlacklistModal
        visible={isLicenseModalVisible}
        setVisible={setIsLicenseModalVisible}
        setIsBlockListAdded={setIsBlockListAdded}
        onFinish={onFinish}
        form={form}
        isLoading={buttonLoading}
        error={modalError}
        setError={setModalError}
      />

      {/* Comment by RB */}
      {/* Commenting out the code until new BE code is integrated */}

      {/* <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForBlackList()}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={pageName}
        filterPage={PageConstants.WATCHLIST}
      /> */}
    </Card>
  );
}
export default withRoles(AgencyBlackList, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
