import {
  AutoComplete,
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { InfoCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import axios, { CancelTokenSource } from 'axios';
import { capitalize, isEmpty, startCase } from 'lodash';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AdminService } from '../../../services/admin.service';
import { AllAdmins_ } from '../../../types/data/allAdmins';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import InviteAdminsModal from '../../../utils/modal/admins/invite-admins/inviteAdmin.modal';
import { Name } from '../../../types/data/name.type';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import RevokeAccessOrInviteModal from '../../../utils/modal/admins/revoke-admin-access-or-invite/revokeAccessOrInvite.modal';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { adminStore } from '../../../stores/admin.store';
import { applySort } from '../../../utils/common.utils';
import { auth0Store } from '../../../stores/auth0Store';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { findKeyByValue } from '../../../utils/findKeyByValue';
import { getNameString } from '../../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { tourStore } from '../../../stores/tour.store';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router-dom';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function AgencyAdmin() {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(10);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [activeData, setActiveData] = useState<any>('');
  const [isLicenseModalVisible, setIsLicenseModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [queryText, setQueryText] = useQueryState('search');

  const user = auth0Store.getUser();
  const { getAccessTokenSilently } = useAuth();
  const [revokeModalVisibility, setRevokeModalVisibility] =
    useState<boolean>(false);
  const [revokeModalDetails, setRevokeModalDetails] = useState<AllAdmins_>();

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [controlledFilters, setControlledFilters] = useState([]);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchAdmins('', 1);
    }
  }, [requestBody]);

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const fetchFiltersInfoForUsers = async () => {
    try {
      setIsFilterInfoLoading(true);
      const bearerToken = await getAccessTokenSilently();
      if (bearerToken) {
        const response: any = await AdminService.getFilterInfoForUserAdmins(
          bearerToken
        );
        setControlledFilters(response.data);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.USERS
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const adminColumns: ColumnsType<AllAdmins_> = [
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: true,
      render: (name: Name, record: AllAdmins_) => {
        const isLoggedInUser = record.email === user?.email;
        return (
          <Row align="middle">
            <Space>
              <Typography.Paragraph
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '14px',
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {getNameString(name)}
              </Typography.Paragraph>
              {isLoggedInUser && <Tag color="magenta">You</Tag>}
            </Space>
          </Row>
        );
      },
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'roleNames',
      width: 200,
      sorter: true,
      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {startCase(capitalize(findKeyByValue(RoleType, text)))}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 200,
      render: (text: string) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{
              fontSize: '14px',
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      width: 200,
      render: (text: string[], record: AllAdmins_) => {
        return (
          <Row
            align="middle"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {record.email !== user?.email && (
              <Tooltip title="Revoke access">
                <Button
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  className="button important-action-button"
                  onClick={() => {
                    setRevokeModalVisibility(true);
                    setRevokeModalDetails(record);
                  }}
                >
                  <UserDeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    },
  ];

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchAdmins(queryText || '', newPageNumber, newPageSize);
  };

  const handleInputChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const fetchAdmins = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    try {
      setIsLoading(true);
      cancelToken && cancelPreviousRequest(cancelToken);
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const bearerToken = await getAccessTokenSilently();
      const response = await adminStore.loadAllAdminState(
        searchTextProp ? searchTextProp : queryText || '',
        searchType ? searchType : '',
        pageNumberProp || pageNumber,
        pageSizeProp || pageSize,
        bearerToken,
        { ...requestBody, ...(sort || sortValue || {}) },
        cancelTokenSource
      );

      if (response) {
        setCount(response.count);
        setActiveData(response.admins);
        setCancelToken(undefined);
        setIsLoading(false);
      } else {
        setCount(0);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setCancelToken(undefined);
      }
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchFiltersInfoForUsers();
  }, []);

  useEffect(() => {
    fetchAdmins();
  }, [queryText, searchType]);

  useEffect(() => {
    if (adminStore.isAccessRevoked) {
      fetchAdmins();
      adminStore.setIsAccessRevoked(false);
    }
  }, [adminStore.isAccessRevoked]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const onModalClose = () => {
    setIsLicenseModalVisible(false);
    if (tourStore.inviteUsersTour) {
      tourStore.setInviteUsersTourPhase(2);
      tourStore.setInviteUsersTourVisible(true);
      tourStore.setInviteUsersTourCurrentStep(0);
    }
  };

  const onRevokeModalClose = () => {
    setRevokeModalVisibility(false);
    fetchAdmins();
  };

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_SETTINGS_USERS;

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);
  return (
    <div
      style={{
        display: 'block',
        width: '100%',
        height: 'fit-content',
      }}
      className="table-layout-v2 relative"
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <Row
          justify="space-between"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: isLoading ? 'not-allowed' : '',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <Typography.Text
            type="secondary"
            className="reset-icon-size"
            style={{ fontSize: '14px', marginBottom: 0 }}
          >
            <Space>
              <InfoCircleOutlined />
              View and manage users and their accounts.
            </Space>
          </Typography.Text>
          <Space style={{ display: 'flex', gap: 16 }}>
            <Button
              id={IdConstants.AGENCY.USERS.INVITE_USERS + '-agency-users'}
              style={{
                background: 'var(--hint-primary-color)',
                color: 'white',
              }}
              onClick={() => {
                setIsLicenseModalVisible(!isLicenseModalVisible);
              }}
            >
              Invite Users
            </Button>
            <Button
              id={
                IdConstants.AGENCY.USERS.VIEW_INVITATION_LINK + '-agency-users'
              }
              onClick={() => navigate(RouteConstants.invite_admins.path)}
            >
              View Invitations
            </Button>
          </Space>
        </Row>
        {/* Comment by RB */}
        {/* Commenting out the code until new BE code is integrated */}
        <div
          style={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '10px',
          }}
        >
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-agency-users'}
              placeholder="Search by User or Email"
              style={{
                width: '100%',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters, pageName }}
        />
        {/* Comment by RB End */}

        <Row className="table">
          <Table
            columns={adminColumns}
            dataSource={activeData}
            rowSelection={undefined}
            scroll={{ y: 'calc(100vh - 280px)' }}
            loading={isLoading}
            pagination={false}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchAdmins);
              setSortValue(sort);
            }}
            rowKey={'id'}
          />{' '}
        </Row>
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
      </ConfigProvider>
      <InviteAdminsModal
        visible={isLicenseModalVisible}
        setVisible={setIsLicenseModalVisible}
        onClose={onModalClose}
      />
      <RevokeAccessOrInviteModal
        visible={revokeModalVisibility}
        setVisible={onRevokeModalClose}
        refetchFilter={() => {
          fetchFiltersInfoForUsers();
        }}
        adminDetails={revokeModalDetails}
        revokeType={'access'}
      />

      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        controlledFilters={controlledFilters}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={pageName}
        filterPage={PageConstants.USERS}
      />
    </div>
  );
}
export default withRoles(observer(AgencyAdmin), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
