import {
  ActiveLicense,
  IndividualOnboardAssignmentProps,
  StateLicenses,
} from './individualOnboardV2Type.type';
import ProducerStatDetails, {
  statDetails,
} from '../../manageAssignments/ProducerStatDetails';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../../utils/common.utils';
import { useEffect, useState } from 'react';

import { AgentService } from '../../../../services/agent.service';
import ProducerAssignments from '../../manageAssignments/producerAssignments';
import { ResidencyType } from '../../../../enums/residencyType.enum';
import { StateDetails } from '../../../../types/data/masterData';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { groupBy } from 'lodash';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../auth/authProvider';

const IndividualOnboardAssignments: React.FC<IndividualOnboardAssignmentProps> =
  observer(
    ({
      producerDetails,
      selectedItems,
      stateGroups,
      setSelectedItems,
      setStateGroups,
      isReview = false,
      selectedTerritory,
      setSelectedTerritory,
      selectedState,
      setSelectedState,
      selectedTab,
      setSelectedTab,
      setDisableSave,
    }) => {
      const { getAccessTokenSilently } = useAuth();
      const [loading, setLoading] = useState(false);
      const [masterData, setMasterData] = useState<StateDetails[]>([]);
      const [statDetails, setStatDetails] = useState<statDetails>({
        totalStates: 51,
        activeStates: 0,
        statesSelected: 0,
        territoriesSelected: 0,
        totalTerriotories: 0,
        totalEstimatedCost: 0,
      });

      // fee calculation
      useEffect(() => {
        let fees = 0;
        const stateCodes: string[] = [];
        // territory fee
        stateGroups
          ?.filter((d) => d.isSelected)
          ?.forEach((d) => {
            d?.stateLicenseConfigs?.forEach((data) => {
              data?.licenseConfigs?.forEach((d) => {
                const activeDetails =
                  individualOnboardStore.producerActiveLicenses?.filter(
                    (d1) => d1.stateCode === data.stateCode
                  )?.[0];
                if (
                  (data.stateCode &&
                    stateCodes.includes(`${data.stateCode}${d.lcCode}`)) ||
                  activeDetails?.licenseClassCode === d.lcCode
                )
                  return;
                if (data.stateCode)
                  stateCodes.push(`${data.stateCode}${d.lcCode}`);

                const homeState = producerDetails?.residentState;
                const isDisabled =
                  (!homeState?.includes(data.stateCode) &&
                    d?.loaConfigs?.[0]?.residencyType ===
                      ResidencyType.Resident) ||
                  (homeState?.includes(data.stateCode) &&
                    d?.loaConfigs?.[0]?.residencyType ===
                      ResidencyType.NonResident)
                    ? true
                    : null;
                if (isDisabled) return;

                fees +=
                  appStateInfoStore.calculateFeesForProducerLicenses(
                    data.stateCode,
                    activeDetails?.licenseClassCode === d.lcCode
                    // d.residencyType
                  ) || 0;
              });
            });
          });

        // state fee
        selectedItems?.forEach((d) => {
          const stateCode = getStateCodeFromName(d?.name);
          Object.entries(d)?.forEach(([k, v]) => {
            if (k === 'name' || typeof v === 'string') return;
            const activeDetails =
              individualOnboardStore.producerActiveLicenses?.filter(
                (d1) => d1.stateCode === stateCode
              )?.[0];
            if (
              (stateCode && stateCodes.includes(`${stateCode}${d.lcCode}`)) ||
              (stateCode && activeDetails?.licenseClassCode === k)
            )
              return;
            if (stateCode) stateCodes.push(`${d.stateCode}${k}`);

            fees +=
              appStateInfoStore.calculateFeesForProducerLicenses(
                stateCode!,
                activeDetails?.licenseClassCode === k
                // ResidencyType.NotApplicable
              ) || 0;
          });
        });

        setStatDetails((prev) => ({ ...prev, totalEstimatedCost: fees }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        selectedItems,
        stateGroups,
        individualOnboardStore.producerActiveLicenses,
      ]);

      const handleIndividualAssignStates = async (
        activeLicenses: ActiveLicense[]
      ) => {
        try {
          setLoading(true);
          const token = await getAccessTokenSilently();
          const response: any =
            await AgentService.getLicenseConfigurationByProducerId(
              producerDetails.id!,
              token
            );
          const territoryIds =
            response?.data?.map((d: any) => d?.territoryId) || [];
          setStateGroups((prev) => {
            return prev.map((d) =>
              territoryIds?.includes(d.id) ? { ...d, isSelected: true } : d
            );
          });
          const selectedStates =
            response?.data
              ?.map((d: any) => {
                return !d?.territoryId && d;
              })
              ?.filter(Boolean) || [];
          const selectedItems: StateLicenses[] = [];
          selectedStates.forEach((d: any) => {
            const stateDetails: any = {};
            const stateName = getStateNameFromCode(d?.stateCode || '');
            if (stateName) {
              stateDetails['name'] = stateName;
              const loas = groupBy(
                d?.licenseConfigurations,
                'licenseClassCode'
              );
              Object.entries(loas).forEach(([k, v]) => {
                stateDetails[k] = {
                  name: v?.[0]?.licenseClass,
                  loas:
                    v?.map((d) => ({
                      id: d?.id,
                      loaCode: d?.loaCode,
                      name: d?.loa,
                    })) || [],
                };
              });
              selectedItems.push(stateDetails);
            }
          });
          setSelectedItems(selectedItems as StateLicenses[]);

          const masterData = [
            ...appStateInfoStore.getLicenseConfigsMasterData(),
          ];

          masterData.sort((a, b) => {
            const nameA = getStateNameFromCode(a.stateCode);
            const nameB = getStateNameFromCode(b.stateCode);
            return nameA.localeCompare(nameB);
          });

          const updatedMasterData = masterData.map((d) => {
            const licenses = d?.licenseClasses?.map((lc) => {
              const activeLicense = activeLicenses.find(
                (al) =>
                  al.stateCode === d.stateCode &&
                  lc.lcCode === al?.licenseClassCode
              );
              const updatedConfigs: string[] = [];
              const activeLoas: string[] = [];
              lc.loaData?.forEach((d) => {
                const activeLoa = activeLicense?.loaDetails?.find(
                  (loa) => loa.loaCode === d?.loaCode
                );
                if (activeLoa) {
                  activeLoas.push(activeLoa.loaCode);
                }
                if (activeLoa && d?.referenceLicenseConfig) {
                  updatedConfigs.push(d?.referenceLicenseConfig);
                }
              });
              const loas = lc.loaData?.filter((d) => {
                if (
                  d?.referenceLicenseConfig &&
                  updatedConfigs.includes(d?.id!)
                )
                  return false;
                return activeLoas.includes(d.loaCode) || !d.isRetired;
              });
              return {
                ...lc,
                loaData: loas,
              };
            });
            return {
              ...d,
              licenseClasses: licenses,
            };
          });

          setMasterData(updatedMasterData);
        } catch (error) {
          console.error(error);
          message.error('Failed to get producer assignments.Please Try Again');
        } finally {
          setLoading(false);
        }
      };

      return (
        <>
          <ProducerStatDetails
            producerDetails={producerDetails!}
            producerStatDetails={statDetails}
          />
          <ProducerAssignments
            {...{
              selectedItems,
              selectedState,
              selectedTab,
              selectedTerritory,
              setSelectedTerritory,
              setSelectedItems,
              setSelectedState,
              setSelectedTab,
              stateGroups,
              setStateGroups,
              setStatDetails,
              handleIndividualAssignStates,
              isLoading: loading,
              masterData,
              statDetails,
              isReview,
              setDisableSave,
            }}
            producerDetails={producerDetails!}
            producerId={producerDetails.id!}
          />
        </>
      );
    }
  );

export default IndividualOnboardAssignments;
