import { Checkbox, Tooltip, Typography } from 'antd';

import AssignmentLoa from './assignmentLoa';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LicenseClassProps } from './individualOnboardV2Type.type';
import React from 'react';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { getDisplayFees } from '../../../../utils/common.utils';
import { observer } from 'mobx-react-lite';

const { Text } = Typography;

const AssignmentLicenseClass: React.FC<LicenseClassProps> = ({
  text,
  licenseClassCode,
  isCheckboxNeeded = true,
  selectedIds,
  LoaDetails,
  handleCheckboxChange,
  stateCode,
  disableCheckBox = false,
  isLicenseChecked,
  activeLicense,
  tooltipText,
}) => {
  const handleClassCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(licenseClassCode, text, null, e.target.checked);
  };

  const getFees = () => {
    return (
      appStateInfoStore.calculateFeesForProducerLicenses(
        stateCode,
        activeLicense?.status === 'active'
      ) || null
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        borderRadius: '6px',
        padding: '16px',
        gap: '15px',
        backgroundColor: '#F1F8FE',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          className="override-checkbox-bg"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Tooltip title={tooltipText}>
            {isCheckboxNeeded && (
              <Checkbox
                checked={isLicenseChecked}
                style={{ marginRight: '20px' }}
                onChange={handleClassCheckboxChange}
                disabled={disableCheckBox}
              />
            )}
            <Text
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '20px',
                color: 'rgba(34, 34, 34, 1)',
                margin: 0,
              }}
            >
              {text}
            </Text>
          </Tooltip>
          <div
            className="override-icon-size"
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              color: '#00A6FB',
              margin: 0,
              marginLeft: '12px',
            }}
          >
            {getFees() && !activeLicense ? (
              <div>
                ( Estimated fees:{' '}
                <span style={{ fontWeight: 500 }}>
                  ${getDisplayFees(getFees() || 0)}
                </span>{' '}
                )
              </div>
            ) : null}{' '}
            {/* {getFees()?.isRetaliatory ? (
              <Tooltip
                title={
                  <div>
                    We are currently building fee calculations for retaliatory
                    states. For more information, please refer{' '}
                    <a target="__blank" href={getFees()?.retaliatoryLink}>
                      {getFees()?.retaliatoryLink}
                    </a>
                  </div>
                }
              >
                <QuestionCircleOutlined
                  style={{
                    cursor: 'pointer',
                    color: '#97AABF',
                  }}
                />
              </Tooltip>
            ) : null} */}
          </div>
        </div>
        {activeLicense ? (
          <div
            className="sub-title-custom"
            style={{
              color: '#222222',
              width: 54,
              height: 16,
              borderRadius: 16,
              textTransform: 'capitalize',
              background: '#98F5C880',
              border: '1px solid #31EB91',
              marginLeft: 'auto',
            }}
          >
            {' '}
            Active
          </div>
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          gap: '15px',
          marginLeft: isCheckboxNeeded ? '40px' : '0',
          flexWrap: 'wrap',
        }}
      >
        {LoaDetails?.map((loa, index) => {
          let activeLoa = false;
          if (activeLicense) {
            const foundLoa = activeLicense.loaDetails.some(
              (loaD) => loaD.loaCode === loa.loaCode
            );
            activeLoa = foundLoa;
          }
          return (
            <AssignmentLoa
              key={index}
              disableCheckBox={disableCheckBox}
              text={(loa.loa || loa.loaName) + ' (' + loa.loaCode + ')'}
              loaId={loa.loaCode || ''}
              loaDbId={loa.id || ''}
              licenseClassCode={licenseClassCode}
              selectedIds={selectedIds}
              handleCheckboxChange={handleCheckboxChange}
              isCheckboxNeeded={isCheckboxNeeded}
              activeLoa={activeLoa}
              tooltipText={tooltipText}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(AssignmentLicenseClass);
