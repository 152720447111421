import { Button, Col, Row, Tooltip, message, notification } from 'antd';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { ActionableLicenseResponseError } from '../../../types/response/actionable-license.type';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../services/licenses.service';
import { PayeeEnum } from '../../../enums/payee.enum';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import { useAuth } from '../../../auth/authProvider';

export enum Tab {
  INACTIVE_LICENSES = 'inactive',
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}
interface ButtonCellProps {
  selectedLicensesLength: number;
  record: any;
  currentTab: string;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  deleteAppliedLicense: (id: string) => void;
  disableApplyButton: boolean;
  setDisableApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
  showError: (msg: { message: string; description?: string }) => void;
  setApplyLicenseErrorModalVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setErrors?: React.Dispatch<
    React.SetStateAction<ActionableLicenseResponseError[]>
  >;
  showEngineLights?: boolean;
  applyErrorCallback?: (prop: string) => void;
  payer?: string;
  isRenewalPage?: boolean;
  isV2?: boolean; // to be deleted later
  isNeedsAttentionPage?: boolean;
  onDeclinedClick?: () => void;
}
export function IndividualApplyButton({
  record,
  currentTab,
  deleteAppliedLicense,
  disableApplyButton,
  setDisableApplyButton,
  showError,
  setApplyLicenseErrorModalVisibility,
  setErrors,
  payer,
  applyErrorCallback,
  isV2,
  isNeedsAttentionPage,
  onDeclinedClick,
}: ButtonCellProps) {
  const [pending, setPending] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const [disableCurrentApplyButton, setDisableCurrentApplyButton] =
    useState(false);

  const onClick = async () => {
    await applyOneLicense(
      !isEmpty(record.latestApplication) ? record.latestApplication : record
    );
  };

  const getToolTip = () => {
    if (record.status === LicenseStatus.MISSING_LOA) {
      if (['WA'].includes(record?.stateCode)) {
        return "Washington doesn't allow amendments";
      }
      if (record?.withinRenewalPeriod) {
        return "License is within renewal period. Renew the license before applying for new LOA's";
      }
    }

    if (!record.producerDetails?.engineLights?.paymentConfig) {
      return 'Payer not configured';
    }
    if (!record.producerDetails?.engineLights?.employmentHistory) {
      return 'Producer missing 5-year consecutive or latest employment history';
    }
    if (!backgroundQuestionStatus) {
      return 'Producer background questions are not answered';
    }
    if (!record.producerDetails?.engineLights?.isResidentLicenseActive) {
      return 'Resident license is missing';
    }

    return '';
  };

  useEffect(() => {
    if (record.status === LicenseStatus.MISSING_LOA) {
      if (['WA'].includes(record.stateCode) || record.withinRenewalPeriod) {
        setDisableCurrentApplyButton(true);
      }
    }
  }, []);
  // const applyOneRenewal = async (license: LicenseApplicationResponse) => {
  //   try {
  //     setDisableApplyButton(true);
  //     setPending(true);
  //     if (license?.status === ApplicationStatusEnum.Applied) {
  //       return;
  //     } else {
  //       const token = await getAccessTokenSilently();
  //       const resp: any = await LicensesService.applyRenewalLicense(
  //         license.id,
  //         token
  //       ).catch((e: ErrorResponse) => {
  //         if (isErrorStripeCustomerMissing(e)) {
  //           api['warning']({
  //             message: 'License Application Submission Done',
  //             description: ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING,
  //           });
  //         } else if (isErrorNoPaymentMethodsFound(e)) {
  //           showError({
  //             message: 'License Application Failed',
  //             description: ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND,
  //           });
  //         } else if (isErrorStripeOnboardingRequired(e)) {
  //           showError({
  //             message: 'License Application Failed',
  //             description:
  //               'Seems like you do not have a payment method configured',
  //           });
  //         } else if (
  //           e?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION
  //         ) {
  //           showError({
  //             message: 'License Application Failed',
  //             description: e.error?.message,
  //           });
  //         } else if (
  //           e.error.exceptionName ===
  //           ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
  //         ) {
  //           const error = e.error.data as GatewayTransactionApplyError;
  //           const lcRespMessages = error.lcRespMessages
  //             .filter((itr) => !!itr)
  //             .map((itr) => itr.comment);

  //           const loaRespMessages = error.loaRespMessages
  //             .filter((itr) => !!itr)
  //             .flatMap((itr) =>
  //               itr.approved !== 'Y'
  //                 ? itr.respMessages.map((msg) => msg.comment)
  //                 : []
  //             );
  //           const mainErrorMessages = error.mainErrorMessages
  //             .filter((itr) => !!itr)
  //             .flatMap((itr) => itr.description);
  //           const intermediateMessages = error.intermediateMessages
  //             .filter((itr) => !!itr)
  //             .flatMap((itr) => {
  //               return { actionable: itr.actionRequired, message: itr.comment };
  //             });

  //           const lcErrorDetails = { errorMessages: lcRespMessages };
  //           const loaErrorDetails = { errorMessages: loaRespMessages };
  //           const mainErrorDetails = { errorMessages: mainErrorMessages };
  //           const intermediateErrorDetails = {
  //             errorMessages: intermediateMessages,
  //           };

  //           setErrors &&
  //             setErrors([
  //               {
  //                 LcErrorDetails: lcErrorDetails,
  //                 LoaErrorDetails: loaErrorDetails,
  //                 mainErrorDetails: mainErrorDetails,
  //                 intermediateErrorDetails: intermediateErrorDetails,
  //               },
  //             ]);
  //           setApplyLicenseErrorModalVisibility &&
  //             setApplyLicenseErrorModalVisibility(true);
  //         }
  //       });

  //       if (resp?.status === 'SUCCESS') {
  //         if (
  //           resp?.data?.niprGatewayTransactionResponseDto?.transaction?.license
  //             ?.intResponse?.respMessages
  //         ) {
  //           let intermediateErrorDetails: {
  //             errorMessages: { actionable: string; message: string }[];
  //           } = { errorMessages: [] };
  //           const intermediateMessages =
  //             resp?.data?.niprGatewayTransactionResponseDto?.transaction?.license?.intResponse?.respMessages
  //               .filter((itr: any) => !!itr)
  //               .flatMap((itr: any) => {
  //                 return {
  //                   actionable: itr.actionRequired,
  //                   message: itr.comment,
  //                 };
  //               });
  //           intermediateErrorDetails.errorMessages = intermediateMessages;
  //           if (
  //             intermediateErrorDetails &&
  //             !isEmpty(intermediateErrorDetails.errorMessages)
  //           ) {
  //             setErrors &&
  //               setErrors([
  //                 {
  //                   LcErrorDetails: { errorMessages: [] },
  //                   LoaErrorDetails: { errorMessages: [] },
  //                   mainErrorDetails: { errorMessages: [] },
  //                   intermediateErrorDetails: intermediateErrorDetails,
  //                 },
  //               ]);
  //             setApplyLicenseErrorModalVisibility &&
  //               setApplyLicenseErrorModalVisibility(true);
  //           }
  //         }
  //         setDisableApplyButton(false);
  //         deleteAppliedLicense(license?.id);
  //       }
  //     }
  //     setPending(false);
  //     setDisableApplyButton(false);
  //     // setRefreshData(true);
  //   } catch (error) {
  //     setPending(false);
  //     setDisableApplyButton(false);
  //     showError({ message: 'Failed To Apply License' });
  //     console.error('error message :: ', error);
  //     applyErrorCallback && applyErrorCallback(record?.license?.id);
  //   }
  // };

  const applyOneLicense = async (license: LicenseApplicationResponse) => {
    try {
      setDisableApplyButton(true);
      setPending(true);

      if (
        license?.latestApplication?.status === ApplicationStatusEnum.PENDING
      ) {
        return;
      } else {
        const token = await getAccessTokenSilently();
        const resp: any = await LicensesService.applyLicenses(
          license?.id,
          token,
          license.status === ApplicationStatusEnum.MISSING_LOA
        );
        //   .catch((e: any) => {
        //   if (isErrorStripeCustomerMissing(e)) {
        //     showError({
        //       message: 'License Application Submission Done',
        //       description: ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING,
        //     });
        //   } else if (isErrorMissingLoaApplication(e)) {
        //     showError({
        //       message:
        //         'Applications of missing LOAs are currently not supported',
        //     });
        //   } else if (isErrorNoPaymentMethodsFound(e)) {
        //     showError({
        //       message: 'License Application Failed',
        //       description: ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND,
        //     });
        //   } else if (isErrorStripeOnboardingRequired(e)) {
        //     showError({
        //       message: 'License Application Failed',
        //       description:
        //         'Seems like you do not have a payment method configured',
        //     });
        //   } else if (
        //     e?.error?.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION
        //   ) {
        //     showError({
        //       message: 'License Application Failed',
        //       description: e.error?.message,
        //     });
        //   } else if (
        //     e?.error?.exceptionName ===
        //     ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
        //   ) {
        //     const error = e.error.data as GatewayTransactionApplyError;
        //     const lcRespMessages = error.lcRespMessages
        //       .filter((itr) => !!itr)
        //       .map((itr) => itr.comment);

        //     const loaRespMessages = error.loaRespMessages
        //       .filter((itr) => !!itr)
        //       .flatMap((itr) =>
        //         itr.approved !== 'Y'
        //           ? itr.respMessages.map((msg) => msg.comment)
        //           : []
        //       );
        //     const mainErrorMessages = error.mainErrorMessages // todo
        //       .filter((itr) => !!itr)
        //       .flatMap((itr) => itr.description);
        //     const intermediateMessages = error.intermediateMessages
        //       .filter((itr) => !!itr)
        //       .flatMap((itr) => {
        //         return { actionable: itr.actionRequired, message: itr.comment };
        //       });

        //     const lcErrorDetails = { errorMessages: lcRespMessages };
        //     const loaErrorDetails = { errorMessages: loaRespMessages };
        //     const mainErrorDetails = { errorMessages: mainErrorMessages };
        //     const intermediateErrorDetails = {
        //       errorMessages: intermediateMessages,
        //     };

        //     setErrors &&
        //       setErrors([
        //         {
        //           LcErrorDetails: lcErrorDetails,
        //           LoaErrorDetails: loaErrorDetails,
        //           mainErrorDetails: mainErrorDetails,
        //           intermediateErrorDetails: intermediateErrorDetails,
        //         },
        //       ]);
        //     setApplyLicenseErrorModalVisibility &&
        //       setApplyLicenseErrorModalVisibility(true);
        //   } else {
        //     showError({
        //       message:
        //         e?.error?.message ||
        //         e?.error?.response?.message ||
        //         e?.error?.response?.message ||
        //         'Failed to apply license.please try again',
        //     });
        //   }
        //   return;
        // });

        if (resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse) {
          const error =
            resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse?.license;
          const lcRespMessages = Array.isArray(error?.response)
            ? error?.response
                ?.filter((itr: any) => !!itr && itr?.approved !== 'Y')
                ?.flatMap((itr: any) => itr?.messages)
                ?.map((d: any) => d?.comments) || []
            : error?.response?.approved !== 'Y'
              ? error?.response?.map((msg: any) => msg?.comments) || []
              : [];

          const loaRespMessages = Array.isArray(error?.loaCodes)
            ? error?.loaCodes
                ?.flatMap((d: any) => d?.response)
                ?.filter((itr: any) => !!itr)
                ?.flatMap((itr: any) =>
                  itr?.approved !== 'Y' ? itr?.messages : []
                )
                ?.map((d: any) => d?.comments) || []
            : error?.loaCodes?.approved !== 'Y'
              ? error?.loaCodes?.response
                  ?.filter((itr: any) => !!itr)
                  ?.flatMap((itr: any) =>
                    itr?.approved !== 'Y' ? itr?.messages : []
                  )
                  ?.map((d: any) => d?.comments) || []
              : [];

          const intermediateMessages = Array.isArray(error?.intResponse)
            ? error?.intResponse
                ?.filter((itr: any) => !!itr)
                ?.flatMap((d: any) => d?.messages)
                ?.map((itr: any) => {
                  return {
                    actionable: itr.actionRequired,
                    message: itr.comments,
                  };
                })
            : error?.intResponse?.messages
              ? error?.intResponse?.messages?.map((itr: any) => {
                  return {
                    actionable: itr.actionRequired,
                    message: itr.comments,
                  };
                })
              : [];

          const lcErrorDetails = { errorMessages: lcRespMessages };
          const loaErrorDetails = { errorMessages: loaRespMessages };
          const mainErrorDetails = { errorMessages: [] };
          const intermediateErrorDetails = {
            errorMessages: intermediateMessages,
          };

          setErrors &&
            setErrors([
              {
                LcErrorDetails: lcErrorDetails,
                LoaErrorDetails: loaErrorDetails,
                mainErrorDetails: mainErrorDetails,
                intermediateErrorDetails: intermediateErrorDetails,
              },
            ]);
          setApplyLicenseErrorModalVisibility &&
            setApplyLicenseErrorModalVisibility(true);
        } else if (resp?.data?.gatewayTransaction?.niprGatewayErrorResponse) {
          const errors =
            resp?.data?.gatewayTransaction?.niprGatewayErrorResponse;
          setErrors &&
            setErrors([
              {
                LcErrorDetails: {
                  errorMessages: [],
                },
                LoaErrorDetails: {
                  errorMessages: [],
                },
                mainErrorDetails: {
                  errorMessages: errors?.map((d: any) => d.description) || [],
                },
                intermediateErrorDetails: {
                  errorMessages: [],
                },
              },
            ]);
          setApplyLicenseErrorModalVisibility &&
            setApplyLicenseErrorModalVisibility(true);
        }
        setDisableApplyButton(false);
        if (resp?.data?.status === 'pending') deleteAppliedLicense(license?.id); //todo
      }
      setPending(false);
      setDisableApplyButton(false);
      // setRefreshData(true);
    } catch (error: any) {
      console.error(error);
      setPending(false);
      setDisableApplyButton(false);
      showError({
        message: error?.response?.data?.message || 'Failed To Apply License',
      });
      console.error('error message :: ', error);
      if (record?.license?.id && applyErrorCallback) {
        applyErrorCallback(record?.license?.id);
      }
    }
  };

  // const paymentConfig =
  //   payer !== 'Agency' ||
  //   !(
  //     record?.producerDetails?.paymentConfig?.paymentType ===
  //       PaymentTypeEnum.CAP ||
  //     record?.producerDetails?.paymentConfig?.paymentType ===
  //       PaymentTypeEnum.FULL
  //   );

  const backgroundQuestion =
    record?.producerDetails?.engineLights?.backgroundQuestion;

  let backgroundQuestionStatus = true;

  if (isArray(backgroundQuestion)) {
    backgroundQuestion.forEach((question) => {
      if (question.stateCode === 'ALL' && backgroundQuestionStatus) {
        backgroundQuestionStatus = question.isAllAnswered;
      }
      if (question.stateCode === record.stateCode && backgroundQuestionStatus) {
        backgroundQuestionStatus = question.isAllAnswered;
      }
    });
  }

  const isAllConfigured =
    !backgroundQuestionStatus ||
    !record.producerDetails?.engineLights?.employmentHistory ||
    !record.producerDetails?.engineLights?.paymentConfig ||
    !record.producerDetails?.engineLights?.isResidentLicenseActive;

  const isDisabled =
    disableApplyButton || disableCurrentApplyButton || isAllConfigured;

  if (isAllConfigured && !isV2) return null;

  return (
    <>
      {/* DND */}
      {contextHolder}
      {/* DND End */}

      <Col
        style={{
          alignItems: 'center',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
          margin: '8px 0 0',
        }}
        className="individual-apply-button"
      >
        <Row align="middle" className="grid place-content-center text-center">
          {record.producerDetails?.paymentConfig?.isAutoRenewalActive &&
          !isNeedsAttentionPage ? (
            <div className="flex gap-2 mb-[2px]">
              <Tooltip
                title="License will be applied automatically 45
                days before expiration"
              >
                <InfoCircleOutlined />
              </Tooltip>
              <div className="no-wrap font-figtree text-xs">Auto Applies</div>
            </div>
          ) : null}
          {record?.latestApplicationStatus?.toLocaleLowerCase() !==
            ApplicationStatusEnum.PENDING && (
            <Tooltip title={getToolTip()}>
              <Button
                id={'apply-individual-button-' + record.licenseNumber}
                disabled={isDisabled}
                style={{
                  background: isDisabled ? '#001836' : '#001F45',
                  opacity: isDisabled ? 0.5 : 1,
                  color: '#ffffff',
                }}
                loading={pending}
                className="font-12 figtree text-white flex justify-center items-center h-[22px] leading-[22px] font-medium w-[100px]"
                onClick={() => {
                  onClick();
                }}
              >
                {record?.latestApplicationStatus ===
                ApplicationStatusEnum.Declined
                  ? 'Retry'
                  : record?.status === ApplicationStatusEnum.MISSING_LOA
                    ? 'Amend'
                    : 'Apply'}
              </Button>
              {record?.latestApplicationStatus ===
                ApplicationStatusEnum.Declined && isV2 ? (
                <div
                  style={{
                    fontWeight: 400,
                    color: '#EB3131',
                  }}
                  className="font-12 figtree cursor-pointer "
                  onClick={onDeclinedClick}
                >
                  <QuestionCircleOutlined />{' '}
                  <span className="mt-[2px] inline-block  underline">
                    Learn More
                  </span>
                </div>
              ) : null}
            </Tooltip>
          )}
        </Row>
      </Col>
    </>
  );
}
