import { Button, Row, Tooltip, message } from 'antd';

import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import { LicensesService } from '../../../services/licenses.service';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { useAuth } from '../../../auth/authProvider';
import { useState } from 'react';

interface ButtonCellProps {
  selectedLicensesLength: number;
  record: LicenseApplicationResponse;
  deleteAppliedLicense: (id: string) => void;
  payer: string;
  disableApplyButton: boolean;
  setDisableApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
  applyErrorCallback?: (prop: string) => void;
}
export function IndividualApplyButton({
  selectedLicensesLength,
  record,
  payer,
  deleteAppliedLicense,
  disableApplyButton,
  setDisableApplyButton,
  applyErrorCallback,
}: ButtonCellProps) {
  const [pending, setPending] = useState(false);
  const { getAccessTokenSilently } = useAuth();

  const applyOneLicense = async (license: LicenseApplicationResponse) => {
    try {
      setPending(true);

      const token = await getAccessTokenSilently();
      const resp: any = await LicensesService.applyLicenses(license.id, token);

      if (resp.status === 'SUCCESS') {
        setDisableApplyButton(false);
        deleteAppliedLicense(license.id);
        message.success(SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS);
      }

      setPending(false);
    } catch (error) {
      setPending(false);
      setDisableApplyButton(false);
      message.error('Failed To Apply License');
      console.error('error :: ', error);
      applyErrorCallback && applyErrorCallback(record.license.id);
    }
  };

  if (pending) {
    return <Button style={{ width: '100px' }} loading={pending}></Button>;
  }

  const paymentConfig = payer !== 'Agent';

  const isAllConfigured =
    !record?.agent?.backgroundQuestionsStatus ||
    !record.agent?.employmentHistoryStatus ||
    paymentConfig;

  if (isAllConfigured) return null;

  if (selectedLicensesLength < 2) {
    return (
      <Row align="middle" style={{ textAlign: 'center' }}>
        {selectedLicensesLength < 2 ? (
          record.agent?.autoRenewEnabled ? (
            <div style={{ display: 'flex', gap: 8 }}>
              <Tooltip
                title="License will be applied automatically 45
                  days before expiration"
              >
                <InfoCircleOutlined />
              </Tooltip>
              <p className="no-wrap">Auto Applies</p>
            </div>
          ) : (
            record?.status &&
            (record?.status?.toLocaleLowerCase() ===
              ApplicationStatusEnum.Declined ||
              record?.status?.toLocaleLowerCase() ===
                ApplicationStatusEnum.Not_Applied) && (
              <Button
                id={'apply-individual-button-' + record.license.licenseNumber}
                disabled={disableApplyButton}
                style={{ width: '100px' }}
                onClick={() => {
                  setDisableApplyButton(true);
                  applyOneLicense(record);
                }}
              >
                {record.previousApplication?.id &&
                record.status === ApplicationStatusEnum.Not_Applied &&
                record.previousApplication?.status ===
                  ApplicationStatusEnum.Declined
                  ? 'Re-Apply'
                  : record?.status?.toLocaleLowerCase() ===
                    ApplicationStatusEnum.Declined
                  ? 'Re-Apply'
                  : record?.status?.toLocaleLowerCase() ===
                      ApplicationStatusEnum.Not_Applied && 'Apply'}
              </Button>
            )
          )
        ) : (
          ''
        )}
      </Row>
    );
  } else {
    return <></>;
  }
}
