import React from 'react';

const CopyIconCustom = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66263 3.9987V1.9987C4.66263 1.63051 4.96111 1.33203 5.32929 1.33203H13.3293C13.6975 1.33203 13.996 1.63051 13.996 1.9987V11.332C13.996 11.7002 13.6975 11.9987 13.3293 11.9987H11.3293V13.9981C11.3293 14.3666 11.0294 14.6654 10.6581 14.6654H2.6672C2.29649 14.6654 1.99609 14.369 1.99609 13.9981L1.99783 4.66594C1.99789 4.29744 2.29785 3.9987 2.66904 3.9987H4.66263ZM3.33104 5.33203L3.32955 13.332H9.99596V5.33203H3.33104ZM5.99596 3.9987H11.3293V10.6654H12.6626V2.66536H5.99596V3.9987Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyIconCustom;
