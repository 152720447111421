import './App.css';

import { Route, Routes } from 'react-router';
import { useEffect, useLayoutEffect } from 'react';

import AdminInvite from './pages/signup/invites/adminInvite';
import AgencyLicensingLayout from './components/agencyLicensing/agencyLicensingLayout';
import { AuthProvider } from './auth/authProvider';
import { ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import DownlineInvite from './pages/signup/invites/downlineInvite';
import { ForgotPassword } from './auth/onboarding/forgotPassword';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HTML5Backend } from 'react-dnd-html5-backend';
import IndividualOnboardV2 from './pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2';
import Loading from './components/loading';
import LoginScreen from './auth/onboarding/loginScreen';
import { MagicLinkVerification } from './auth/onboarding/magicLink/magicLinkVerification';
import ManageAssignmentsByProducer from './pages/agents/manageAssignments/manageAssignmentByProducer';
import OnboardOrAppAccess from './components/onboardOrAppAccess';
import OnboardingScreen from './auth/onboarding/onboardingScreen';
import PlausibleProvider from './components/plausibleProvider';
import ReactGA from 'react-ga4';
import { RouteConstants } from './constants/routes.constants';
import SignUpScreen from './auth/onboarding/signupScreen';
import StripeRedirection from './auth/onboarding/stripe/stripeRedirection';
import Unauthorized from './components/unauthorized';
import { hotjar } from 'react-hotjar';
import { observer } from 'mobx-react-lite';
import { signupStore } from './stores/signupStore';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  const domain = process.env.REACT_APP_REDIRECT_URI;
  if (process.env.REACT_APP_GA_TRACKER_ID)
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKER_ID);
  if (
    process.env.REACT_APP_HOTJAR_ID &&
    process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
  )
    hotjar.initialize(
      Number(process.env.REACT_APP_HOTJAR_ID),
      Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)
    );

  useLayoutEffect(() => {
    const pathname = location.pathname;
    const elements = document.getElementsByClassName('grecaptcha-badge');
    if (
      !['/signup', '/', '/forgot-password'].includes(pathname) &&
      elements.length > 0
    ) {
      if (!elements.item(0)?.classList.contains('captcha-hidden')) {
        elements.item(0)?.classList.remove('captcha-visible');
        elements.item(0)?.classList.add('captcha-hidden');
      }
    } else {
      if (elements.item(0)?.classList.contains('captcha-hidden')) {
        elements.item(0)?.classList.remove('captcha-hidden');
        elements.item(0)?.classList.add('captcha-visible');
      }
    }
  }, [location]);

  return (
    <PlausibleProvider domain={domain!}>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#00a6fb',
              },
            }}
          >
            {signupStore.isAppLoading && (
              <Loading logo fullHeight showAsOverlay />
            )}
            <GoogleReCaptchaProvider
              reCaptchaKey={
                process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY as string
              }
              container={{
                parameters: {
                  badge: 'inline',
                },
              }}
            >
              <Routes>
                <Route
                  path={RouteConstants.rootRedirect.path}
                  element={<LoginScreen />}
                />
                <Route
                  path={RouteConstants.forgotPassword.path}
                  element={<ForgotPassword />}
                />
                <Route
                  path={RouteConstants.signup.path}
                  element={<SignUpScreen />}
                />
                <Route
                  path={RouteConstants.onboarding.path}
                  element={<OnboardingScreen />}
                />
                <Route
                  path={RouteConstants.authUserInvite.path}
                  element={<AdminInvite />}
                />
                <Route
                  path={RouteConstants.authDownlineInvite.path}
                  element={<DownlineInvite />}
                />
                <Route
                  path={RouteConstants.stripeRedirection.path}
                  element={<StripeRedirection />}
                />
                <Route
                  path={RouteConstants.magicLink.path}
                  element={<MagicLinkVerification />}
                />
                <Route path="/unauthorized-page" element={<Unauthorized />} />
                <Route path="*" element={<OnboardOrAppAccess />} />
                <Route
                  path={RouteConstants.myAgencyManageAssignments.path}
                  element={<AgencyLicensingLayout />}
                />
                <Route
                  path={RouteConstants.manageAssignmentsByProducers.path}
                  element={<ManageAssignmentsByProducer />}
                />
                <Route
                  path={RouteConstants.onboardProducer.path}
                  element={<IndividualOnboardV2 />}
                />
              </Routes>
            </GoogleReCaptchaProvider>
          </ConfigProvider>
        </DndProvider>
      </AuthProvider>
    </PlausibleProvider>
  );
}

export default observer(App);
