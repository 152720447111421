import CustomButton from '../button/CustomButton';
import { Typography } from 'antd';
import emptyData from '../../../assets/images/emptyState.svg';
import emptyState from '../../../assets/images/emptyTable.svg';

type EmptyStateProps = {
  heading: string;
  content: string;
  button?: boolean;
  buttonText?: string;
  route?: string;
  image?: 'empty data' | 'empty state';
  width?: string | number;
};

function EmptyState(props: EmptyStateProps) {
  return (
    <div
      style={{ width: props?.width || '75%', textAlign: 'center' }}
      className="flex flex-col items-center"
    >
      {props.button || props.image === 'empty data' ? (
        <img src={emptyData} alt="no data" />
      ) : (
        <img src={emptyState} alt="no data" />
      )}
      <Typography.Paragraph
        style={{
          fontSize: '16px',
          fontWeight: 500,
          color: 'var(--grey-heading)',
          textAlign: 'center',
        }}
      >
        {props.heading}
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          fontSize: '12px',
          fontWeight: 400,
          textAlign: 'center',
          color: 'var(--grey-text)',
        }}
      >
        {props.content}
      </Typography.Paragraph>
      {props.button && (
        <CustomButton
          classNames="button primary-button"
          buttonName={props.buttonText}
          type="internalNavigation"
          destination={props.route}
        />
      )}
    </div>
  );
}

export default EmptyState;
