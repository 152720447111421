import { Button, Typography, Upload, message } from 'antd';

import type { RcFile } from 'antd/es/upload/interface';
import React from 'react';
import type { UploadChangeParam } from 'antd/es/upload';
import uploadBasket from '../../../assets/icons/uploadBasket.svg';

const { Dragger } = Upload;

interface UploadComponentProps {
  setPreviewImage: (image: string | null) => void;
  setFormData: (formData: FormData) => void;
  setDisabled: (disabled: boolean) => void;
}

const UploadComponent: React.FC<UploadComponentProps> = ({
  setPreviewImage,
  setFormData,
  setDisabled,
}) => {
  const beforeUpload = async (file: RcFile) => {
    const isSupportedFileType =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png';

    if (!isSupportedFileType) {
      message.error('You can only upload JPEG, JPG, or PNG files!');
      return Upload.LIST_IGNORE;
    }

    const isValidSize = await validateImageDimensions(file);
    if (!isValidSize) {
      message.error('Image dimensions must be at least 20px x 20px!');
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const validateImageDimensions = (file: RcFile): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const isValid = img.width >= 20 && img.height >= 20;
        URL.revokeObjectURL(img.src);
        resolve(isValid);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const handleChange = (info: UploadChangeParam) => {
    const { status, originFileObj } = info.file;
    if (status === 'done' && originFileObj) {
      const imageUrl = URL.createObjectURL(originFileObj);
      setPreviewImage(imageUrl);
      const newFormData = new FormData();
      newFormData.append('file', originFileObj);
      setFormData(newFormData);
      setDisabled(false);
    } else if (status === 'error') {
      setPreviewImage(null);
      setDisabled(true);
      message.error(`${info.file.name} file upload failed.`);
    } else if (status === 'uploading') setDisabled(true);
  };

  const mockUpload = (options: any) => {
    const { file, onSuccess, onError } = options;
    setTimeout(() => {
      if (file) {
        onSuccess('OK');
      } else {
        onError('Failed');
      }
    }, 1000);
  };

  return (
    <Dragger
      name="file"
      multiple={false}
      beforeUpload={beforeUpload}
      customRequest={mockUpload}
      onChange={handleChange}
      accept=".jpeg,.jpg,.png"
      style={{ height: '154px' }}
      showUploadList={false}
    >
      <div>
        <p className="ant-upload-drag-icon">
          <img src={uploadBasket} alt="dropperLogo" />
        </p>
        <Typography.Paragraph
          className="font-figtree"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            marginBottom: 0,
            marginTop: '-4px',
            textAlign: 'center',
            fontFamily: 'Figtree !important',
          }}
        >
          Drop your File onto this area to upload
          <br />
          or
        </Typography.Paragraph>
        <Button
          type="primary"
          style={{
            backgroundColor: '#001F45',
            fontFamily: 'Figtree !important',
            fontSize: '14px',
            fontWeight: 500,
            padding: '0 12px',
            color: '#fff',
            margin: '8px 0',
          }}
          className="upload-hint-choose-file"
        >
          Upload Photo
        </Button>
      </div>
    </Dragger>
  );
};

export default UploadComponent;
